import UnlockedBadgeIcon from './icon/UnlockedBadgeIcon';
import LockedBadgeIcon from './icon/LockedBadgeIcon';
import { getBadgeIconSrc, getSpecialAchievementBadges, getStreakAchievementBadges } from '../../utils/gamificationHelper';
import React, { useMemo } from 'react';
import HeaderBadgeBg from './icon/HeaderBadgeBg';

const ProfileAchievementsInfoBox = ({
  hasNoRank,
  gamificationBadges,
  affiliateBadges,
  gamificationAffProgress,
  windowWidthSize,
}) => {
  const streakAchievementBadges = useMemo(
    () => (!gamificationBadges ? [] : getStreakAchievementBadges(gamificationBadges, affiliateBadges, gamificationAffProgress)),
    [affiliateBadges, gamificationBadges, gamificationAffProgress]
  );

  const specialAchievementBadges = useMemo(
    () => (!gamificationBadges ? [] : getSpecialAchievementBadges(gamificationBadges, affiliateBadges, gamificationAffProgress)),
    [gamificationBadges, affiliateBadges, gamificationAffProgress]
  );

  const mergedBadges = [...streakAchievementBadges, ...specialAchievementBadges];

  const sortBadges = (badges) => {
    const originalOrder = new Map(badges.map((badge, index) => [badge.id, index]));

    const sortedBadges = badges.sort((a, b) => {
      // Unlocked login_streak progressive badges come first
      const isLoginStreakA = a.isUnlocked && a.type === 'login_streak' && a.badgeData.progressive;
      const isLoginStreakB = b.isUnlocked && b.type === 'login_streak' && b.badgeData.progressive;

      if (isLoginStreakA && !isLoginStreakB) {
        return -1;
      }
      if (!isLoginStreakA && isLoginStreakB) {
        return 1;
      }

      // Other progressive unlocked badges come next
      const isProgressiveA = a.isUnlocked && a.badgeData.progressive;
      const isProgressiveB = b.isUnlocked && b.badgeData.progressive;

      if (isProgressiveA && !isProgressiveB) {
        return -1;
      }
      if (!isProgressiveA && isProgressiveB) {
        return 1;
      }

      // Remaining unlocked badges come next, maintaining original order
      if (a.isUnlocked && !b.isUnlocked) {
        return -1;
      }
      if (!a.isUnlocked && b.isUnlocked) {
        return 1;
      }

      // Maintain the original order for locked badges
      return originalOrder.get(a.id) - originalOrder.get(b.id);
    });

    return sortedBadges.slice(0, 4);
  };
  const sortedBadges = sortBadges(mergedBadges);

  return (
    <div className="latest-achievements">
      <span className="achievements-text">Your Achievements</span>
      <div className="badge-list">
        {sortedBadges.map((badge, index) => (
          <div className="badge-box" key={index}>
            {badge?.isUnlocked ? <UnlockedBadgeIcon /> : <LockedBadgeIcon />}
            <img src={getBadgeIconSrc(badge)} />
          </div>
        ))}

        <HeaderBadgeBg windowWidthSize={windowWidthSize} />
      </div>
    </div>
  );
};

export default ProfileAchievementsInfoBox;
