/* eslint-disable no-useless-escape */
import url from 'url';
import querystring from 'querystring';
import request from '../utils/Req';
import moment from 'moment';
import _ from 'lodash';

import config from '../config';
import consts from '../utils/consts';

// TODO: remove jQuery from project
import $ from 'jquery';

const { API_URL, BACK_OFFERS, BACK_OFFERS_OVERRIDE, SURVEY_BASE_URL, SURVEY_OFFER_ID } = config;

const {
  COLUMNS_DATE,
  COUNTRIES_ORDER,
  DRILL_DOWN_VALUES,
  MOBILE_STATS_REPORTS,
  REGEXES: { TEXTS },
  STATS_COLUMNS_ORDER,
  STATS_REPORTS,
} = consts;

export const clearCookie = (name, path = '/', domain) => {
  document.cookie =
    name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=' + path + ';' + (domain ? 'domain=' + domain + ';' : '');
};

/**
 * Création de cookie
 * @param name {string} - Nom du cookie
 * @param value {string} - Valeur a entrer dans le cookie
 * @param daysToExpire {number} - Nombre de jours avant l'expiration du cookie
 */
export const setCookie = (name, value, daysToExpire) => {
  if (typeof document !== 'undefined') {
    let cookieString;
    if (!!daysToExpire && daysToExpire > 0) {
      const expiryDate = moment().add(daysToExpire, 'days').toDate();
      cookieString = `${name}=${escape(value)}; expires=${expiryDate}; path=/`;
    } else {
      cookieString = `${name}=${escape(value)}; path=/`;
    }
    document.cookie = cookieString;
  }
};

/**
 * Obtention de cookie
 * @param name {string} - Nom du cookie
 */
export const getCookie = (name) => {
  if (typeof window !== 'undefined') {
    const parts = `; ${document.cookie}`.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  }
};

export const getLocalStorageValue = (key) => {
  if (typeof window !== 'undefined' && !!window.localStorage) {
    const myStorage = window.localStorage;
    return myStorage.getItem(key);
  }
  return false;
};

export const setLocalStorageValue = (key, value) => {
  if (typeof window !== 'undefined' && !!window.localStorage) {
    const myStorage = window.localStorage;
    myStorage.setItem(key, value);
    return true;
  }
  return false;
};

export const getSessionStorageValue = (key) => {
  if (typeof window !== 'undefined' && !!window.sessionStorage) {
    const myStorage = window.sessionStorage;
    return myStorage.getItem(key);
  }
  return false;
};

export const setSessionStorageValue = (key, value) => {
  if (typeof window !== 'undefined' && !!window.sessionStorage) {
    const myStorage = window.sessionStorage;
    myStorage.setItem(key, value);
    return true;
  }
  return false;
};

export const deleteLocalStorageKey = (key) => {
  if (typeof window !== 'undefined' && !!window.localStorage) {
    const myStorage = window.localStorage;
    myStorage.removeItem(key);
    return true;
  }
  return false;
};

export const createMessagesFromLabels = (obj) => {
  if (obj instanceof Array) {
    return obj.reduce((object, option) => {
      const newObject = { ...object };
      newObject[option.label.id] = option.label;
      return newObject;
    }, {});
  }
};

/**
 * Fonction de décryption de string côté serveur
 * @param str {string} - String à décoder
 * @returns {Promise}
 */
export const decryptString = (str) =>
  new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/utils/untransform`)
      .send({ string: str })
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .then((res) => {
        if (!!res && !!res.body && !!res.body.data && !!res.body.data.string) {
          fulfill(JSON.parse(res.body.data.string));
        } else {
          reject();
        }
      })
      .catch((err) => reject(err))
  );

/**
 * Crée le array des tags pour les filtres afin de classer ceux-ci et pouvoir supprimer les bons tags qui sont cliqués
 */
export const buildFiltersTags = (type, values, category, text, id, filterName, ...rest) => {
  switch (type) {
    case 'select':
      if (typeof values === 'object') {
        if (values.length > 0 && text.length > 0) {
          return values.map((value, i) => ({
            category,
            filter: filterName,
            id,
            text: text[i],
            value,
            ...rest[0],
          }));
        }
      } else if (typeof values === 'string') {
        return [
          {
            text,
            value: values,
          },
        ];
      }
      break;
    case 'checkbox':
      if (typeof values === 'boolean') {
        return [
          {
            text,
            value: values,
            category,
            id,
            filter: filterName,
          },
        ];
      }
      break;
    case 'input':
      if (!!values && values.length >= 1) {
        return [
          {
            text: values,
            value: values,
            category,
            id,
            filter: filterName,
            ...rest[0],
          },
        ];
      }
      break;
    case 'range':
      if (!!values && values.length >= 1) {
        let textFilter;
        if (values[0] !== '' && values[1] !== '') {
          if (values[0] === values[1]) {
            textFilter = `Equal ${values[0]}`;
          } else {
            textFilter = `Between ${values[0]} and ${values[1]}`;
          }
        } else if (values[0] !== '') {
          textFilter = `Minimum ${values[0]}`;
        } else if (values[1] !== '') {
          textFilter = `Maximum ${values[1]}`;
        }

        return [
          {
            text: textFilter || '',
            value: values,
            category,
            id,
            filter: filterName,
            ...rest[0],
          },
        ];
      }
      break;
    default:
      break;
  }
  return [];
};

/**
 * Obtenir les filtres de recherche
 * @returns {{tags: {}, values: {}}}
 */
export const getFiltersInfos = (form, withCompare = false) => {
  const tags = {};
  const values = {};
  const $form = $(form);
  const $selectFilters = $form.find('.filterInput select, .filterInput input[type="hidden"]'); // Updated to include hidden inputs
  const $checkboxFilters = $form.find('.filterInputCheckbox input[type="checkbox"]');
  const $inputFilters = $form.find('.filterInputText input[type="text"]');

  if ($selectFilters.length) {
    $selectFilters.each(function () {
      let filterCompare;
      const $input = $(this);
      const id = $input.attr('id');
      const rawValue = $input.val();
      const filterName = this.getAttribute('data-filtername');
      const category = this.getAttribute('data-category');
      const text = this.getAttribute('data-text');
      const filterTexts = [];

      // Determine if it’s a multi-select by checking if rawValue has commas
      const isMultiSelect = rawValue.includes(',');
      const valuesArray = isMultiSelect ? rawValue.split(',') : [rawValue];
      const textsArray = isMultiSelect ? text.split(',') : [text];

      if (withCompare) {
        filterCompare = this.getAttribute('data-compare') || 'include';
      }

      // Handle selected options by iterating over the valuesArray
      textsArray.forEach((value) => {
        const optionText = value;
        if (optionText) {
          filterTexts.push(optionText);
        }
      });

      values[id] = isMultiSelect ? valuesArray : rawValue;
      if (!withCompare) {
        tags[id] = buildFiltersTags('select', valuesArray, category, filterTexts, id, filterName);
      } else {
        tags[id] = buildFiltersTags('select', valuesArray, category, filterTexts, id, filterName, { compare: filterCompare });
      }
    });
  }

  // Keep the rest of the function intact for checkboxes and text inputs
  if ($checkboxFilters.length) {
    $checkboxFilters.each(function () {
      const $input = $(this);
      const id = $input.attr('id');
      const value = $input[0].checked;
      const filterName = this.getAttribute('data-filtername');
      const category = $input.data('category');

      if (value !== null) {
        values[id] = value;
        tags[id] = buildFiltersTags('checkbox', value, category, filterName, id, filterName);
      }
    });
  }

  if ($inputFilters.length) {
    $inputFilters.each(function () {
      let filterCompare;
      const $input = $(this);
      const id = $input.attr('id');
      const value = $input.val();
      const filterName = this.getAttribute('data-filtername');
      const category = $input.data('category');

      if (withCompare) {
        filterCompare = this.getAttribute('data-compare') || 'include';
      }

      if (!!value && value.length >= 1) {
        values[id] = value;
        tags[id] = buildFiltersTags('input', value, category, filterName, id, filterName, { compare: filterCompare });
      }
    });
  }

  return {
    tags,
    values,
  };
};

export const prepareFiltersForSearch = ({ searchString, tags }) => {
  const search = {
    filters: {},
    searchString,
  };

  Object.keys(tags).map((key) => {
    tags[key].map((tag) => {
      if (!search.filters[tag.filter]) {
        search.filters[tag.filter] = {
          values: [],
          category: [],
          text: [],
          id: [],
        };
      }

      if (Object.prototype.hasOwnProperty.call(tag, 'compare')) {
        let tagValue = tag.value;
        const tagText = tag.text;
        let compare;

        switch (tag.compare) {
          case 'include':
            compare = 'EQUAL_TO';
            break;
          case 'exclude':
            compare = 'NOT_EQUAL_TO';
            break;
          case 'like':
            compare = 'LIKE';
            tagValue = '%' + tagValue + '%';
            break;
          case 'between':
            if (tagValue[0] !== '' && tagValue[1] === '') {
              compare = 'GREATER_THAN_OR_EQUAL_TO';
              tagValue[1] = Number.MAX_SAFE_INTEGER;
            } else if (tagValue[0] === '' && tagValue[1] !== '') {
              compare = 'LESS_THAN_OR_EQUAL_TO';
              tagValue[0] = 0;
            } else if (tagValue[0] !== tagValue[1]) {
              compare = 'BETWEEN';
            } else {
              compare = 'EQUAL_TO';
            }
            break;
          default:
            break;
        }

        search.filters[tag.filter] = {
          conditional: compare,
          values: tagValue instanceof Array ? tagValue : [...search.filters[tag.filter].values, tagValue],
          text: tagText instanceof Array ? tagText : [...search.filters[tag.filter].text, tagText],
          id: tag.id,
          category: tag.category,
        };
      } else {
        search.filters[tag.filter] = [...search.filters[tag.filter], tag.value, tag.text, tag.id, tag.category];
      }
    });
  });
  return search;
};

export const getCategories = (categoryName, categories = []) =>
  categories
    .filter((category) => category.name.startsWith(`${categoryName} - `))
    .map((category) => category.name.split(' - ')[1]);

export const getNiches = (categories = []) => getCategories('Niche', categories);

export const explodeDescription = (description) => {
  const explodedDescription = {};

  if (typeof window !== 'undefined') {
    const $descriptionNode = document.createElement('div');
    $descriptionNode.innerHTML = description;

    const $descriptionTag = $descriptionNode.getElementsByClassName('description-tag')[0];
    explodedDescription.description = $descriptionTag ? $descriptionTag.innerHTML : '';

    const $offerCampaignId = $descriptionNode.getElementsByClassName('campaign-id')[0];
    explodedDescription.offerCampaignId = $offerCampaignId ? $offerCampaignId.textContent : '';

    const $customPayout = $descriptionNode.getElementsByClassName('custom-payout')[0];
    explodedDescription.customPayout = $customPayout ? $customPayout.innerHTML : '';

    const $offerCreativeReplaceId = $descriptionNode.getElementsByClassName('creative-replace')[0];
    explodedDescription.offerCreativeReplaceId = $offerCreativeReplaceId ? $offerCreativeReplaceId.textContent : '';

    const $mailingOfferId = $descriptionNode.getElementsByClassName('mailing-offer-id')[0];
    explodedDescription.mailingOfferId = $mailingOfferId ? $mailingOfferId.textContent : '';

    const $config = $descriptionNode.getElementsByClassName('config')[0];
    if ($config) {
      try {
        explodedDescription.config = JSON.parse($config.textContent);
      } catch (e) {
        explodedDescription.config = {};
      }
    }

    const $conversionTitle = $descriptionNode.getElementsByClassName('conversion-title-tag')[0];
    if ($conversionTitle) {
      explodedDescription.conversionTitle = $conversionTitle.outerText;
    }
    explodedDescription.conversionTags = [...$descriptionNode.getElementsByClassName('conversion-tag')].map((e) => e.innerText);

    explodedDescription.tiersTags = [...$descriptionNode.getElementsByClassName('tiers-tags')].map(($tierTags) =>
      [...$tierTags.getElementsByClassName('tiers-tag')].map(($tags) => $tags.textContent)
    );

    explodedDescription.tiersTitleTagContainer = [...$descriptionNode.getElementsByClassName('tiers-title-tag-container')].map(
      (element) => {
        const $tiersTitleTag = element.getElementsByClassName('tiers-title-tag')[0];
        const $tiersTitlePayout = element.getElementsByClassName('tiers-title-payout')[0];
        const $tiersTitleDevice = element.getElementsByClassName('tiers-title-device')[0];

        return {
          tiersTitleTag: $tiersTitleTag ? $tiersTitleTag.textContent : '',
          tiersTitlePayout: $tiersTitlePayout ? $tiersTitlePayout.textContent : '',
          tiersTitleDevice: $tiersTitleDevice ? $tiersTitleDevice.textContent : '',
        };
      }
    );
  }

  return explodedDescription;
};

export const detectMobileAndTablet = () => {
  const regexNames =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i;
  const regexCodes =
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;

  let ua;
  if (typeof navigator !== 'undefined') {
    ua = navigator.userAgent || navigator.vendor;
  }
  if (!ua && typeof window !== 'undefined') {
    ua = window.opera;
  }

  if (ua) {
    return regexNames.test(ua) || regexCodes.test(ua.substr(0, 4));
  }

  return false;
};

export const sortStatsColumns = (columns) => STATS_COLUMNS_ORDER.filter((column) => columns.some((key) => key === column));

export const buildDrillDownObject = (row) => {
  const drillDownObj = {};

  DRILL_DOWN_VALUES.forEach((drillDownValue) => {
    const drillDownValues = drillDownValue.split('.');
    const theRow = row[drillDownValues[0]];

    if (theRow) {
      const subRow = theRow[drillDownValues[1]];
      if (subRow) {
        drillDownObj[drillDownValue] = {
          conditional: 'EQUAL_TO',
          values: subRow,
        };
      }
    }
  });
  return drillDownObj;
};

export const buildStatisticsSearch = (
  statisticsDateStart,
  statisticsDateEnd,
  drillDownValues,
  statisticsGroups,
  filters,
  statisticsColumns,
  statisticsTableLevel,
  statisticsSort,
  statisticsSettings
) => {
  if (statisticsColumns.length === 0) {
    statisticsColumns.push('Stat.date', 'Stat.impressions');
  }

  const sortedColumns = sortStatsColumns(statisticsColumns);
  let newSort = {};

  if (statisticsSort) {
    newSort = statisticsSort;

    sortedColumns.forEach((column) => {
      if (COLUMNS_DATE.some((key) => key === column) && !Object.keys(newSort).some((key) => key === column)) {
        newSort[column] = 'desc';
      }
    });
  } else {
    newSort[sortedColumns[0]] = 'desc';
  }

  Object.keys(newSort)
    .filter((key) => statisticsColumns.indexOf(key) === -1)
    .map((key) => delete newSort[key]);

  const options = {
    fields: sortedColumns,
    data_start: statisticsDateStart,
    data_end: statisticsDateEnd,
    sort: newSort,
    groups: statisticsGroups,
    ...statisticsSettings,
  };

  const drillDown = drillDownValues[statisticsTableLevel];

  // checker pour mobile et forcer les 4 champs à la place
  if (detectMobileAndTablet() && _.isEqual(drillDown.fields, STATS_REPORTS.DAILY.columns)) {
    drillDown.fields = MOBILE_STATS_REPORTS.DAILY.columns;
  }

  const preparedFilters = prepareFiltersForSearch(filters);
  const newSearchFilters = _.merge(preparedFilters, drillDown);
  const newSearch = _.merge(newSearchFilters, options);

  const newFiltersFilters = preparedFilters.filters;
  const drillDownFilters = drillDown.filters;

  newSearch.fields =
    !_.isEqual(drillDown.fields, statisticsColumns) && drillDown.fields.length === 1
      ? _.uniq([...drillDown.fields, ...statisticsColumns])
      : drillDown.fields;
  newSearch.groups = options.groups;
  newSearch.filters = { ...newFiltersFilters, ...drillDownFilters };

  // Pour les verticals
  if (sortedColumns.some((key) => key === 'Category.name')) {
    newSearch.filters = {
      ...newSearch.filters,
      'Category.name': {
        conditional: 'LIKE',
        values: '%Vertical%',
      },
    };
  }

  // this field don't exist in the HO api, we remove it from the search to prevent it from crashing the call
  newSearch.fields = newSearch.fields.filter((item) => item !== 'Stat.paidConversions');

  return newSearch;
};

export const sortCountries = (countries) => {
  const firstCountriesArray = [];
  const lastCountriesArray = [];

  countries.forEach((country) => {
    const countryOrderIndex = COUNTRIES_ORDER.indexOf(country.code);

    if (countryOrderIndex >= 0) {
      firstCountriesArray[countryOrderIndex] = country;
    } else {
      lastCountriesArray.push(country);
    }
  });

  return [...firstCountriesArray, ...lastCountriesArray];
};

export const basicLengthAndCharValidation = (field, min = 2, max = 100, regex) => {
  let error = false;

  const [Min, Max] = [parseInt(min, 10), parseInt(max, 10)];

  if (Min === Max && field.length !== Min) {
    error = `The field must have exactly ${Min} characters.`;
  } else if (field[0] === ' ') {
    error = `The field must not start with an empty space.`;
  } else if (field.length < Min) {
    error = `The field must have a minimum of ${Min} characters.`;
  } else if (field.length > Max) {
    error = `You have reached the maximum number of characters (${Max}) allowed for this field.`;
  } else if ((typeof regex === 'undefined' && !field.match(TEXTS)) || (typeof regex !== 'undefined' && !field.match(regex))) {
    error = 'This field has invalid characters in it';
  }

  return error;
};

export const basicLengthValidation = (field, min = 2, max = 100) => {
  if (field?.length < min) {
    return `The field must have a minimum of ${min} characters.`;
  } else if (field?.length > max) {
    return `You have reached the maximum number of characters (${max}) allowed for this field.`;
  } else if (field === undefined || field === '') {
    return true;
  }
  return false;
};

export const transformTableValue = (column, value, forGraph = false) => {
  let newValue = value;

  if (typeof newValue !== 'undefined' && newValue !== null) {
    switch (column) {
      case 'Category.name':
        newValue = value.split(' - ')[1];
        break;
      case 'OfferUrl.name':
      case 'Goal.name':
        if (value === '') {
          newValue = 'Default';
        }
        break;
      case 'Stat.erpc':
        newValue = forGraph ? parseFloat(value || 0) : parseFloat(value || 0).toFixed(4);
        break;
      case 'Stat.ltr':
      case 'Stat.cpm':
        newValue = forGraph ? parseFloat(value || 0) : parseFloat(value || 0).toFixed(3);
        break;
      case 'Stat.payout':
      case 'Stat.cpa':
      case 'Stat.ctr':
        newValue = forGraph ? parseFloat(value || 0) : parseFloat(value || 0).toFixed(2);
        break;
      case 'Stat.payout_type':
        switch (newValue) {
          case 'cpa_flat':
            newValue = 'CPA';
            break;
          case 'cpa_both':
            newValue = 'CPA + REV';
            break;
          case 'cpa_percentage':
            newValue = 'REV';
            break;
          default:
            break;
        }
        break;
      case 'Stat.week':
        newValue = moment(`${value.substr(0, 4)}W${value.substr(4, 6)}`).format('YYYY w (MMM D)');
        break;
      case 'Stat.affiliate_info1':
      case 'Stat.affiliate_info2':
      case 'Stat.affiliate_info3':
      case 'Stat.affiliate_info4':
      case 'Stat.affiliate_info5':
      case 'Stat.source':
        newValue = decodeURI(newValue);
        break;
      default:
        if (typeof newValue === 'string' && !isNaN(newValue) && forGraph) {
          newValue = parseFloat(newValue, 10);
        }
        break;
    }
  }
  return newValue;
};

export const stripValuesFromDataObj = (dataObj) => {
  const newObj = {};

  DRILL_DOWN_VALUES.forEach((drillValue) => {
    const drillValues = drillValue.split('.');

    if (!!dataObj[drillValues[0]] && typeof dataObj[drillValues[0]][drillValues[1]] !== 'undefined') {
      newObj[drillValue] = dataObj[drillValues[0]][drillValues[1]];
    }
  });
  return newObj;
};

export const configStripper = (conf) => ({
  ...conf,
  APP_PORT: undefined,
  DEV_SERVER_PORT: undefined,
  PROD_ENV: undefined,
});

export const reportTagsToFormFields = (report) => {
  const formValues = {};

  if (!!report && Object.prototype.hasOwnProperty.call(report, 'filters')) {
    const values = report.filters.values;
    Object.keys(values).forEach((key) => {
      switch (key) {
        case 'Country.name':
          formValues.country = values[key];
          break;
        case 'Offer.id':
          formValues.offer = values[key];
          break;
        case 'OfferUrl.name':
          formValues.offerUrl = values[key];
          break;
        case 'Vertical':
          formValues.vertical = values[key];
          break;
        case 'Browser.id':
          formValues.browser = values[key];
          break;
        case 'Stat.affiliate_info1':
          formValues.subId1 = values[key][0].replace(/%/g, '');
          break;
        case 'Stat.affiliate_info2':
          formValues.subId2 = values[key][0].replace(/%/g, '');
          break;
        case 'Stat.affiliate_info3':
          formValues.subId3 = values[key][0].replace(/%/g, '');
          break;
        case 'Stat.affiliate_info4':
          formValues.subId4 = values[key][0].replace(/%/g, '');
          break;
        case 'Stat.affiliate_info5':
          formValues.subId5 = values[key][0].replace(/%/g, '');
          break;
        case 'Stat.payout_type':
          formValues.payoutType = values[key];
          break;
        default:
          break;
      }
    });
  }

  return formValues;
};

export const reportTagsToActiveFilters = (report) => {
  const formValues = {};

  if (!!report && Object.prototype.hasOwnProperty.call(report, 'filters')) {
    Object.keys(report.filters.values).forEach((key) => {
      switch (key) {
        case 'Browser.id':
          formValues.filterBrowser = true;
          break;
        case 'Stat.affiliate_info1':
          formValues.filterSubId1 = true;
          break;
        case 'Stat.affiliate_info2':
          formValues.filterSubId2 = true;
          break;
        case 'Stat.affiliate_info3':
          formValues.filterSubId3 = true;
          break;
        case 'Stat.affiliate_info4':
          formValues.filterSubId4 = true;
          break;
        case 'Stat.affiliate_info5':
          formValues.filterSubId5 = true;
          break;
        case 'Stat.payout_type':
          formValues.filterPayoutType = true;
          break;
        default:
          break;
      }
    });
  }

  return formValues;
};

export const guid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const HexColorToRGB = (hex) => {
  const hexVal = parseInt(hex.replace('#', ''), 16);
  return {
    r: hexVal >> 16,
    g: (hexVal & 0x00ff00) >> 8,
    b: hexVal & 0x0000ff,
  };
};

export const paramifyObject = (paramObject) =>
  Object.keys(paramObject)
    .map((key) => `${key}=${paramObject[key]}`)
    .join('&');

export const surveyUrlBuilder = (
  surveyUrl,
  { basicConfiguration, customColors, rewardOptions, templateOptions },
  affId,
  showQuestionsSet = true
) => {
  let queryStrUrl = {};
  if (surveyUrl) {
    const parsedUrl = url.parse(surveyUrl);
    queryStrUrl = querystring.parse(parsedUrl.query);
  }
  const trafficType =
    !!rewardOptions && !!rewardOptions.rewardType && !!rewardOptions.rewardType.value
      ? JSON.parse(rewardOptions.rewardType.value)
      : '';
  const modelIdentity = typeof templateOptions.modelIdentity !== 'undefined' ? templateOptions.modelIdentity.value : '';
  const getRgbValue = (stateItem) => stateItem.value.replace('#', '');
  const params = {
    ...queryStrUrl,
    aff_id: affId,
    offer_id: SURVEY_OFFER_ID,
    noredirect: true,
    c0: getRgbValue(customColors.logoBackgroundColor),
    c1: getRgbValue(customColors.rewardsTextColor),
    c2: getRgbValue(customColors.introductionBackgroundColor),
    c3: getRgbValue(customColors.generalBackgroundColor),
    c4: getRgbValue(customColors.questionBoxBackgroundColor),
    c5: getRgbValue(customColors.questionBoxQuestionTextColor),
    c6: getRgbValue(customColors.questionBoxAnswerTextColor),
    c8: getRgbValue(customColors.rewardsBackgroundColor),
    c9: getRgbValue(customColors.rewardsTextColor),
    c10: getRgbValue(customColors.rewardsButtonBackgroundColor),
    c11: getRgbValue(customColors.rewardsButtonTextColor),
    c12: getRgbValue(customColors.generalBackgroundColor),
    c14: getRgbValue(customColors.introductionTextColor),
    custom1: 789,
    custom3: affId,
    title: encodeURIComponent(basicConfiguration.customTitle.value),
    'has-logo': !!basicConfiguration.displayLogo && basicConfiguration.displayLogo.value === 'yes' ? 1 : 0,
    logo_position: basicConfiguration.customLogoUrlAlignement ? basicConfiguration.customLogoUrlAlignement.value : 'center',
    footer: 0,
    bgImg: basicConfiguration.customBackgroundUrl.value,
    aff_sub: '{aff_sub}',
    aff_sub2: '{aff_sub2}',
    aff_sub3: '{aff_sub3}',
    aff_sub4: '{aff_sub4}',
    aff_sub5: '{aff_sub5}',
    source: '{source}',
    file_id: '{file_id}',
    img: modelIdentity, // Named img because of survey templates
    zones: trafficType.zones || '10584,10585,10586,10587',
    set: `${basicConfiguration.questionTheme.value}|`,
  };

  if (showQuestionsSet) {
    params.dto = true;
  }

  if (basicConfiguration.customLogoUrl.value) {
    params.logo = basicConfiguration.customLogoUrl.value;
  } else {
    params.lc0 = getRgbValue(customColors.logoColor1);
    params.lc1 = getRgbValue(customColors.logoColor2);
  }

  return `${SURVEY_BASE_URL}?${paramifyObject(params)}`;
};

export const setBodyClassForMobileDetection = () =>
  (document.getElementsByTagName('body')[0].className = detectMobileAndTablet() ? 'mobile-device' : 'desktop-device');

export const arrayShuffle = (array) => {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

/*
  Takes an array, and return it in an array of littel arrays of n
  arrayChunk([0,1,2,3,4], 2) => [[0, 1], [2, 3], [4]];
*/
export const arrayChunk = (values, n) => {
  if (values.length < 1 || n === undefined) {
    return [];
  }
  return Array.from(Array(Math.ceil(values.length / n)), (x, i) => values.slice(i * n, i * n + n));
};

export const ellipsizeTextBox = (elements) => {
  for (let i = 0; i < elements.length; i++) {
    const wordArray = elements[i].innerHTML.split(/\s/g);
    let inc = 0;
    while (elements[i].scrollHeight > elements[i].offsetHeight) {
      if (inc > wordArray.length) {
        break;
      }
      wordArray.pop();
      elements[i].innerHTML = `${wordArray.join(' ')}&nbsp;...'`;
      inc++;
    }
  }
};

export const fillDailyStatsBlank = (statData, dateRange) => {
  const newStatArray = [];

  if ('Stat' in statData[0]) {
    if ('hour' in statData[0].Stat) {
      for (let hour = dateRange.end; hour >= dateRange.start; hour--) {
        const currentStat = statData.find((statObj) => parseInt(statObj.Stat.hour, 10) === hour) || {
          Stat: {
            clicks: '0',
            conversions: '0',
            erpc: '0',
            hour: hour.toString(),
            impressions: '0',
            payout: '0',
          },
        };
        newStatArray.push(currentStat);
      }
    } else if ('date' in statData[0].Stat) {
      const startMoment = moment(dateRange.start);
      const endMoment = moment(dateRange.end);

      for (let m = moment(startMoment); m.isSameOrBefore(endMoment); m.add(1, 'days')) {
        const formattedMoment = m.format('YYYY-MM-DD');
        const currentStat = statData.find((statObj) => statObj.Stat.date === formattedMoment) || {
          Stat: {
            clicks: '0',
            conversions: '0',
            erpc: '0',
            date: formattedMoment,
            impressions: '0',
            payout: '0',
          },
        };
        newStatArray.push(currentStat);
      }
    }
  }
  return newStatArray;
};

const getAbsoluteHeight = (el) => {
  // Get the DOM Node if you pass in a string
  const theEl = typeof el === 'string' ? document.querySelector(el) : el;
  return Math.ceil(theEl.offsetHeight);
};

const getMargin = (el) => {
  // Get the DOM Node if you pass in a string
  const theEl = typeof el === 'string' ? document.querySelector(el) : el;
  const styles = window.getComputedStyle(theEl);

  return parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);
};

export const rowsResize = (cols, colsElements) => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const seenCols = [];
  const duplicateRowsCols = {};
  let biggestWidth = 0;

  Object.keys(colsElements).forEach((colName) => {
    if (colsElements[colName] !== null) {
      colsElements[colName].children[0].removeAttribute('style');
    }
  });

  Object.keys(cols).map((width) => {
    if (screenWidth >= width) {
      biggestWidth = width;
    }
  });

  Object.keys(cols[biggestWidth]).forEach((colNumber) => {
    cols[biggestWidth][colNumber].forEach((colName) => {
      if (seenCols.indexOf(colName) === -1) {
        seenCols.push(colName);
      } else {
        duplicateRowsCols[colName] = colNumber;
      }
    });
  });

  Object.keys(cols[biggestWidth]).forEach((colNumber) => {
    if (cols[biggestWidth][colNumber].length > 1) {
      let biggestHeight = 0;

      cols[biggestWidth][colNumber].forEach((colName) => {
        const colElement = colsElements[colName];
        if (colElement) {
          const childrenHeight = colElement.children[0].clientHeight;
          const colIsDuplicate = Object.keys(duplicateRowsCols).indexOf(colName) !== -1;

          if (
            childrenHeight > biggestHeight &&
            (!colIsDuplicate || (colIsDuplicate && duplicateRowsCols[colName] === colNumber))
          ) {
            biggestHeight = childrenHeight;
          }
        }
      });

      cols[biggestWidth][colNumber].forEach((colName) => {
        const colElement = colsElements[colName];
        if (colElement) {
          const theChildren = colElement.children[0];
          if (Object.keys(duplicateRowsCols).indexOf(colName) >= 0 && colNumber === duplicateRowsCols[colName]) {
            theChildren.style.height = [getAbsoluteHeight(theChildren), biggestHeight, getMargin(theChildren), 'px'].join('');
          } else {
            theChildren.style.height = `${biggestHeight}px`;
          }
        }
      });
    } else if (colsElements[cols[biggestWidth][colNumber][0]]) {
      colsElements[cols[biggestWidth][colNumber][0]].children[0].style.height = 'auto';
    }
  });
};

export const getCurrentQuarter = () => Math.ceil(parseInt(moment().format('MM'), 10) / 3);

export const getBackOffersList = (offer) => {
  if (BACK_OFFERS_OVERRIDE[offer.id]) {
    return BACK_OFFERS_OVERRIDE[offer.id];
  }
  if (getNiches(offer.category).find((niche) => niche === 'Gay')) {
    return BACK_OFFERS.Gay || [];
  }
  return offer.verticals
    ? [
        ...new Set(
          [].concat(
            ...offer.verticals.filter((vertical) => BACK_OFFERS[vertical.name]).map((vertical) => BACK_OFFERS[vertical.name])
          )
        ),
      ]
    : [];
};

export const clearObject = (raw) =>
  Object.keys(raw)
    .filter((key) => ![undefined, null, ''].some((val) => val === raw[key]))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: raw[key],
      }),
      {}
    );

export const getOfferIfByUrl = (href) => {
  const link = new URL(href);
  return link.pathname.includes('/offers/') ? link.pathname.replace('/offers/', '') : 'no-offerId';
};

// valide les accès a toutes les offres d'une campagne
export const validateAccessToOffers = (campaignLabel, campaigns) => {
  const campaignSelected = campaigns.find((camp) => camp.name === campaignLabel);
  if (campaignSelected) {
    return !campaignSelected.creatives.some((offer) => offer.require_approval);
  }
  return true;
};

export const delayAction = (() => {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

/*
 * takes an array and return it as a human readable string
 */
export const arrayToReadableString = (array, lastWord = 'and') => {
  if (!Array.isArray(array)) {
    return array;
  }
  if (array.length === 1) {
    return array[0];
  }
  const last = array.pop();
  return `${array.join(', ')} ${lastWord} ${last}`;
};

export const getNewToken = (radix = 36, length = 28) => {
  const dividedLength = length / 2 + 2;
  return `${Math.random().toString(radix).substring(2, Math.floor(dividedLength))}${Math.random(radix)
    .toString()
    .substring(2, Math.ceil(dividedLength))}`;
};
