import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { MAIN_DROPDOWN_NAME, OFFER_SEARCH_FIELD, OFFER_SEARCH_FORM_NAME, offerDropdownFields } from './values';
import consts from '../../../utils/consts';
import { change } from 'redux-form';
import { Gtm } from '../../../utils/gtm';
const OffersFilterContext = createContext(undefined);

//BUILD ARRAY OF TAGS FOR SEARCH GTM
const buildFilterTagsForSearch = ({ dropdowns, activeFilters }) => {
  const filterStringArray = [];

  if (Object.keys(activeFilters).length) {
    for (const key in activeFilters) {
      const valuesArray = activeFilters?.[key] || [];
      const options = dropdowns.find(({ field }) => field === key)?.options;
      valuesArray.forEach((value) => {
        let textValue = options.find(({ id, code, name }) => id === value || code === value || name === value);
        textValue = textValue.name;
        if (textValue) {
          filterStringArray.push(`${key}: ${textValue}`);
        }
      });
    }
  }

  return filterStringArray;
};

const buildFilterTags = ({ dropdowns, activeFilters }) => {
  const filterStringArray = [];
  if (Object.keys(activeFilters).length) {
    for (const key in activeFilters) {
      if (key !== 'Id') {
        const valuesArray = activeFilters?.[key] || [];
        const options = dropdowns.find(({ field }) => field === key)?.options;
        valuesArray.forEach((value) => {
          let textValue = options.find(({ id, code, name }) => id === value || code === value || name === value);
          textValue = textValue?.name;
          if (textValue) {
            if (key === 'Country') {
              filterStringArray.push({ name: `${textValue} (Accepted Country)`, key: key, value: value });
            } else if (key === 'TopCountries') {
              filterStringArray.push({ name: `${textValue} (Performs In)`, key: key, value: value });
            } else if (key === 'OptimizedFor') {
              filterStringArray.push({ name: `${textValue} (Optimized For)`, key: key, value: value });
            } else {
              filterStringArray.push({ name: textValue, key: key, value: value });
            }
          }
        });
      }
    }
  }

  return filterStringArray;
};

const initialOuterboxState = {
  mainDropdownActive: false,
  subDropdown: '',
};

export const OffersFilterProvider = ({
  dropdowns,
  handleSearchSubmit,
  dispatch,
  search,
  offersIsFetching,
  offersSearch,
  children,
  isSeeAllClicked,
}) => {
  const searchRef = useRef(null);

  const [desktopMode, setDesktopMode] = useState(true);
  const [showDropdown, setShowDropdown] = useState('');
  const [activeFilters, setActiveFilters] = useState({});
  const [searchOptions, setSearchOptions] = useState({});
  const [activeTags, setActiveTags] = useState([]);

  useEffect(() => {
    if (!offersSearch) {
      return;
    }

    const updatedFilters = {};

    for (const key in offerDropdownFields) {
      const value = offerDropdownFields[key];
      if (offersSearch[value]) {
        const currentValues = offersSearch[value].value;
        updatedFilters[value] = currentValues;
      }
    }

    setActiveFilters(updatedFilters);
  }, [offersSearch]);

  //MOBILE OUTER BOX
  const [outerBoxActive, setOuterBoxActive] = useState(initialOuterboxState);

  const activateOuterBox = ({ field }) => {
    const body = document.querySelector('body');

    setOuterBoxActive(() => {
      const state = { ...initialOuterboxState };

      if (field === MAIN_DROPDOWN_NAME) {
        state.mainDropdownActive = true;
        state.subDropdown = '';
      } else if (field) {
        state.mainDropdownActive = true;
        state.subDropdown = field;
      }

      if (field) {
        body.classList.add('dropdownFilterOpen');
      } else {
        body.classList.remove('dropdownFilterOpen');
      }

      return state;
    });
  };

  const closeDropdown = (event) => {
    const selection = window.getSelection();
    if (selection && selection.type === 'Range') {
      return;
    }

    setShowDropdown('');
  };

  useEffect(() => {
    const removeListener = () => window.removeEventListener('click', closeDropdown);

    if (showDropdown) {
      window.addEventListener('click', closeDropdown);
    } else {
      removeListener();
    }

    return () => {
      removeListener();
      document.body.style.overflow = 'auto';
    };
  }, [showDropdown]);

  const distpachSearchFormChange = ({ field, filters, searchEvent }) => {
    if (offersIsFetching) {
      return;
    }

    if (!filters?.length) {
      filters = '';
    }

    if (searchEvent) {
      const currentMainSearchValue = searchRef.current.value;
      const categoryList = buildFilterTagsForSearch({ dropdowns, activeFilters });

      Gtm.searchOffer(currentMainSearchValue, categoryList);
    }
    dispatch(change(OFFER_SEARCH_FORM_NAME, field, filters));
    const updateFilters = {
      [field]: filters,
    };
    handleSearchSubmit({ updateFilters });
  };

  useEffect(() => {
    const resize = () => {
      const desktop = window.innerWidth > 991;
      setDesktopMode(desktop);
    };

    window.addEventListener('resize', resize);
    resize();

    () => window.removeEventListener('resize', resize);
  }, []);

  const clearAllFilters = () => {
    const updateFilters = { ...activeFilters };
    for (const key in activeFilters) {
      updateFilters[key] = [];
      dispatch(change(OFFER_SEARCH_FORM_NAME, key, []));
    }
    handleSearchSubmit({ updateFilters });
  };

  const clearSearchFilter = () => {
    searchRef.current.value = '';
    const field = OFFER_SEARCH_FIELD;
    distpachSearchFormChange({ field, filters: '' });
  };

  const clearFiltersAndSearchFromContext = () => {
    const updateFilters = { ...activeFilters };
    for (const key in activeFilters) {
      updateFilters[key] = [];
      dispatch(change(OFFER_SEARCH_FORM_NAME, key, []));
    }
    dispatch(change(OFFER_SEARCH_FORM_NAME, OFFER_SEARCH_FIELD, ''));
    if (searchRef?.current) {
      searchRef.current.value = '';
    }
    setActiveFilters(updateFilters);
    dispatch({ type: consts.ACTIONS.REMOVE_SEARCH_ALL_FILTERS });
    return { ...updateFilters, [OFFER_SEARCH_FIELD]: '' };
  };

  const clearAllFiltersAndSearch = () => {
    const updateFiltersAndSearchString = clearFiltersAndSearchFromContext();
    handleSearchSubmit({ updateFilters: updateFiltersAndSearchString });
  };

  useEffect(() => {
    if (isSeeAllClicked) {
      clearAllFiltersAndSearch();
    }
  }, [isSeeAllClicked]);

  const isDropdownsFull = Object.values(dropdowns).every((value) => Array.isArray(value.options) && value.options.length > 0);

  useEffect(() => {
    if (isDropdownsFull) {
      setActiveTags(buildFilterTags({ dropdowns, activeFilters }));
    }
  }, [activeFilters, isDropdownsFull]);
  return (
    <OffersFilterContext.Provider
      value={{
        dropdowns,
        showDropdown,
        setShowDropdown,
        activeFilters,
        activeTags,
        setActiveTags,
        setActiveFilters,
        searchOptions,
        setSearchOptions,
        handleSearchSubmit,
        clearAllFilters,
        dispatch,
        searchRef,
        search,
        distpachSearchFormChange,
        clearAllFiltersAndSearch,
        offersIsFetching,
        outerBoxActive,
        setOuterBoxActive,
        activateOuterBox,
        desktopMode,
        clearSearchFilter,
      }}
    >
      {children}
    </OffersFilterContext.Provider>
  );
};

export const useOffersFilterContext = () => useContext(OffersFilterContext);
