import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import { CodeInput, Copy } from '../../reusables';
import { Gtm } from '../../../utils/gtm';
import { POP_CODE_TYPES } from '../../../utils/popCodeData.js';

const {
  offerPopCode: { msgCopy, msgHeader, msgLoading, msgNoData },
} = messages;

const popCodeCopyTracking = (popCodeTrackingString) =>
  Gtm.event(`${window.location.pathname === '/smartlink' ? 'dating smartlink' : 'offers'}`, 'Click', popCodeTrackingString);

const CreativesPopCode = ({ creativeCode, dispatch, intl, isLoading, offerFileId, title, popCodeTabActive }) => {
  const titleText = `${title} Code`;
  const copyText = `Copy ${title} Code`;

  const popcodeId = popCodeTabActive === POP_CODE_TYPES.POPUNDER ? 'offer-pop-code' : 'offer-popup-code';
  const popCodeTrackingString = popCodeTabActive === POP_CODE_TYPES.POPUNDER ? 'copy pop code' : 'copy pop up code';

  return (
    <div className="fullWidth">
      {!isLoading ? (
        <div>
          {offerFileId ? (
            <div className="code-input-wrapper">
              <CodeInput codeId={popcodeId} text={creativeCode} title={titleText} />
              <Copy
                clipboardTarget={`#${popcodeId}`}
                dispatch={dispatch}
                icon="code"
                onCopy={() => {
                  popCodeCopyTracking(popCodeTrackingString);
                }}
                text={copyText}
              />
            </div>
          ) : (
            <div className="code-input-wrapper">
              <CodeInput text={intl.formatMessage(msgNoData)} title={titleText} />
              <Copy clipboardTarget={null} disabled dispatch={() => {}} icon="code" text={copyText} />
            </div>
          )}
        </div>
      ) : (
        <div className="code-input-wrapper">
          <CodeInput text={intl.formatMessage(msgLoading)} title={titleText} />
          <Copy clipboardTarget={null} disabled dispatch={() => {}} icon="code" text={copyText} />
        </div>
      )}
    </div>
  );
};

CreativesPopCode.defaultProps = {
  creativeCode: '',
  dispatch: () => {},
  isLoading: false,
};

CreativesPopCode.propTypes = {
  creativeCode: PropTypes.string,
  dispatch: PropTypes.func,
  intl: intlShape.isRequired,
  isLoading: PropTypes.bool,
  offerFileId: PropTypes.string,
  popCodeTabActive: PropTypes.string,
};

export default injectIntl(CreativesPopCode);
