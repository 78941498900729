import request from 'superagent';
import { RATE_LIMIT_MESSAGE_ID, isRateLimitCode, RATE_LIMIT_MESSAGE } from './rateLimit';

function interceptRequest(req) {
  req.on('response', (res) => {
    if (isRateLimitCode(res.status)) {
      const closeRateLimitMessage = () => {
        const rateLimitMessage = document.getElementById(RATE_LIMIT_MESSAGE_ID);
        if (rateLimitMessage) {
          rateLimitMessage.remove();
        }
      };

      const rateLimitMessage = document.getElementById(RATE_LIMIT_MESSAGE_ID);

      if (!rateLimitMessage) {
        const outerDiv = document.createElement('div');
        outerDiv.id = RATE_LIMIT_MESSAGE_ID;

        const notifcationDiv = document.createElement('div');
        notifcationDiv.id = 'return-message';

        const messageDiv = document.createElement('div');
        messageDiv.classList.add('message', 'z-depth-4');

        const textSpan = document.createElement('span');
        textSpan.classList.add('text');
        textSpan.textContent = RATE_LIMIT_MESSAGE;

        const closeSpan = document.createElement('span');
        closeSpan.classList.add('close');
        closeSpan.onclick = closeRateLimitMessage;

        const icon = document.createElement('i');
        icon.classList.add('material-icons');
        icon.textContent = 'close';

        closeSpan.appendChild(icon);
        messageDiv.appendChild(textSpan);
        messageDiv.appendChild(closeSpan);
        notifcationDiv.appendChild(messageDiv);
        outerDiv.appendChild(notifcationDiv);

        document.body.appendChild(outerDiv);

        setTimeout(() => {
          notifcationDiv?.classList.add('show');
          setTimeout(() => {
            notifcationDiv?.classList.remove('show');
            setTimeout(closeRateLimitMessage, 300);
          }, 7000);
        }, 10);
      }
    }

    return res;
  });
}

class BetterRequest {
  setOrigin(origin) {
    this.origin = origin;
  }

  get() {
    return this.completeRequest(request.get(...arguments).use(interceptRequest));
  }

  post() {
    return this.completeRequest(request.post(...arguments).use(interceptRequest));
  }

  put() {
    return this.completeRequest(request.put(...arguments).use(interceptRequest));
  }

  delete() {
    return this.completeRequest(request.delete(...arguments).use(interceptRequest));
  }

  completeRequest(req) {
    if (this.origin) {
      req.set('Origin', this.origin);
    }
    return req;
  }
}

const req = new BetterRequest();

export default req;
