import {
  CRAKANNIVERSARY_TYPE,
  PAYOUT_AMOUNT_TYPE,
  REFERRAL_QUALIFIED_COUNT_TYPE,
  SHOW_TES_AFFILIATE_SEVILLE_TYPE,
  TELEGRAM_BADGE_TYPE,
} from './utils/gamificationHelper';

export const mockBadgeData = [
  {
    title: 'Login Legend', // database,
    type: 'login_streak',
    badgeData: {
      textLocked:
        'Stay consistent and earn this badge! Log in every week to build your streak. The longer you stay connected, the closer you get to unlocking the next badge. Don’t miss a week—keep your momentum going!',
      textUnlocked:
        'Congratulations! You’ve unlocked this badge by achieving your current weekly login. This highlights your dedication and commitment to growth. Keep the streak alive and aim to beat your personal best!',
      style: {
        glowColorCode: '#00b000',
      },
      ctaUrl: '',
      ctaText: 'View your progress',
      progressive: true, // false
    },
  },
  {
    title: 'Summit Road', // database,
    type: 'payout_milestone',
    badgeData: {
      textLocked:
        'Climb higher on the earnings ladder! This badge unlocks when your all-time revenue reaches the next milestone. Keep growing your payouts to achieve it—your success depends on your ongoing efforts!',
      textUnlocked:
        'Amazing work! You’ve hit the $%s all-time payout milestone, showcasing your dedication and results. Stay focused and aim for the next summit—it’s within your reach!',
      style: {
        glowColorCode: '#00b000',
      },
      ctaUrl: '/offers',
      ctaText: 'Select an offer',
      progressive: true, // false
    },
  },
  {
    title: 'Aspiring Ace', // database,
    type: 'rank', // database,
    level: 1, // database,
    badgeData: {
      textLocked:
        'Aim high and claim this badge! This badge unlocks when you secure a spot in the Rising Star Level (Top 400) by the end of 2024. The more you grow, the closer you’ll be to unlocking this achievement.',
      textUnlocked:
        'Kudos to you! You finished the year as a Rising Star Affiliate (Top 400). Enjoy your well-deserved perks and let them propel you to new heights—VIP status is just around the corner!',
      style: {
        glowColorCode: '#EAEAEA',
      },
      ctaUrl: 'https://www.crakrevenue.com/affiliate/#achievement-program',
      ctaText: 'Learn more',
      progressive: false,
    },
  },
  {
    title: 'Power Player', // database,
    type: 'rank', // database,
    level: 2, // database,
    badgeData: {
      textLocked:
        'Step up your game and earn this badge! This badge unlocks when you secure a spot in the VIP Level (Top 200) by the end of 2024. Your hard work and dedication will get you there—keep climbing!',
      textUnlocked:
        'Incredible achievement! You finished the year as a VIP Affiliate (Top 200). Your new perks are unlocked—use them to push even further. The Elite level is within your reach—keep striving for greatness!',
      style: {
        glowColorCode: '#FEF258',
      },
      ctaUrl: 'https://www.crakrevenue.com/affiliate/#achievement-program',
      ctaText: 'Learn more',
      progressive: false,
    },
  },
  {
    title: 'OG Whale', // database,
    type: 'rank', // database,
    level: 3, // database,
    badgeData: {
      textLocked:
        'Join the ranks of the Elite! This badge unlocks by reaching the prestigious Elite Club (Top 50) by the end of 2024. Only the best make it—are you ready to take the leap?',
      textUnlocked:
        'Much respect! You’ve finished the year as an Elite Affiliate (Top 50), earning your place in an exclusive club alongside Nick, the founder of CrakRevenue. Enjoy your hard-earned perks and let this milestone fuel your journey to even greater success!',
      style: {
        glowColorCode: '#01FC00',
      },
      ctaUrl: 'https://www.crakrevenue.com/affiliate/#achievement-program',
      ctaText: 'Learn more',
      progressive: false,
    },
  },
  {
    title: 'Level Up FTW', // database,
    type: 'lvl_up_contest', // database,
    level: 2, // database,
    badgeData: {
      textLocked:
        'Take your shot at glory! Unlock this exclusive badge by joining this year’s Level Up Contest. Boost your rank, and claim one of the prizes after the contest ends. Are you ready to rise?',
      textUnlocked:
        'Congratulations! You’ve won the Level Up Contest by increasing your rank and earned one of the rarest badges in the game. Wear it with pride and get ready to dominate the next edition of our Level Up affiliate contest!',
      style: {
        glowColorCode: '#0028DC',
      },
      ctaUrl: 'https://www.crakrevenue.com/contest/',
      ctaText: 'Open contest page',
      progressive: false,
    },
  },
  {
    title: 'Challenge Accepted', // database,
    type: 'lvl_up_contest', // database,
    level: 1, // database,
    badgeData: {
      textLocked:
        'Show your determination! Unlock this badge by joining this year’s Level Up Contest. To join: improve your ranking by the end of the year. Every step forward brings you closer to success!',
      textUnlocked:
        'Well done! You improved your ranking during the Level Up Contest—a true testament to your effort and dedication. Challenges like this pave the way to even greater achievements. Next time, the spotlight is yours!',
      style: {
        glowColorCode: '#00b000',
      },
      ctaUrl: 'https://www.crakrevenue.com/contest/',
      ctaText: 'Open contest page',
      progressive: false,
    },
  },
  {
    title: 'Review Rock Star', // database,
    type: 'trust_pilot', // database,
    level: 1, // database,
    badgeData: {
      textLocked: (
        <>
          Become a Review Rock Star! Unlock this badge by leaving us a{' '}
          <a href="https://ca.trustpilot.com/evaluate/crakrevenue.com?stars=5" rel="noreferrer" target="_blank">
            Trustpilot review
          </a>{' '}
          review and submitting proof. Your feedback helps us grow and improve!
        </>
      ),
      textUnlocked:
        'Thank you for your support! Your Trustpilot review means the world to us, and we’re thrilled to have you as part of our journey. Let this badge be a token of our gratitude and a celebration of your voice!',
      style: {
        glowColorCode: '#01FC00',
      },
      ctaUrl: '',
      ctaText: 'Send proof',
      progressive: false,
    },
  },
  {
    title: 'Ka-Ching!', // database,
    type: 'first_payment', // database,
    level: 1, // database,
    badgeData: {
      textLocked:
        'Ready to hear the sweet sound of success? This badge unlocks once your first payment status changes to PAID. Keep driving results, and this milestone will be yours!',
      textUnlocked:
        'Congratulations on your first payment! Your hard work is paying off—literally. Your first payment is on its way, and this is just the beginning. Aim higher, and let your next payout be even bigger!',
      style: {
        glowColorCode: '#F149FD',
      },
      ctaUrl: '/payment-history',
      ctaText: 'View your payments',
      progressive: false,
    },
  },
  {
    title: 'Trophy Room Hero',
    type: 'beta_tester_trophy_room',
    level: 1,
    badgeData: {
      textLocked: '',
      textUnlocked:
        'You’re a pioneer! This badge honors your invaluable contribution as one of the first Affiliates to test and shape our new Achievements section. Your feedback helps us improve—thank you for being part of the journey!',
      style: {
        glowColorCode: '#F149FD',
      },
      progressive: false,
      onlyUnlocked: true,
    },
  },
  {
    title: 'TES Seville 2025',
    type: SHOW_TES_AFFILIATE_SEVILLE_TYPE,
    level: 1,
    badgeData: {
      textLocked:
        'Unlock this one-time badge by meeting us at booth H4 during the TES Affiliate conference in Seville, Spain (March 9-12, 2025) and sending us a photo of the exclusive badge we will give you there.',
      textUnlocked:
        'Connections for more conversions! Thank you for meeting us at TES 2025 in Seville, Spain. We hope to see you again in future events, where more exclusive badges will come your way!',
      style: {
        glowColorCode: '#F149FD',
      },
      ctaUrl: 'https://www.crakrevenue.com/about-us/events',
      ctaText: 'Meet our team',
      progressive: false,
    },
  },
  {
    title: 'Telegram Titan',
    type: TELEGRAM_BADGE_TYPE,
    level: 1,
    badgeData: {
      textLocked: (
        <>
          Stay ahead of the game! Unlock this badge by joining our{' '}
          <a href="https://t.me/crakrevenuenews" rel="noreferrer" target="_blank">
            Telegram channel
          </a>{' '}
          and sending your Telegram username as proof. Don’t miss out on the latest news and opportunities!
        </>
      ),
      textUnlocked: (
        <>
          You're in the loop! By joining our{' '}
          <a href="https://t.me/crakrevenuenews" rel="noreferrer" target="_blank">
            Telegram channel
          </a>
          , you're always connected to the latest CrakRevenue updates and opportunities. Stay tuned for more!
        </>
      ),
      style: {
        glowColorCode: '#01FC00',
      },
      ctaUrl:
        'mailto:support@crakrevenue.com?subject=Request%20to%20Unlock%20Telegram%20Titan%20Badge&body=Hi%2C%0D%0A%0D%0AI%E2%80%99d%20like%20to%20unlock%20the%20Telegram%20Titan%20Badge%20as%20I%E2%80%99ve%20joined%20the%20CrakRevenue%20channel.%0D%0A%0D%0AMy%20Telegram%20username%20is%3A%20%5BYour%20Username%5D%0D%0A%0D%0ABest%2C',
      ctaText: 'SEND PROOF',
      progressive: false,
    },
  },
  {
    title: 'Trailblazer',
    type: PAYOUT_AMOUNT_TYPE,
    level: 1,
    badgeData: {
      textLocked:
        'Your journey to success starts here! Unlock this badge by earning your first $10 in payouts with our exclusive, high-paying offers. This is just the beginning—keep climbing and reach new heights with the Summit Road badge as you continue to unlock higher payout milestones!',
      textUnlocked:
        "You’ve made your first catch! With $10 in payouts under your belt, you're officially in the game. Keep reeling in bigger wins and progress towards the Summit Road badge as you unlock even higher payout milestones—this is just the beginning! ",
      style: {
        glowColorCode: '#01FC00',
      },
      ctaUrl: '/offers',
      ctaText: 'START PROMOTING',
      progressive: false,
    },
  },
  {
    title: 'The Network Effect',
    type: REFERRAL_QUALIFIED_COUNT_TYPE,
    badgeData: {
      textLocked:
        'Your network is your net worth! Unlock this badge when you refer affiliates who generate at least $10 in payouts. The more you share, the more you earn—so start spreading the word and grow your network!',
      textUnlocked:
        'Your network is your net worth! Your referred affiliate(s) have generated at least $10 in payouts—great job! Keep the momentum going and boost your earnings by bringing in more affiliates. Refer more and unlock the next level of this badge!',
      style: {
        glowColorCode: '#F149FD',
      },
      ctaUrl: '/referrals',
      ctaText: 'GRAB MY REFERRAL LINK',
      progressive: true,
    },
  },
  {
    title: 'Hip Hip Hooray',
    type: CRAKANNIVERSARY_TYPE,
    badgeData: {
      textLocked:
        'Loyalty deserves recognition! We thrive through long-term relationships with quality affiliates like you. Unlock a new badge on each of your CrakRevenue anniversaries and celebrate your journey with us.',
      textUnlocked:
        'Another year of success! Celebrate your CrakRevenue anniversary with this new badge, and watch it evolve with each passing year.',
      style: {
        glowColorCode: '#F149FD',
      },
      ctaUrl: '/offers',
      ctaText: 'KEEP UP THE GOOD WORK',
      progressive: true,
    },
  },
];

export const upcomingBadge = {
  disabled: true,
  badgeData: {
    subtype: 'upcoming',
  },
  type: 'upcoming',
  id: 0,
  title: 'Upcoming Badge',
  isUnlocked: true,
};

export const upcomingBadgeProgressive = {
  disabled: true,
  badgeData: {
    subtype: 'upcoming_progressive',
  },
  type: 'upcoming',
  id: 0,
  title: 'Upcoming Badge',
  isUnlocked: true,
};
