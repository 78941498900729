import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { provideHooks } from 'redial';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { browserHistory } from 'react-router';

import messages from '../../i18n/base-en.js';
import { closeModalAnimation, openModal } from '../../components/Modal/modal-actions/modal-actions.js';
import OfferAdditionalContainer from '../../components/Offer/OfferAdditional/OfferAdditionalContainer/OfferAdditionalContainer.react';
import OfferEmailInstructions from '../../components/Offer/OfferEmailInstructions/OfferEmailInstructions.react';
import OfferInfo from '../../components/Offer/OfferInfo/OfferInfo/OfferInfo.react';
import OfferPostbacks from '../../components/Offer/OfferPostbacks/OfferPostbacks.react';
import OfferTrackingLink from '../../components/Offer/OfferTrackingLink/OfferTrackingLink.react';
import OfferTrackingLinkCustomization from '../../components/Offer/OfferTrackingLinkCustomization/OfferTrackingLinkCustomization.react';
import OfferUnapprovedOverlay from '../../components/Offer/OfferUnapprovedOverlay/OfferUnapprovedOverlay.react';
import { Container, Creatives } from '../../components/reusables';
import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';

import { clearObject } from '../../utils/helpers';
import {
  fetchCampaignLink,
  fetchCreativeLink,
  fetchOffer,
  fetchOfferUrl,
  fetchTrackingLink,
  fetchUnsubscribeLinks,
  receiveCampaignLinkDone,
  resetOffer,
} from './Offer-Actions/Offer-Actions';
import { fetchGoals, resetGoals } from '../../actions/goals-actions';
import { fetchPostbacks } from '../../actions/postbacks-actions';

import intercom from '../../utils/intercom';

import validate from '../smart-link/validateSmartlinkForm.js';
import config from '../../config';
const { VERTICALS_OFFER_FOR_CHAT_HEAD } = config;
const Offer = (props) => {
  const {
    affId,
    campaignLink,
    creatives,
    creativesTrending,
    creativesIsFetching,
    creativesSearchForm,
    creativesSizes,
    creativesSizesIsFetching,
    dispatch,
    fields,
    goals,
    location,
    offer,
    offerIsFetching,
    offerNotFound,
    offerOverlay,
    offerPopCode,
    offerPopUpCode,
    offersCreativesViewMode,
    params,
    trackingLink,
    userEmail,
    values,
    skinConfigurations,
    mailingOfferId,
    errors,
    creativeLink,
    widgetDomain,
  } = props;

  const [canApplyCustomization, setCanApplyCustomization] = useState(false);
  const [currentCreativeCode, setCurrentCreativeCode] = useState(null);
  const [isReferrals, setIsReferrals] = useState(false);
  const [isLandingPageModalOpen, setIsLandingPageModalOpen] = useState(false);
  const getLinksRefetchAllowed = useRef(false);
  const timeoutRef = useRef(null);

  const offerFound = !offerNotFound && offer.id;

  const fetchData = () => {
    if (offerFound) {
      dispatch(fetchUnsubscribeLinks(params.id));
      dispatch(fetchOfferUrl(params.id));
      dispatch(fetchPostbacks(params.id));
      dispatch(fetchGoals(params.id));
    }
  };

  const isOptimizedDeletable = (initialValues) => {
    if (initialValues.url_id === 'optimized') {
      delete initialValues.url_id;
      return initialValues;
    }
    return initialValues;
  };

  const fetchLinks = (val, offer, firstFetch) => {
    const strippedValues = isOptimizedDeletable(val || clearObject(values));
    dispatch(fetchTrackingLink(params.id, strippedValues));
    if (offer.campaignId) {
      if (firstFetch) {
        delete strippedValues.url_id;
      }

      dispatch(fetchCampaignLink(offer.campaignId, offer.id, strippedValues));
    } else {
      dispatch(receiveCampaignLinkDone());
    }
    if (offer.creativeId) {
      dispatch(fetchCreativeLink(offer.creativeId, strippedValues));
    }
  };

  useEffect(() => {
    dispatch(fetchOffer(params.id));
    dispatch(fetchGoals(params.id));
    fetchData();
    const history = browserHistory;
    const referralsOffer = process.env.REACT_APP_REFERRAL_OFFER_ID;

    if (history.getCurrentLocation().pathname === `/offers/${referralsOffer}`) {
      setIsReferrals(!isReferrals);
    }
  }, []);

  useEffect(() => {
    if (!offerIsFetching && offer.id && offer.name) {
      intercom.trackEvent(`Offer`, {
        Offer_id: offer.id,
        Offer_name: offer.name,
      });
    }
  }, [offerIsFetching, offer.id, offer.name]);

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.searchParams.get('ad-tool')) {
      timeoutRef.current = setTimeout(() => {
        const adToolsList = document.getElementById('ad-tool');
        if (!adToolsList) {
          return;
        }
        adToolsList.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const generateCustomLink = () => {
    const strippedValues = isOptimizedDeletable(clearObject(values));
    fetchLinks(strippedValues, offer);
  };

  useEffect(() => {
    if (offerFound) {
      const strippedValues = isOptimizedDeletable(clearObject(values));
      fetchLinks(strippedValues, offer, true);
      getLinksRefetchAllowed.current = true;
    }
  }, [offerIsFetching]);

  useEffect(() => {
    if (offerFound && getLinksRefetchAllowed.current) {
      const strippedValues = isOptimizedDeletable(clearObject(values));
      fetchLinks(strippedValues, offer);
    }
  }, [values.url_id, fields.url_id.value, values.model, fields.model.value]);

  useEffect(
    () => () => {
      dispatch(resetOffer());
      dispatch(resetGoals());
    },
    []
  );

  const openShareModal = () => {
    dispatch(
      openModal({
        name: 'OfferShareModal',
        modalProps: {
          dispatch,
          offer: offer,
          affId: affId,
          skinConfigurations: skinConfigurations,
          className: 'offerShareModalStyled',
        },
      })
    );
  };

  const hideEmailModal = () => {
    setCurrentCreativeCode(null);
    dispatch(closeModalAnimation());
  };

  const showEmailPopup = (code = null) => {
    const {
      offer: { email_instructions, show_mail_list },
    } = props;

    setCurrentCreativeCode(code);

    if (show_mail_list === '1' || email_instructions === '1') {
      dispatch(
        openModal({
          name: 'OfferMailingModal',
          modalProps: {
            code: currentCreativeCode,
            dispatch: dispatch,
            downloadLink: offer.dne_download_url,
            hideEmailModal: hideEmailModal,
            unsubscribeLink: offer.dne_unsubscribe_url,
            className: 'offer-mailing-popup',
          },
        })
      );
    }
  };

  const showTerms = offer.require_terms_and_conditions === 1 && offer.agreed_terms_and_conditions === false;
  const showEmails = offer.show_mail_list === '1' || offer.email_instructions === '1';

  const isAvailableForVertical = offer?.verticals?.some(
    (vertical) =>
      vertical.id === VERTICALS_OFFER_FOR_CHAT_HEAD.cam ||
      vertical.id === VERTICALS_OFFER_FOR_CHAT_HEAD.dating ||
      vertical.id === VERTICALS_OFFER_FOR_CHAT_HEAD.AI
  );

  const tabs = {
    banner: {
      eventLabel: 'Console-Offer-Banner-Tab',
      show: true,
    },
    content: {
      eventLabel: 'Console-Offer-Content-Tab',
      show: true,
    },
    email: {
      eventLabel: 'Console-Offer-Email-Tab',
      show: true,
    },
    html: {
      eventLabel: 'Console-Offer-HTML-Tab',
      show: true,
    },
    iframe: {
      eventLabel: 'Console-Offer-Iframe-Tab',
      show: true,
    },
    postitial: {
      eventLabel: 'Console-Offer-Postitial-Tab',
      show: true,
    },
    prestitial: {
      eventLabel: 'Console-Offer-Prestitial-Tab',
      show: true,
    },
    link: {
      eventLabel: 'Console-Offer-Link-Tab',
      show: false,
    },
    pop: {
      eventLabel: 'Console-Offer-Pop-Tab',
      show: !offer.noPop,
    },
    chatHead: {
      eventLabel: 'Console-Offer-ChatHead-Tab',
      show: isAvailableForVertical,
    },
  };

  const approvalStatus = offerIsFetching ? '' : offer.approval_status || 'approval-required';
  const offerUnapprovedOverlay =
    approvalStatus !== 'approved' ? <OfferUnapprovedOverlay approvalStatus={approvalStatus} /> : undefined;

  const cancelModalButton = {
    action: () => {
      setIsLandingPageModalOpen(!isLandingPageModalOpen);
    },
    isModalOpen: isLandingPageModalOpen,
  };

  const SKIN = new SkinConfigurationsUtils(skinConfigurations);

  const getOfferItemsVisibility = () => ({
    moreInfo: SKIN.isItemVisible('Offers', 'MoreInfo'),
  });

  const IS_VISIBLE = getOfferItemsVisibility();

  const showTrackingLinkType =
    fields.url_id.value === 'optimized'
      ? campaignLink || trackingLink
      : offer.creativeId
      ? creativeLink
      : trackingLink || creativeLink || campaignLink;

  const postitialLandingIdsArray = [];

  offer.landing_pages.forEach((landing) => postitialLandingIdsArray.push(landing.id));
  return offerNotFound ? (
    <div className="offer-overlay-container">
      <div className="offer-overlay app-container">
        <h2>
          <FormattedMessage {...messages.offerNotFound} />
        </h2>
        <p>
          <FormattedMessage {...messages.offerSorryNotAvailable} />
        </p>
        <Link className="waves-effect waves-light btn" onlyActiveOnIndex={false} to="/offers/">
          <FormattedMessage {...messages.offerBackToList} />
        </Link>
        <div className="offer-overlay-waves">
          <svg viewBox="0 0 1440 292" xmlns="http://www.w3.org/2000/svg">
            <path className="fill-primary-darker-color" d="M1415 73c-115-45-289-145-602 12C480 253 0 81 0 81v217h1440V83" />
            <path className="fill-primary-color" d="M25 73C140 28 314-72 627 85c333 168 813-4 813-4v217H0V83" />
          </svg>
        </div>
      </div>
    </div>
  ) : (
    <div className="offer-overlay-container">
      <div className="container-fluid app-container" id="offer-content">
        <OfferInfo
          approvalStatus={approvalStatus}
          cancelModalButton={cancelModalButton}
          dispatch={dispatch}
          fields={fields}
          goals={goals}
          isAutoOptimized={!!campaignLink}
          isLandingPageModalOpen={isLandingPageModalOpen}
          isMoreInfoIconVisible={IS_VISIBLE.moreInfo}
          isReferrals={isReferrals}
          offer={offer}
          offerIsFetching={offerIsFetching}
          openShareModal={openShareModal}
          skinConfigurations={skinConfigurations}
          values={values}
        />
        {!showTerms && (
          <div className="approved-offer">
            <div id="link-section">
              <OfferTrackingLink
                campaignLink={offer.campaignId ? campaignLink : undefined} // not in referring campaigns
                cancelModalButton={cancelModalButton}
                dispatch={dispatch}
                downloadLink={offer.dne_download_url}
                fields={fields}
                generateCustomLink={generateCustomLink}
                hideEmailPopup={hideEmailModal}
                isAutoOptimized={!!campaignLink}
                isReferrals={isReferrals}
                offer={offer}
                offerId={params.id}
                overlay={offerUnapprovedOverlay}
                showEmailPopup={showEmailPopup} // not in referring campaigns
                skinConfigurations={skinConfigurations}
                trackingLink={trackingLink}
                trakingLinkFormValues={values}
                unsubscribeLink={offer.dne_unsubscribe_url}
                userEmail={userEmail}
                values={values}
              />
              <OfferTrackingLinkCustomization
                canApplyCustomization={canApplyCustomization}
                errors={errors}
                fields={fields}
                generateCustomLink={generateCustomLink}
                handleApplyChanges={fetchLinks}
                handleOnChange={() => setCanApplyCustomization(true)}
                isMoreInfoIconVisible={IS_VISIBLE.moreInfo}
                overlay={offerUnapprovedOverlay}
              />
            </div>
            {!isReferrals && (
              <OfferPostbacks dispatch={dispatch} isMoreInfoIconVisible={IS_VISIBLE.moreInfo} overlay={offerUnapprovedOverlay} />
            )}
            {!isReferrals && (
              <OfferAdditionalContainer
                offerChannels={offer.channels}
                offerConversionFlow={offer.conversionTags}
                offerConversionTitle={offer.conversionTitle}
                offerOS={offer.operating_systems}
                offerTargetAudience={offer.config ? offer.config.target_audience : []}
                offerTopPromotionalMethods={offer.config ? offer.config.top_promotional_methods : []}
                offerVerticals={offer.verticals}
              />
            )}
            {!!showEmails && (
              <OfferEmailInstructions
                approvalStatus={approvalStatus}
                downloadLink={offer.dne_download_url}
                emailInstructions={offer.email_instructions}
                emailInstructionsFrom={offer.email_instructions_from}
                emailInstructionsSubject={offer.email_instructions_subject}
                showEmailPopup={showEmailPopup}
              />
            )}
            <Container
              className="z-depth-2 black-title"
              icon="filter_list"
              id="ad-tools"
              overlay={offerUnapprovedOverlay}
              title={<FormattedMessage {...messages.offerAdTools} />}
            >
              <Creatives
                affId={affId}
                campaignLink={offer.campaignId ? campaignLink : undefined}
                creatives={creatives}
                creativesIsFetching={creativesIsFetching}
                creativesSearchForm={creativesSearchForm}
                creativesSizes={creativesSizes}
                creativesSizesIsFetching={creativesSizesIsFetching}
                creativesTrending={creativesTrending}
                dispatch={dispatch}
                fields={fields}
                isAutoOptimized={!!campaignLink}
                isReferrals={isReferrals}
                location={location}
                mailingOfferId={mailingOfferId}
                offerId={params.id}
                offerLandingPages={offer.landing_pages}
                offerOverlay={offerOverlay}
                offerPopCode={offerPopCode}
                offerPopUpCode={offerPopUpCode}
                offerThumbnail={offer && offer.thumbnail ? offer.thumbnail.thumbnail : null}
                offerVerticals={offer.verticals}
                offersCreativesViewMode={offersCreativesViewMode}
                postitialLandingIds={postitialLandingIdsArray || []}
                prestitialLandingIds={offer?.config?.prestitial_landing_ids || []}
                showEmailPopup={showEmailPopup}
                showEmails={showEmails}
                skinConfigurations={skinConfigurations}
                strippedValues={isOptimizedDeletable(clearObject(values))}
                tabs={tabs}
                trackingLink={showTrackingLinkType}
                userEmail={userEmail}
                values={values}
                widgetDomain={widgetDomain}
              />
            </Container>
          </div>
        )}
      </div>
    </div>
  );
};

Offer.propTypes = {
  addPostbackForm: PropTypes.object,
  affId: PropTypes.string.isRequired,
  campaignLink: PropTypes.string,
  creatives: PropTypes.object.isRequired,
  creativesIsFetching: PropTypes.bool.isRequired,
  creativesSearchForm: PropTypes.object.isRequired,
  creativesSizes: PropTypes.array.isRequired,
  creativesSizesIsFetching: PropTypes.bool.isRequired,
  creativesTrending: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  goals: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  location: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  offerIsFetching: PropTypes.bool.isRequired,
  offerNotFound: PropTypes.bool.isRequired,
  offerOverlay: PropTypes.any,
  offerPopCode: PropTypes.any,
  offerPopUpCode: PropTypes.any,
  offerUrl: PropTypes.array.isRequired,
  offersCreativesViewMode: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  postbacks: PropTypes.array.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  trackingLink: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  widgetDomain: PropTypes.string,
};

export default provideHooks()(
  connect((state) => ({
    affId: state.profile.data.affUserInfos ? state.profile.data.affUserInfos.affiliate_id : '',
    campaignLink: state.offer.campaignLink,
    creatives: state.creatives.data,
    creativesTrending: state.creativesTrending,
    creativeLink: state.offer.creativeLink,
    creativesIsFetching: state.creatives.isFetching,
    creativesSearchForm: state.form.creativesSearch || {},
    creativesSizes: state.creativesSizes.data,
    creativesSizesIsFetching: state.creativesSizes.isFetching,
    goals: state.goals.goals,
    offer: state.offer.offer,
    offerIsFetching: state.offer.offerIsFetching,
    offerNotFound: state.offer.offerNotFound,
    offerOverlay: state.offer.offerOverlay,
    offerPopCode: state.offer.offerPopCode,
    offerPopUpCode: state.offer.offerPopUpCode,
    offerUrl: state.offer.offerUrl,
    offersCreativesViewMode: state.application.ui.offersCreativesViewMode,
    trackingLink: state.offer.trackingLink,
    userEmail: state.profile.data.affUserInfos ? state.profile.data.affUserInfos.email : '',
    skinConfigurations: state.skinConfigurations.data,
    widgetDomain: state.offer.widgetDomain,
  }))(
    injectIntl(
      reduxForm(
        {
          form: 'trackingLink',
          fields: [
            'aff_sub',
            'aff_sub2',
            'aff_sub3',
            'aff_sub4',
            'aff_sub5',
            'bo',
            'popUnder',
            'source',
            'url_id',
            'searchString',
            'model',
          ],
          validate,
        },
        (state) => ({
          initialValues: {
            bo: state.offer.offer.backOffersList || undefined,
            popUnder: state.offer.offer.noPop ? undefined : true,
            url_id: state.offer.campaignLink ? 'optimized' : '0',
          },
        })
      )(Offer)
    )
  )
);
