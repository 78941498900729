export const IdentityInfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <path
      d="M34.1858 23.6432V12.9124C34.1858 8.56325 31.4761 5.5 27.127 5.5H13.3756C9.04101 5.5 6.31506 8.56325 6.31506 12.9124V24.6432C6.31506 28.9908 9.02641 32.054 13.3756 32.054H16.8053L20.2513 35.5L23.6973 32.054"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5162 31.934V30.5783C12.5162 28.0551 14.5141 24.9075 20.2433 24.9075C22.9129 24.9075 24.7733 25.5814 26 26.5561"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1865 16.3558C25.1865 19.0833 22.9763 21.2937 20.2486 21.2937C17.5211 21.2937 15.3092 19.0833 15.3092 16.3558C15.3092 13.6282 17.5211 11.418 20.2486 11.418C22.9763 11.418 25.1865 13.6282 25.1865 16.3558Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.8982 37.9215C35.7642 37.9215 38.8982 34.7875 38.8982 30.9215C38.8982 27.0555 35.7642 23.9215 31.8982 23.9215C28.0322 23.9215 24.8982 27.0555 24.8982 30.9215C24.8982 34.7875 28.0322 37.9215 31.8982 37.9215Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M29 31.3333L30.8 33L35 29" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
