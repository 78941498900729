import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';

import { InputText, InputNotice } from '../../../../components/reusables';

import provincesList, { getProvinceLabelDefaultMessage } from '../../../../utils/list-options/provinces';
import statesList, { getStateLabelDefaultMessage } from '../../../../utils/list-options/states';

import messages from '../../../../i18n/base-en.js';
import SelectCustom from '../../../../components/reusables/form/SelectCustom.js';

function BankInfos({ fields, values, countries, intl }) {
  const [savedRoutingNumber, setRoutingNumber] = useState(fields.wireABArouting.value || '');

  const isCountryWithRoutingNumber = (country) => ['US', 'CA', 'PR'].includes(country);

  useEffect(() => {
    if (isCountryWithRoutingNumber(values.wireBankCountry)) {
      setRoutingNumber(fields.wireABArouting.value || '');
    }
  }, [fields.wireABArouting.value, values.wireBankCountry]);
  return (
    <div className="bankInfos_section customSelects">
      <div className="row">
        <div className="col-md-12">
          <h2>
            <FormattedMessage {...messages.paymentInfosBankInfosTitle} />
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 fields" data-cy="select-wrapper-wireBankCountry">
          <SelectCustom
            enableSearch
            id="wireBankCountry"
            label={messages.paymentInfosBankInformationCountry.description}
            onChange={(e) => {
              fields.wireBankCountry.onChange(e);
              if (isCountryWithRoutingNumber(e)) {
                fields.wireABArouting.onChange(savedRoutingNumber);
              } else {
                fields.wireABArouting.onChange('');
              }
            }}
            options={countries.map((country) => ({ value: country.code, text: country.name }))}
            showSelectedFieldCheckmark={false}
            tabIndex={120}
            value={fields.wireBankCountry.value || ''}
            valueDisabled={intl.formatMessage(messages.genericTextChoose)}
          />
        </div>
        {(fields.wireBankCountry.value === 'UA' || fields.wireBankCountry.value === 'RU') && (
          <InputNotice className="col-md-12">
            <span>
              <FormattedHTMLMessage {...messages.paymentInfosRussiaUkraineMessage} />
            </span>
          </InputNotice>
        )}
      </div>

      {values.wireBankCountry ? (
        <div>
          <div className="row">
            <InputText
              className="col-md-12"
              displayErrorInstantly
              field={fields.wireBankName}
              id="wireBankName"
              label={messages.paymentInfosBankInformationName}
              maxLength={50}
              tabIndex={90}
              type="text"
            />
          </div>

          <div className="row">
            <InputText
              className="col-md-12"
              displayErrorInstantly
              field={fields.wireBankAddress}
              id="wireBankAddress"
              label={messages.paymentInfosBankInformationAddress}
              maxLength={60}
              tabIndex={100}
              type="text"
            />
          </div>

          <div className="row">
            <InputText
              className="col-md-12"
              displayErrorInstantly
              field={fields.wireBankCity}
              id="wireBankCity"
              label={messages.paymentInfosBankInformationCity}
              maxLength={20}
              tabIndex={110}
              type="text"
            />
          </div>

          {values.wireBankCountry === 'CA' && (
            <div className="row ">
              <div className="col-md-12 fields" data-cy="select-wrapper-wireBankProvince">
                <SelectCustom
                  id="wireBankProvince"
                  label={messages.paymentInfosBankInformationProvince.description}
                  onChange={fields.wireBankProvince.onChange}
                  options={provincesList.map((province) => ({
                    value: province.value,
                    text: intl.formatMessage(messages[province.label.id]),
                  }))}
                  showSelectedFieldCheckmark={false}
                  tabIndex={130}
                  value={fields.wireBankProvince.value || ''}
                  valueDisabled={intl.formatMessage(messages.genericTextChoose)}
                />
              </div>
              {(fields.wireBankProvince.value === '' ||
                fields.wireBankProvince.value === null ||
                !getProvinceLabelDefaultMessage(fields.wireBankProvince.value)) && (
                <div className="col-md-12 select-field-error field-error">Please select the province of the bank.</div>
              )}
            </div>
          )}

          {values.wireBankCountry === 'US' && (
            <div className="row">
              <div className="col-md-12 fields" data-cy="select-wrapper-wireBankState">
                <SelectCustom
                  id="wireBankState"
                  label={messages.paymentInfosBankInformationState.description}
                  onChange={fields.wireBankState.onChange}
                  options={statesList.map((state) => ({
                    value: state.value,
                    text: intl.formatMessage(messages[state.label.id]),
                  }))}
                  showSelectedFieldCheckmark={false}
                  tabIndex={130}
                  value={fields.wireBankState.value || ''}
                  valueDisabled={intl.formatMessage(messages.genericTextChoose)}
                />
              </div>
              {(fields.wireBankState.value === '' ||
                fields.wireBankState.value === null ||
                !getStateLabelDefaultMessage(fields.wireBankState.value)) && (
                <div className="col-md-12 select-field-error field-error">Please select the state of the bank.</div>
              )}
            </div>
          )}

          {values.wireBankCountry !== 'US' && values.wireBankCountry !== 'CA' && (
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.wireBankRegion}
                id="wireBankRegion"
                label={messages.paymentInfosBankInformationRegion}
                tabIndex={130}
                type="text"
              />
            </div>
          )}

          <div className="row">
            <InputText
              className="col-md-12"
              displayErrorInstantly
              field={fields.wireBankZipcode}
              id="wireBankZipcode"
              label={
                values.wireBankCountry === 'US'
                  ? messages.paymentInfosBankInformationZipcode
                  : values.wireBankCountry === 'CA'
                  ? messages.paymentInfosBankInformationPostalcode
                  : messages.paymentInfosBankInformationZipPostalcode
              }
              tabIndex={140}
              type="text"
            />
          </div>

          <div className="row">
            <InputText
              className="col-md-12"
              displayErrorInstantly
              field={fields.wireBicSwift}
              id="wireBicSwift"
              label={messages.paymentInfosWireBicSwiftLabel}
              maxLength={12}
              tabIndex={160}
              type="text"
            />
          </div>

          <div className="row">
            <InputText
              className="col-md-12"
              displayErrorInstantly
              field={fields.wireAccountNumberOrIban}
              id="wireAccountNumberOrIban"
              label={
                values.wireBankCountry === 'US'
                  ? messages.paymentInfosWireAccountNumberLabel
                  : values.wireBankCountry === 'CA'
                  ? messages.paymentInfosWireCanadaAccountNumberLabel
                  : messages.paymentInfosWireIbanLabel
              }
              maxLength={35}
              tabIndex={170}
              type="text"
            />
          </div>

          {(values.wireBankCountry === 'US' || values.wireBankCountry === 'CA' || values.wireBankCountry === 'PR') && (
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.wireABArouting}
                id="wireABArouting"
                label={
                  values.wireBankCountry === 'CA'
                    ? messages.paymentInfosWireTransitNumberLabel
                    : messages.paymentInfosWireABAroutingLabel
                }
                mask={values.wireBankCountry === 'CA' ? '999-99999' : undefined}
                maxLength={9}
                tabIndex={180}
                type="text"
              />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

BankInfos.propTypes = {
  countries: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  values: PropTypes.object.isRequired,
};

export default injectIntl(BankInfos);
