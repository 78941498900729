import { Component, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { withRouter } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../../i18n/base-en.js';

import ConnectionInfos, { ConnectedNewConnectionInfos } from './user-infos/ConnectionInfos.react';
import ProfileInfos from './user-infos/ProfileInfos.react';
import ContactInfos from './user-infos/ContactInfos.react';
import { LoadingPage } from '../../components/reusables';

import HandleAPIError from '../../utils/handleAPIError';
import { fetchProfileCompleteVerification } from '../../components/Profile/actions/profile-completed-actions';
import { setActionMessage, fetchPaymentInfo } from '../../components/Application/actions/application-actions';
import { initialFetching } from '../../utils/initialFetching';

import { updateProfile, fetchProfile } from '../../actions/profile-actions.js';
import { updateConnectionSettings } from '../../actions/connection-settings-actions';
import { getCountryLabelDefaultMessage } from '../../utils/list-options/countries.js';
import { buildAddressLabel } from '../../utils/addressHelper.js';

const UserInfos = ({
  dispatch,
  bestTimesToCall,
  payableTo,
  countries,
  timezones,
  companyInfos,
  isFetchingUserInfos,
  router,
  route,
  profileCompanyInfosForm,
  profileUserInfosForm,
  intl,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(false);
  const [isContactInfoOpen, setIsContactInfoOpen] = useState(false);

  useEffect(() => {
    initialFetching(dispatch).then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isPersonalInfoOpen) {
      setIsContactInfoOpen(false);
      setIsAccountOpen(false);
    }
  }, [isPersonalInfoOpen]);

  useEffect(() => {
    if (isContactInfoOpen) {
      setIsAccountOpen(false);
      setIsPersonalInfoOpen(false);
    }
  }, [isContactInfoOpen]);

  useEffect(() => {
    if (isAccountOpen) {
      setIsContactInfoOpen(false);
      setIsPersonalInfoOpen(false);
    }
  }, [isAccountOpen]);

  const removeLeaveHook = router.setRouteLeaveHook(route, () => {
    const newForm = _.extend(profileCompanyInfosForm, profileUserInfosForm) || {};
    let changed = false;

    Object.keys(newForm).forEach((key) => {
      if (
        typeof newForm[key] === 'object' &&
        String(newForm[key].value ? newForm[key].value : '') !== String(newForm[key].initial ? newForm[key].initial : '')
      ) {
        if (!['password', 'newPassword', 'newPasswordConfirmation', 'phone'].includes(key)) {
          changed = true;
        }
      }
    });

    if (changed) {
      return intl.formatMessage(messages.profileSettingsUnsavedChecked);
    }
  });

  const handleUserInfosFormSubmit = (data) => {
    const body = {
      oldPassword: data.password,
      password: data.newPassword,
      password_confirmation: data.newPasswordConfirmation,
    };
    dispatch(updateConnectionSettings(body))
      .then(() => {
        dispatch(fetchProfile()).then(() => {
          const fetchingPromises = [
            dispatch(
              setActionMessage('success', {
                text: intl.formatMessage(messages.userAndCompanyUpdateProfileInfosCompleted),
              })
            ),
            removeLeaveHook(),
          ];
          Promise.all(fetchingPromises);
          setIsAccountOpen(false);
        });
      })
      .catch((res) => {
        new HandleAPIError(res, dispatch).handleAll();
      });
  };

  const handleCompanyInfosFormSubmit = (data) => {
    const sentAddressLabel = buildAddressLabel({
      address: data.address,
      city: data.city,
      region: data.region,
      postalCode: data.zipcode,
      country: getCountryLabelDefaultMessage(data.country),
    });
    if (profileCompanyInfosForm?.address_label?.initial !== sentAddressLabel) {
      if (data.autocompleted_address_label) {
        data.is_affiliate_address_autocompleted = sentAddressLabel === data.autocompleted_address_label;
      } else {
        data.is_affiliate_address_autocompleted = false;
      }
    }

    dispatch(updateProfile(data))
      .then(() => {
        dispatch(fetchProfileCompleteVerification());
        dispatch(fetchProfile()).then(() => {
          const fetchingPromises = [
            dispatch(
              setActionMessage('success', {
                text: intl.formatMessage(messages.userAndCompanyUpdateProfileInfosCompleted),
              })
            ),
            dispatch(fetchPaymentInfo()),
            removeLeaveHook(),
          ];
          Promise.all(fetchingPromises);
          if (isPersonalInfoOpen) {
            setIsPersonalInfoOpen(false);
          }

          if (isContactInfoOpen) {
            setIsContactInfoOpen(false);
          }
        });
      })
      .catch((res) => {
        new HandleAPIError(res, dispatch).handleAll();
      });
  };

  const dataLoading = isFetchingUserInfos || isLoading;
  return (
    <div className="app-container container-fluid form-container" id="profile-userinfos">
      <div className="user-details-wrapper user-details-collapseble-wrapper ">
        <ConnectionInfos
          affiliateId={companyInfos.id}
          isLoading={dataLoading}
          isOpen={isAccountOpen}
          onSubmit={handleUserInfosFormSubmit}
          setIsOpen={setIsAccountOpen}
        />
        <ProfileInfos
          bestTimesToCall={bestTimesToCall}
          countries={countries}
          isLoading={dataLoading}
          isOpen={isPersonalInfoOpen}
          onSubmit={handleCompanyInfosFormSubmit}
          payableTo={payableTo}
          setIsOpen={setIsPersonalInfoOpen}
          timezones={timezones}
        />
        <ContactInfos
          bestTimesToCall={bestTimesToCall}
          countries={countries}
          isLoading={dataLoading}
          isOpen={isContactInfoOpen}
          onSubmit={handleCompanyInfosFormSubmit}
          parentRoute={route}
          payableTo={payableTo}
          setIsOpen={setIsContactInfoOpen}
          timezones={timezones}
        />
      </div>
    </div>
  );
};

UserInfos.propTypes = {
  bestTimesToCall: PropTypes.array.isRequired,
  companyInfos: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isFetchingUserInfos: PropTypes.bool,
  payableTo: PropTypes.any.isRequired,
  profileCompanyInfosForm: PropTypes.object.isRequired,
  profileUserInfosForm: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  timezones: PropTypes.array.isRequired,
};

export default injectIntl(withRouter(UserInfos));
