import { OFFER_TABLE_HEADERS } from './data';
import './styles/OfferListingTable.scss';
import EpcTooltipIcon from '../Offers/EpcTooltipIcon';
import CountriesTooltipIcon from '../Offers/CountriesTooltipIcon';

const OfferListingRowHeader = () => (
  <div className="offerListingRow offerListingRowHeader">
    {OFFER_TABLE_HEADERS.map(({ keyHeader, title }) => (
      <div className={`offerListingGridColumn column-${keyHeader}`} key={keyHeader}>
        {title}
        {keyHeader === 'epc' && <EpcTooltipIcon id="tooltip-epc" />}
        {keyHeader === 'countries' && (
          <CountriesTooltipIcon
            content="These countries have generated good earnings for this offer based on recent network performance."
            id="tooltip-targeted-countries"
          />
        )}
        {keyHeader === 'targetedCountries' && (
          <CountriesTooltipIcon
            content="This offer is specifically tailored to maximize performance for traffic from these countries."
            id="tooltip-countries"
          />
        )}
      </div>
    ))}
  </div>
);

export default OfferListingRowHeader;
