import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import { getCookie } from '../utils/helpers';
import { logout } from '../components/Login/actions/login-actions';

import config from '../config';
import consts from '../utils/consts';

const { API_URL } = config;
const {
  ACTIONS: { PROFILE },
} = consts;

const requestProfile = () => ({ type: PROFILE.REQUEST_PROFILE });
const receiveProfile = (data) => ({ type: PROFILE.RECEIVE_PROFILE, data });
const failedToReceiveProfile = () => ({
  type: PROFILE.FAILED_TO_RECEIVE_PROFILE,
});

export const fetchProfile = () => (dispatch) => {
  dispatch(requestProfile());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/user/profile`)
      .withCredentials()
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveProfile(res.body.data));
          fulfill();
        } else {
          dispatch(failedToReceiveProfile());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestUpdateProfile = () => ({ type: PROFILE.REQUEST_UPDATE_PROFILE });
const receiveUpdateProfile = () => ({ type: PROFILE.RECEIVE_UPDATE_PROFILE });
const failedToUpdateProfile = () => ({ type: PROFILE.FAILED_TO_UPDATE_PROFILE });

export const updateProfile = (data) => (dispatch) => {
  dispatch(requestUpdateProfile());
  return new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/user/profile`)
      .withCredentials()
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .send({
        ...data,
      })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveUpdateProfile());
          fulfill();
        } else {
          dispatch(failedToUpdateProfile());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};
