export const BillingInfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M36.0647 23.9929H29.3175C26.8401 23.9914 24.8321 21.9849 24.8306 19.5075C24.8306 17.0301 26.8401 15.0235 29.3175 15.022H36.0647"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M30.081 19.4048H29.5615" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9126 5H27.3183C32.1486 5 36.0644 8.91585 36.0644 13.7461V25.7078C36.0644 30.5381 32.1486 34.4539 27.3183 34.4539H12.9126C8.08235 34.4539 4.1665 30.5381 4.1665 25.7078V13.7461C4.1665 8.91585 8.08235 5 12.9126 5Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M11.7261 12.5636H20.7243" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
