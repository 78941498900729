const TelegramMenuLeftIcon = () => (
  <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M3.74981 8.91996C7.67981 7.19996 10.2998 6.08996 11.5998 5.53996C15.3398 3.98996 16.1098 3.70996 16.6198 3.70996C16.7198 3.70996 16.9798 3.72996 17.1398 3.85996C17.2698 3.95996 17.3098 4.10996 17.3298 4.21996C17.3498 4.32996 17.3698 4.55996 17.3498 4.73996C17.1398 6.85996 16.2798 12.04 15.8198 14.42C15.6298 15.43 15.2498 15.76 14.8998 15.81C14.1198 15.87 13.5098 15.29 12.7598 14.8C11.5798 14.02 10.9098 13.54 9.75981 12.78C8.43981 11.9 9.29981 11.42 10.0498 10.64C10.2398 10.43 13.6998 7.29996 13.7698 7.00996C13.7698 6.96996 13.7898 6.83996 13.7098 6.77996C13.6298 6.71996 13.5198 6.73996 13.4398 6.75996C13.3098 6.77996 11.4198 8.03996 7.74981 10.52C7.19981 10.9 6.71981 11.07 6.27981 11.07C5.79981 11.07 4.86981 10.8 4.17981 10.57C3.33981 10.3 2.66981 10.15 2.72981 9.68996C2.78981 9.43996 3.12981 9.18996 3.75981 8.92996L3.74981 8.91996Z"
      fillRule="evenodd"
      stroke="#FFF"
      strokeLinejoin="round"
    />
  </svg>
);

export default TelegramMenuLeftIcon;
