const InvoiceIcon = ({ className }) => (
  <svg className={className} fill="none" height="19" viewBox="0 0 14 19" width="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3636 1.31824H2.63636C1.73262 1.31824 1 2.05086 1 2.9546V16.0455C1 16.9492 1.73262 17.6819 2.63636 17.6819H11.3636C12.2674 17.6819 13 16.9492 13 16.0455V2.9546C13 2.05086 12.2674 1.31824 11.3636 1.31824Z"
      stroke="#252525"
    />
    <path d="M4.81818 9.49999L9.18181 5.13635" stroke="#252525" strokeLinecap="round" />
    <path
      d="M5.36363 6.22726C5.66488 6.22726 5.90909 5.98305 5.90909 5.68181C5.90909 5.38056 5.66488 5.13635 5.36363 5.13635C5.06238 5.13635 4.81818 5.38056 4.81818 5.68181C4.81818 5.98305 5.06238 6.22726 5.36363 6.22726Z"
      stroke="#252525"
    />
    <path
      d="M8.63634 9.50009C8.93758 9.50009 9.18179 9.25588 9.18179 8.95463C9.18179 8.65339 8.93758 8.40918 8.63634 8.40918C8.33509 8.40918 8.09088 8.65339 8.09088 8.95463C8.09088 9.25588 8.33509 9.50009 8.63634 9.50009Z"
      stroke="#252525"
    />
    <path d="M3.72729 12.7728H10.2727M3.72729 14.9546H10.2727" stroke="#252525" strokeLinecap="round" />
  </svg>
);

export default InvoiceIcon;
