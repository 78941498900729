import InvoiceIcon from './InvoiceIcon';
import moment from 'moment/moment';
import { FormattedNumber } from 'react-intl';
import { Gtm } from '../../utils/gtm';
import ArrowBtnIcon from './ArrowBtnIcon';
import React from 'react';
import { getPaymentDueDate } from '../../utils/dateHelper';

export const NextPaymentsTable = ({
  pendingPaymentList,
  paymentTerm,
  minimumPayoutAmount,
  handleInvoiceRequest,
  setPaymentTab,
  windowWidthSize,
}) => {
  const dateFormat = windowWidthSize >= 992 ? 'YYYY/MM/DD' : 'DD/MM/YY';
  let paymentDueDateTime = pendingPaymentList.length > 0 ? getPaymentDueDate(pendingPaymentList[0].end_date, paymentTerm) : null;
  return (
    <div className="payment-row next-payment">
      <div className="invoices ">
        {pendingPaymentList.length > 0 ? (
          pendingPaymentList.map((payment, index) => {
            const startDate = moment(payment.start_date).format(dateFormat);
            const endDate = moment(payment.end_date).format(dateFormat);
            const currentPaymentDueDateTime = getPaymentDueDate(payment.end_date, paymentTerm);
            if (payment.amount >= minimumPayoutAmount) {
              paymentDueDateTime = currentPaymentDueDateTime;
            }
            const amountDue = parseFloat(payment.amount).toFixed(2);
            return (
              <div className="tr" key={index}>
                <div className="td">
                  <div className="payment-box">
                    <InvoiceIcon />
                    {startDate} - {endDate}
                  </div>
                </div>
                <div className="td due-date">{paymentDueDateTime ? paymentDueDateTime.format(dateFormat) : ''}</div>
                <div className="td">
                  <div className="amount-box">
                    <FormattedNumber currency={payment.currency} style="currency" value={amountDue} />
                    <div className="pdf-invoice details-box" onClick={(e) => handleInvoiceRequest(e, payment.id)}>
                      <span className="details-box">
                        <i
                          className="material-icons primary-color "
                          onClick={() => Gtm.newEvent('payment_history', false, 'click', 'string', 'print icon - invoice')}
                        >
                          remove_red_eye
                        </i>
                        <span className="details-button">details</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="next-payments-empty-state">
            <p>
              No upcoming payments yet. <br /> Here you'll find all the payments you’re due.
            </p>
            <div className="btn-box">
              <span className="current-btn" onClick={() => setPaymentTab('current')}>
                <ArrowBtnIcon /> Current period
              </span>
              <span className="history-btn" onClick={() => setPaymentTab('history')}>
                Payment History <ArrowBtnIcon />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
