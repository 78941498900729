import { OFFER_TAG_TEXT } from '../../OfferListingTable/data';

export const OFFER_SEARCH_FORM_NAME = 'offersSearch';
export const OFFER_SEARCH_FIELD = 'searchString';
export const SELECTABLE_TAB_CSS_NAME = 'selectableTab';
export const MAIN_DROPDOWN_NAME = 'greyTabs';
export const CATEGORY_FILTER_KEY = 'Category';

//DROPDOWN FIELD NAMES
export const offerDropdownFields = Object.freeze({
  category: CATEGORY_FILTER_KEY,
  vertical: 'Vertical',
  payoutType: 'Payout',
  targeting: 'Targeting',
  acceptedCountry: 'Country',
  niche: 'Niche',
  status: 'approval_status',
  acceptedPromotionMethod: 'MediaPlacement',
  performsIn: 'TopCountries',
  id: 'Id',
  optimizedFor: 'OptimizedFor',
});

export const offerMobileDropdownStateFields = Object.freeze({
  mainDropdownActive: 'mainDropdownActive',
  subDropdown: 'subDropdown',
});

//DROPDOWN LABELS
export const offerDropdownLabels = Object.freeze({
  [offerDropdownFields.category]: 'Tag',
  [offerDropdownFields.vertical]: 'Vertical',
  [offerDropdownFields.payoutType]: 'Payout Type',
  [offerDropdownFields.targeting]: 'Targeting',
  [offerDropdownFields.acceptedCountry]: 'Accepted Country',
  [offerDropdownFields.niche]: 'Niche',
  [offerDropdownFields.status]: 'Status',
  [offerDropdownFields.acceptedPromotionMethod]: 'Promotion Method',
  [offerDropdownFields.performsIn]: 'Performs In',
  [offerDropdownFields.optimizedFor]: 'Optimized For',
});

//DROPDOWN CUSTOM LABELS INSIDE THE DROPDOWN
export const offerDropdownCustomLabels = Object.freeze({
  [offerDropdownFields.acceptedPromotionMethod]: 'Promo Method',
});

//GREEN MOBILE CTA TYPES
export const largeCtaTypes = Object.freeze({
  mainFilter: 'mainFilter',
  mobileMenuBottom: 'mobileMenuBottom',
  sort: 'sort',
});

export let statusDropdown = ['Approved', 'Pending', 'Rejected', 'Required'];
statusDropdown = statusDropdown.map((text) => ({ name: text }));

export const filterNiche = (categoriesList) =>
  categoriesList
    .filter((category) => category.name.includes('Niche'))
    .map((category) => ({ ...category, name: category.name.split('Niche - ')[1] }));

export const categoryDropdownValues = Object.values(OFFER_TAG_TEXT).map((text) => ({ name: text }));
