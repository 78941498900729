import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchOffer } from '../offer/Offer-Actions/Offer-Actions';
import React, { useEffect, useRef, useState } from 'react';
import { openModal } from '../../components/Modal/modal-actions/modal-actions.js';
import { useCallback } from 'react';
import { BUTTON_TEXT_BY_STATUS, CHIP_TEXT, PARAGRPAHS_BY_STATUS, STATUSES, VAST_GTM_EVENTS, VAST_OUTLINK } from './values.js';
import { Gtm } from '../../utils/gtm.js';
import Container from '../../components/reusables/Container.react';
import PreviewTitleIcon from './PreviewTitleIcon';
import { Scroller } from '../../components/reusables';
import WarningIcon from './WarningIcon';
import CreativeAdTools, { DEFAULT_VAST_VIDEO_SRC } from '../../components/reusables/creatives/CreativesAdTools';
import { getFeatureFlagValueFromLocalStorage } from '../../utils/featureFlagHelper';

const DEFAULT_VAST_TAG = 'https://cpm.afkwa.com/vast?zone=196804&tagid=5545&page_url=crakrevenue.com/vast-pre-roll';

const VastPreRoll = ({ dispatch, offer, offerIsFetching }) => {
  const [showVideoSlider, setShowVideoSlider] = useState(false);
  const [selectedOption, setSelectedOption] = useState('sample');
  const [selectedTabs, setSelectedTabs] = useState('preroll');
  const [isVideoInitialized, setIsVideoInitialized] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isVideoRestartOngoing, setIsVideoRestartOngoing] = useState(false);
  const [isFirstPlay, setIsFirstPlay] = useState(true);
  const customVastInputRef = useRef(null);
  const [vastTag, setVastTag] = useState(DEFAULT_VAST_TAG);
  const fluidPlayerRef = useRef(null);
  let approvalStatus = '';
  let warningText = '';

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.fluidplayer.com/v3/current/fluidplayer.min.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!offerIsFetching) {
    if (!offer.id || offer.approval_status === 'rejected') {
      approvalStatus = STATUSES.REJECTED;
    } else if (!offer.approval_status) {
      approvalStatus = STATUSES.UNAPPROVED;
    } else {
      approvalStatus = offer.approval_status;
    }
  }

  const buttonText = BUTTON_TEXT_BY_STATUS[approvalStatus];

  const onClick = useCallback(() => {
    const gtmValue = VAST_GTM_EVENTS[approvalStatus];

    switch (approvalStatus) {
      case STATUSES.APPROVED:
      case STATUSES.REJECTED:
      case STATUSES.PENDING:
        window.Intercom('showNewMessage');
        Gtm.event(`vast preroll page`, 'Click', 'need_help');
        break;
      case STATUSES.UNAPPROVED:
        dispatch(
          openModal({
            name: 'OfferRequestModal',
            modalProps: {
              dispatch,
              vastPreRoll: true,
            },
          })
        );
        break;
    }
    if (gtmValue) {
      Gtm.event('UI Component', 'Click', gtmValue);
    }
  }, [approvalStatus, dispatch]);

  if (!offerIsFetching) {
    if (!offer.id || offer.approval_status === 'rejected') {
      warningText = (
        <p>
          Here are some examples of what can be achieved with a VAST Tag. Unfortunately, your request was rejected. Feel free to{' '}
          <span onClick={onClick}>contact us</span> if you need any assistance or further clarification.
        </p>
      );
    } else if (approvalStatus === STATUSES.PENDING) {
      warningText = (
        <p>
          Here are some examples of what can be achieved with a VAST Tag. Your request is currently under review. We’ll notify you
          once a decision is made. If you have any questions in the meantime, feel free to{' '}
          <span onClick={onClick}>reach out</span>.
        </p>
      );
    } else if (!offer.approval_status) {
      warningText = (
        <p>
          Here are some examples of what can be achieved with a VAST Tag. <span onClick={onClick}>Request approval</span> and
          provide your traffic source details to utilize this tool.
        </p>
      );
    } else {
      warningText = (
        <p>
          Here are some examples of what can be achieved with a VAST Tag. <span onClick={onClick}>Reach out</span> if you need
          help setting this up with your personal VAST Tag URL.
        </p>
      );
    }
  }

  const destroyVastFluidPlayer = () => {
    let videoContainer = document.getElementById('video-container');
    let currentVideo = document.getElementById('fluid-video');
    if (window.fluidPlayer && videoContainer) {
      try {
        if (fluidPlayerRef.current) {
          if (currentVideo) {
            if (!currentVideo.paused) {
              fluidPlayerRef.current.on('pause', function () {
                // Destroy only after pause completed.
                fluidPlayerRef.current.destroy();
                fluidPlayerRef.current = null;
              });
              currentVideo.pause();
              fluidPlayerRef.current.pause();
            } else {
              fluidPlayerRef.current.destroy();
              fluidPlayerRef.current = null;
              currentVideo.remove();
            }
          }
        } else {
          if (currentVideo) {
            currentVideo.remove();
          }
        }
        setIsVideoInitialized(false);
      } catch (error) {
        console.error('Error unable to destroy the fluid Player:', error);
        return false;
      }
    }
  };

  const setupVastFluidPlayer = (playVideoAfterInit = false) => {
    setIsVideoRestartOngoing(true);
    let videoContainer = document.getElementById('video-container');
    if (window.fluidPlayer && videoContainer) {
      try {
        destroyVastFluidPlayer();

        const newVideo = document.createElement('video');
        newVideo.id = 'fluid-video';
        newVideo.setAttribute('controls', '');
        newVideo.style.width = '100%';

        const newSource = document.createElement('source');
        newSource.src = DEFAULT_VAST_VIDEO_SRC;
        newSource.type = 'video/mp4';
        newSource.style.width = 'inherit';

        newVideo.appendChild(newSource);
        videoContainer.appendChild(newVideo);

        fluidPlayerRef.current = fluidPlayer('fluid-video', {
          vastOptions: {
            adList: [
              {
                roll: 'preRoll',
                vastTag,
              },
            ],
          },
        });

        const videoWrapper = document.getElementById('fluid_video_wrapper_fluid-video');
        if (videoWrapper) {
          videoWrapper.style.width = '100%';
          videoWrapper.style.height = 'unset';
        }

        fluidPlayerRef.current.on('pause', function () {
          setIsVideoPlaying(false);
        });
        fluidPlayerRef.current.on('play', function () {
          setIsVideoPlaying(true);
        });
        setIsVideoInitialized(true);
        if (playVideoAfterInit) {
          fluidPlayerRef.current.play();
        }
        setIsVideoRestartOngoing(false);
        return true;
      } catch (error) {
        console.error('Error initializing Fluid Player:', error);
        setIsVideoInitialized(false);
        setIsVideoRestartOngoing(false);
        return false;
      }
    } else {
      setIsVideoInitialized(false);
      setIsVideoRestartOngoing(false);
      return false;
    }
  };

  const restartVastFluidPlayer = (playVideoAfterInit = false) => {
    setIsVideoRestartOngoing(true);
    if (isVideoPlaying) {
      fluidPlayerRef.current.pause();
      setTimeout(() => {
        setupVastFluidPlayer(playVideoAfterInit);
      }, 500);
    } else {
      setupVastFluidPlayer(playVideoAfterInit);
    }
    setIsVideoRestartOngoing(false);
  };

  useEffect(() => {
    dispatch(fetchOffer(process.env.REACT_APP_VAST_PREROLL_OFFER_ID));
  }, []);

  useEffect(() => {
    let timerVast = null;
    let attempts = 0;
    const maxAttempts = 5;
    const retryDelay = 1000;

    const attemptInitialization = () => {
      if (attempts >= maxAttempts) {
        console.warn('Max retry attempts reached. Stopping Fluid Player initialization.');
        return;
      }

      const initialized = setupVastFluidPlayer();
      if (!initialized) {
        attempts += 1;
        timerVast = setTimeout(attemptInitialization, retryDelay);
      }
    };

    if (selectedTabs === 'preroll') {
      attemptInitialization();
    } else {
      if (isVideoInitialized) {
        destroyVastFluidPlayer();
        setIsFirstPlay(true);
      }
      setShowVideoSlider(false);
    }

    return () => {
      if (timerVast) {
        clearTimeout(timerVast);
      }
    };
  }, [selectedTabs, selectedOption]);

  const videoSliderScript = `<script src="https://cfgrcr1.com/popin/latest/popin-min.js"></script>
  <script>var crakPopInParams = {
    contentUrl: 'https://cpm.afkwa.com/vast?zone=196804&tagid=5545&page_url=crakrevenue.com/vast-pre-roll',
    contentType: 'vast',
    leadOut: false,
    width: '440px',
    height: '260px',
    timeout: 100,
    delayClose: 0,
    clickStart: false,
    closeIntent: false,
    borderColor: '#000',
    closeButtonColor: '#000',
    closeCrossColor: '#fff',
    shadow: false,
    shadowColor: '#000',
    shadowOpacity: '.5',
    shadeColor: '#111',
    shadeOpacity: '0',
    border: '1px',
    borderRadius: '0px',
    animation: 'slide',
    direction: 'left',
    verticalPosition: 'bottom',
    horizontalPosition: 'right',
    expireDays: 0.001
  };
  </script>`;

  const handleTestBtn = () => {
    if (showVideoSlider) {
      setShowVideoSlider(false);
      setTimeout(() => setShowVideoSlider(true), 200);
    } else {
      setShowVideoSlider(true);
    }
    if (selectedTabs === 'preroll' && fluidPlayerRef.current && !isVideoRestartOngoing) {
      if (isVideoInitialized && !isVideoPlaying && isFirstPlay) {
        fluidPlayerRef.current.play();
        setIsFirstPlay(false);
      } else {
        restartVastFluidPlayer(true);
      }
    }
  };

  return (
    <div className={`vastPreRoll container-fluid app-container ${offerIsFetching ? 'loading' : ''}`}>
      <div className="topTitle">
        <h1 className="primary-color title">VAST Tag Pre-Roll Ads</h1>
        <div className={`chipTag ${approvalStatus}`}>{CHIP_TEXT[approvalStatus]}</div>
      </div>
      <div className="content">
        <div className="leftContent">
          <p>
            Discover the power of VAST tags, the gold standard for unleashing captivating pre-roll video ads! Elevate your
            campaign's performance while ensuring maximum visibility for your video ads. Our expert team provides an optimal
            rotation with the help of machine learning to help you reach full potential. Please note that a VAST-compatible video
            player is required for this tool. Learn more about the realm of possibilities with VAST 3.0{' '}
            <a href={VAST_OUTLINK} rel="noreferrer" target="_blank">
              here.
            </a>
          </p>
          {PARAGRPAHS_BY_STATUS[approvalStatus] ? (
            <p>
              <div className="subText">
                <div className="circle" />
                <span>{PARAGRPAHS_BY_STATUS[approvalStatus]}</span>
              </div>
            </p>
          ) : null}
        </div>
        <button className="btn" disabled={approvalStatus === STATUSES.PENDING} onClick={onClick}>
          {buttonText}
        </button>
      </div>
      <Container Svg={PreviewTitleIcon} className="z-depth-2 black-title vast-container" title="VAST Tag Pre-Roll Ads">
        <Scroller classNames={['tabs', 'text-center']}>
          <ul className="">
            <li className={selectedTabs === 'preroll' ? 'active' : null} onClick={() => setSelectedTabs('preroll')}>
              Pre-Roll
            </li>
            <li className={selectedTabs === 'slider' ? 'active' : null} onClick={() => setSelectedTabs('slider')}>
              Video Slider
            </li>
          </ul>
        </Scroller>
        <div className="warning-box">
          <WarningIcon />
          {warningText}
        </div>
        <div className="preview-content-box">
          <div className="preview-info">
            <h2>Video Ad Tag</h2>
            <p>
              Explore how a VAST tag operates within a video player with our live demo. <br /> This interactive test lets you see
              what’s possible when integrating our VAST
            </p>
            <div className="vast-options">
              <div className="radio-btn-box">
                <input
                  checked={Boolean(selectedTabs === 'slider' || selectedOption === 'sample')}
                  name="vastOption"
                  onChange={(e) => {
                    setSelectedOption(e.target.value);
                    setVastTag(DEFAULT_VAST_TAG);
                  }}
                  id="sample"
                  type="radio"
                  value="sample"
                />
                <label htmlFor="sample">
                  <span className="radio-circle"></span>
                  Sample VAST Tag
                </label>
              </div>
              {selectedTabs === 'preroll' && getFeatureFlagValueFromLocalStorage('vast_custom_ff') === '1' ? (
                <>
                  <div className="radio-btn-box">
                    <input
                      checked={Boolean(selectedOption === 'custom')}
                      name="vastOption"
                      onChange={(e) => {
                        setSelectedOption(e.target.value);
                        if (customVastInputRef.current) {
                          setVastTag(customVastInputRef.current?.value || '');
                        }
                      }}
                      placeholder={DEFAULT_VAST_TAG}
                      type="radio"
                      id="custom"
                      value="custom"
                    />
                    <label htmlFor="custom">
                      <span className="radio-circle" />
                      Custom
                    </label>
                  </div>
                  <textarea
                    ref={customVastInputRef}
                    type="text"
                    disabled={selectedOption !== 'custom'}
                    placeholder="Enter custom VAST URL"
                    onChange={(e) => {
                      if (selectedOption === 'custom') {
                        setVastTag(e.target.value);
                      }
                    }}
                  />
                </>
              ) : null}
            </div>

            <button
              className="test-btn btn"
              disabled={selectedTabs === 'preroll' && isVideoRestartOngoing}
              onClick={handleTestBtn}
              type="button"
            >
              Test Ad
            </button>
          </div>
          <div className="preview-widget">
            {selectedTabs === 'slider' && (
              <CreativeAdTools adToolType="vast" creativeCode={videoSliderScript} isVast show={showVideoSlider} />
            )}
            {selectedTabs === 'preroll' && <CreativeAdTools adToolType="vast" isDemoTag isPreRoll isVast />}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default connect((state) => ({
  offer: state.offer.offer,
  offerIsFetching: state.offer.offerIsFetching,
}))(VastPreRoll);
