const Telegram = () => (
  <svg fill="none" height="44" viewBox="0 0 44 44" width="44" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_484_8683)">
      <circle cx="22" cy="22" r="21.5" stroke="black" />
      <path
        clipRule="evenodd"
        d="M10.6225 21.1777C16.7879 18.4738 20.9092 16.7261 22.9533 15.8688C28.822 13.4287 30.0419 13 30.8332 13C30.998 13 31.3937 13.033 31.6574 13.2308C31.8553 13.3957 31.9212 13.6265 31.9542 13.7914C31.9871 13.9563 32.0201 14.319 31.9871 14.6158C31.6574 17.9462 30.3057 26.0909 29.5803 29.8171C29.2836 31.3999 28.6901 31.9275 28.1296 31.9934C26.9097 32.0923 25.9536 31.169 24.7667 30.4106C22.9203 29.1906 21.8653 28.4321 20.0519 27.2451C17.9748 25.8601 19.3266 25.1017 20.5135 23.8816C20.8102 23.5519 26.2503 18.6387 26.3492 18.177C26.3492 18.1111 26.3822 17.9132 26.2503 17.8143C26.1184 17.7154 25.9536 17.7484 25.8217 17.7813C25.6239 17.8143 22.6566 19.7928 16.8868 23.6838C16.0296 24.2773 15.2713 24.5411 14.5789 24.5411C13.8206 24.5411 12.3699 24.1125 11.2819 23.7497C9.96305 23.3211 8.90801 23.0902 9.00692 22.3648C9.10583 21.9691 9.63335 21.5734 10.6225 21.1777Z"
        fill="black"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_484_8683">
        <rect fill="white" height="44" width="44" />
      </clipPath>
    </defs>
  </svg>
);

export default Telegram;
