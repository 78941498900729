import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAdToolsContext } from '../CreativesAdTools';
import { PREVIEW_TABS, formatCreativeCode, previewCustomValues } from './values';

const createIframe = ({ creativeCode, adToolType, isChathead, isVast }) => {
  const html = `
      <html>
      <body>
          <style>
              body {
                  padding: ${isVast ? '20px' : '0'};
                  margin: 0;
                  background: ${isChathead || isVast ? '#757575' : '#EBEBEB'};
              }
              ${adToolType === 'postitial' ? `#link {opacity: 0;}` : ''}
          </style>
          ${adToolType === 'postitial' ? `<a id="link" href="https://some-test-site-for-iframe-load.com/">link text</a>` : ''}
          ${creativeCode}
          <script>
              window.addEventListener("DOMContentLoaded", () => {
                  const cookies = document.cookie.split(';');
                  cookies.forEach((cookie) => {
                      let [key, value] = cookie.split('=');
                      key = key.trim();
                      if (key.includes('crak-popin')) {
                          document.cookie = key + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                      }
                  });
                  ${
                    adToolType === 'postitial'
                      ? `
                    setTimeout(() => { const link = document.getElementById('link');
                        link.click();
                      }, 300)              
                  `
                      : ''
                  }
              });
          </script>
      </body>
      </html>
    `;

  return html;
};

const DEVICE_TYPES = Object.freeze({
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
});

const CSS_DIMENSIONS = Object.freeze({
  [DEVICE_TYPES.DESKTOP]: {
    large: {
      'min-width': 1220,
      'min-height': 780,
    },
    small: {
      'min-width': 1220,
      'min-height': 780,
    },
  },
  [DEVICE_TYPES.MOBILE]: {
    large: {
      'min-width': 375,
      'min-height': 680,
    },
    small: {
      'min-width': 375,
      'min-height': 600,
    },
  },
});

const CHAT_HEAD_CSS_DIMENSIONS = Object.freeze({
  [DEVICE_TYPES.DESKTOP]: {
    large: {
      'min-width': 780,
      'min-height': 600,
    },
    small: {
      'min-width': 1220,
      'min-height': 780,
    },
  },
  [DEVICE_TYPES.MOBILE]: {
    large: {
      'min-width': 450,
      'min-height': 680,
    },
    small: {
      'min-width': 375,
      'min-height': 600,
    },
  },
});

const VAST_CSS_DIMENSIONS = Object.freeze({
  [DEVICE_TYPES.DESKTOP]: {
    large: {
      'min-width': 1018,
      'min-height': 529,
    },
    small: {
      'min-width': 1220,
      'min-height': 780,
    },
  },
  [DEVICE_TYPES.MOBILE]: {
    large: {
      'min-width': 352,
      'min-height': 620,
    },
    small: {
      'min-width': 352,
      'min-height': 620,
    },
  },
});

const tabs = [];

for (const key in DEVICE_TYPES) {
  const value = DEVICE_TYPES[key];
  tabs.push(value);
}

const DeviceTab = React.memo(({ tab, selected, setDevice }) => {
  const { gtmEvent } = useAdToolsContext();

  const onClick = useCallback(() => {
    setDevice((device) => ({
      ...device,
      switching: true,
      deviceType: tab,
    }));
    gtmEvent(`device type - ${tab}`);
  }, [selected, tab]);

  return (
    <div className={`tab ${tab} ${selected ? 'selected' : ''}`} onClick={selected ? null : onClick}>
      {tab === DEVICE_TYPES.DESKTOP ? (
        <svg viewBox="0 0 19 18.685" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.994,2.658H6.007A3.51,3.51,0,0,0,2.5,6.164v7.5a3.512,3.512,0,0,0,3.507,3.508H11.25v2.67H8.055a.75.75,0,1,0,0,1.5h7.89a.75.75,0,0,0,0-1.5H12.75v-2.67h5.244A3.511,3.511,0,0,0,21.5,13.665v-7.5A3.51,3.51,0,0,0,17.994,2.658Z"
            fillRule="evenodd"
            transform="translate(-2.5 -2.658)"
          />
        </svg>
      ) : (
        <svg viewBox="0 0 13.7 19" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.95,18.15a.95.95,0,1,1,.95-.95A.969.969,0,0,1,11.95,18.15ZM14.75,2.5H9.25a4.106,4.106,0,0,0-4.1,4.1V17.4a4.106,4.106,0,0,0,4.1,4.1h5.5a4.106,4.106,0,0,0,4.1-4.1V6.6A4.106,4.106,0,0,0,14.75,2.5Z"
            fillRule="evenodd"
            transform="translate(-5.15 -2.5)"
          />
        </svg>
      )}
    </div>
  );
});

const Iframe = React.memo(({ show }) => {
  let { adToolType, creativeCode, previewTab, isChathead, isLiveCamWidget, isVast, isPreRoll, isDemoTag } = useAdToolsContext();

  const active = previewTab === PREVIEW_TABS.SHOW_PREVIEW;

  const timeoutRef = useRef(null);

  const customValues = previewCustomValues?.[adToolType];

  creativeCode = formatCreativeCode({
    object: customValues,
    creativeCode,
  });

  const iframeCode = !isLiveCamWidget ? createIframe({ creativeCode, adToolType, isChathead, isVast }) : creativeCode;

  const [device, setDevice] = useState({
    deviceType: '',
    iframeLoading: true,
    switching: false,
    css: {
      'min-width': '',
      'min-height': '',
      transform: '',
    },
  });

  const iframeLoading = device.iframeLoading;
  const deviceType = device.deviceType;
  const switching = device.switching;

  const iframeBrowserAreaRef = useRef(null);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    let deviceType = DEVICE_TYPES.DESKTOP;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && windowWidth < 768) {
      deviceType = DEVICE_TYPES.MOBILE;
    }

    setDevice((device) => ({
      ...device,
      iframeLoading: false,
      deviceType,
    }));
  }, []);

  const setCss = () => {
    timeoutRef.current = setTimeout(() => {
      const windowWidth = window.innerWidth;
      const viewport = windowWidth <= 1280 ? 'small' : 'large';
      const dimensions = (isChathead ? CHAT_HEAD_CSS_DIMENSIONS : isVast ? VAST_CSS_DIMENSIONS : CSS_DIMENSIONS)[deviceType][
        viewport
      ];
      const transform =
        isLiveCamWidget && deviceType === DEVICE_TYPES.DESKTOP
          ? null
          : `scale(${iframeBrowserAreaRef.current.offsetWidth / dimensions['min-width']})`;
      setDevice((device) => ({
        ...device,
        switching: false,
        css: {
          ...dimensions,
          transform,
        },
      }));
    }, 201);
  };

  useEffect(() => {
    if (!deviceType) {
      return;
    }

    clearTimeout(timeoutRef.current);
    const removeEventListener = () => window.removeEventListener('resize', setCss);
    removeEventListener();

    window.addEventListener('resize', setCss);
    setCss();

    return () => {
      clearTimeout(timeoutRef.current);
      removeEventListener();
    };
  }, [deviceType, adToolType, isChathead]);

  useEffect(() => {
    setCss();
  }, [show]);

  return (
    <div className={`iframeBox ${active ? 'active' : ''}`}>
      <div className={`deviceIcons selected-${deviceType}`}>
        {tabs.map((tab, i) => (
          <DeviceTab key={i} selected={deviceType === tab} setDevice={setDevice} tab={tab} />
        ))}
      </div>
      <div className={`deviceBox ${deviceType} ${isChathead ? 'chatHeadWidth' : ''}`}>
        <div className="topBarBox">
          <div className="bar">
            <div className="dots">
              <div className="dot red" />
              <div className="dot yellow" />
              <div className="dot green" />
            </div>
          </div>
        </div>
        <div className="iframeBrowserArea">
          {iframeLoading ? (
            <div className="loadingText" />
          ) : (
            <div
              className={`inner ${deviceType} ${switching ? 'switching' : ''} ${isLiveCamWidget ? 'iframe-border' : ''}`}
              ref={iframeBrowserAreaRef}
            >
              {isDemoTag ? <div className="demo-tag">THIS IS A DEMO</div> : null}
              {isLiveCamWidget ? (
                <iframe src={iframeCode} />
              ) : isVast && isPreRoll ? (
                <div id="video-container"></div>
              ) : (
                !switching && show && <iframe srcDoc={iframeCode} style={{ ...device.css }} />
              )}
            </div>
          )}
          {active && !isChathead && !isVast ? <div className="iframeCover" /> : null}
        </div>
      </div>
    </div>
  );
});

export default Iframe;
