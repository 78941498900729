import { CATEGORY_FILTER_KEY } from '../../components/reusables/offersSearch/values';

export const getApiQlStrings = (search) => {
  const createOrFilters = (array, key, condition = 'eq') =>
    array
      .map((val, i) => {
        const el = `key:"${key}", value:"${val}", condition:${condition}`;
        if (array.length > i + 1 && i === 0) {
          return `{${el}, or:[`;
        } else if (array.length === i + 1) {
          return `{${el}}]}`;
        }
        return `{${el}}, `;
      })
      .join('');

  // Make a filter from the search into a graphql filter
  const makeFilter = (filterName, apiqlKey, condition = 'eq') => {
    if ('filters' in search && filterName in search.filters) {
      const filters = search.filters[filterName];

      if (filters.length === 1) {
        return `{key:"${apiqlKey}", value:"${filters[0]}", condition:${condition}}`;
      } else if (filters.length > 1) {
        return createOrFilters(filters, `${apiqlKey}`, condition);
      }
    }
    return '';
  };

  // Search
  const searchString = search.searchString;
  let searchFilter = '';
  if (!isNaN(searchString) && searchString > 0) {
    searchFilter = `{key:"id", value:"${searchString}", condition:eq}`;
  } else if (searchString) {
    searchFilter = `{key: "name", value: "${searchString}", condition: cnt, or: [
          {key: "description", value: "${searchString}", condition: cnt},
          {key: "countries.name", value: "${searchString}", condition: cnt},
          {key: "categories.name", value: "${searchString}", condition: cnt},
          {key: "landing_pages.name", value: "${searchString}", condition: cnt},
        ]}`;
  }

  // Featured
  const featuredFilter = search.featured ? '{key:"featured", value:"null", condition:not_eq}' : '';

  // Status
  let statusFilter = '';
  if ('others' in search && 'approval_status' in search.others && search.others.approval_status.length > 0) {
    // multiple select
    const approvalStatusArray = `${search.others.approval_status}`.split(',');
    if (approvalStatusArray.length > 1) {
      statusFilter = createOrFilters(approvalStatusArray, 'approval_status');
    } else {
      statusFilter = `{key:"approval_status", value:"${search.others.approval_status}", condition:eq}`;
    }
  }

  // Countries
  let countryFilter = '';
  if ('filters' in search && 'Country' in search.filters) {
    const countries = search.filters.Country;
    if (countries.length === 1) {
      const excluded = `, and: [{key: "excluded_countries.code", value: "${countries[0]}", condition: not_eq, or: [{key: "excluded_countries", value: "0", condition: length}]}]`;
      countryFilter = `{key:"countries.code", value:"${countries[0]}", condition:eq, or: [{key:"countries", value:"0", condition:length}]${excluded} }`;
    } else if (countries.length > 1) {
      const and = `and: [{key: "excluded_countries", value: "0", condition: length, or: [{key: "excluded_countries.code", value: "${
        countries[0]
      }", condition: not_eq, and: [${countries
        .slice(1)
        .map((val) => `{key: "excluded_countries.code", value: "${val}", condition: not_eq}`)
        .join(', ')}]}]}]`;
      const or = `[${countries.map((val) => `{key:"countries.code", value:"${val}", condition:eq}`).join(',')}]`;
      countryFilter = `{key: "countries", value: "0", condition: length, ${and}, or:${or}}`;
    }
  }

  // Media placements
  const mediaPlacementFilter = makeFilter('MediaPlacement', 'media_placements.id');
  const topCountriesFilter = makeFilter('TopCountries', 'top_countries', 'cnt');
  const targetCountriesFilter = makeFilter('OptimizedFor', 'targeted_countries', 'cnt');

  const verticalFilters = makeFilter('Vertical', 'verticals.id');
  const targetingFilters = makeFilter('Targeting', 'channels.id');
  const payoutFilters = makeFilter('Payout', 'conversion_types.id');
  const idsFilters = makeFilter('Id', 'id');
  const tagsFilters = makeFilter(CATEGORY_FILTER_KEY, 'tags.name');

  // Filters
  const filtersStrings = [
    searchFilter,
    featuredFilter,
    statusFilter,
    countryFilter,
    mediaPlacementFilter,
    topCountriesFilter,
    verticalFilters,
    targetingFilters,
    payoutFilters,
    idsFilters,
    tagsFilters,
    targetCountriesFilter,
  ].filter((val) => !!val);

  // Categories
  if ('categories' in search) {
    for (const [key, value] of Object.entries(search.categories)) {
      const nbCategories = search.categories[key].length;
      if (nbCategories === 1) {
        filtersStrings.push(`{key:"categories.id", value:"${value}"}`);
      } else if (nbCategories > 1) {
        filtersStrings.push(createOrFilters(search.categories[key], 'categories.id'));
      }
    }
  }

  // sorting
  let sortStrings = [];
  if ('sort' in search) {
    sortStrings = Object.entries(search.sort).map(([key, value]) => `{sort:"${key}", direction:${value}}`);
  }

  return {
    filtersString: filtersStrings.join(','),
    sortString: sortStrings.join(','),
  };
};

export default (search) => {
  const { filtersString, sortString } = getApiQlStrings(search);

  const excludedCountriesString = `
        excluded_countries {
          id
          code
          name
        }
`;

  const targetedCountries = `targeted_countries`;

  return `
    Offers (
      sorts: [${sortString}, {sort:"name", direction:asc}]
      filters: [${filtersString}]
      ${search.limit && search.page ? `pager: {size:${search.limit}, page:${search.page - 1}}` : ``}
      ) {
      Data {
        id
        name
        description
        terms_and_conditions
        default_payout
        default_goal_name
        payout_type
        currency
        percent_payout
        preview_url
        featured
        require_terms_and_conditions
        require_approval
        approval_status
        email_instructions
        email_instructions_from
        email_instructions_subject
        show_mail_list
        dne_download_url
        dne_unsubscribe_url
        ${targetedCountries}
        top_countries
        epc_affiliation
        epc_global
        screenshot {
          desktop
          mobile
        }
        landing_pages {
          id
          name
          preview_url
          epc_affiliation
          status
          screenshot {
            desktop
            desktop_thumbnail
            mobile
            mobile_thumbnail
          }
        }
        channels {
          id
          name
        }
        countries {
          id
          code
          name
        }
        ${excludedCountriesString}
        verticals {
          id
          name
        }
        categories {
          id
          name
        }
        thumbnail {
          thumbnail
        }
        conversion_types {
          id
          name
        }
        tags {
          id
          name
        }
        media_placements {
            id
            name
            active
        }         
      }
      PageInfo {
        length
        currentPage
        lastPage
        pageSize
      }
    }
  `;
};
