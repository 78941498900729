import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { InputText, LoadingPage } from '../../components/reusables';

import validate from './validatePasswordEmailResetForm';

const PasswordResetEmailForm = ({ fields, handleSubmit, isDisabled, forcePasswordReset }) => (
  <div id="password-reset-form">
    <div className={isDisabled ? 'greyed-out' : ''}>
      <div className="form-info">
        {forcePasswordReset ? (
          <>
            <h2>Password reset</h2>
            <p>
              <FormattedMessage {...messages.loginPageForcePasswordReset} />
            </p>
          </>
        ) : (
          <>
            <h2>Forgot password?</h2>
            <p>Enter the email associated with your CrakRevenue account to receive a password reset link</p>
          </>
        )}
      </div>
      <form action="#" className="" method="post" onSubmit={handleSubmit}>
        <InputText
          autoFocus
          className="filterInputText"
          disabled={isDisabled}
          field={fields.email}
          id="email"
          label={messages.loginFormLabelEmailResetPassword}
          tabIndex={10}
          type="email"
        />
        <div className="form-buttons text-right">
          <button className="waves-effect waves-light btn" disabled={!fields.email.valid}>
            <FormattedMessage {...messages.passwordResetPassword} />
          </button>
        </div>
        <p className="need-help">
          Need help?{' '}
          <a className="support-link" href="https://support.crakrevenue.com/support/">
            contact support
          </a>
        </p>
      </form>
    </div>
    {isDisabled ? (
      <div className="is-loading">
        <LoadingPage fullScreen={false} />
      </div>
    ) : null}
  </div>
);

PasswordResetEmailForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm(
  {
    form: 'passwordResetForm',
    touchOnChange: false,
    touchOnBlur: false,
    fields: ['email'],
    validate,
  },
  () => ({
    initialValues: {
      email: '',
    },
  })
)(PasswordResetEmailForm);
