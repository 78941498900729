import { cloneElement, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/grid';

import { EffectCoverflow, Navigation, Autoplay, Grid } from 'swiper';

export default function SwiperSlider({
  children,
  isSlidable = true,
  loop = true,
  numberOfRows = 2,
  onSlideVisible,
  setIsSlidable,
  slidersWidth,
  slidesPerView = 'auto',
  spaceBetween = null,
  swiperParams = {},
  uid,
  useGrid = false,
  verticalsAsString,
}) {
  const carouselRef = useRef();
  const [active, setActive] = useState(false);
  const currentSlideLink = useRef(null);
  const isInitialLoad = useRef(false);
  let theChildren = null;
  let childrenCleaned = [];

  if (children) {
    childrenCleaned = [];
    theChildren = children.map((child) => {
      const theChild = child;
      if (theChild.props.className) {
        childrenCleaned.push(
          cloneElement(theChild, {
            ...theChild.props,
            className: theChild.props.className + ' swiper-slide',
          })
        );
      } else {
        childrenCleaned.push(
          cloneElement(theChild, {
            ...theChild.props,
            className: 'swiper-slide',
          })
        );
      }

      return theChild;
    });
  }

  useEffect(() => {
    const checkSlidable = () => {
      if (setIsSlidable) {
        if (!carouselRef.current) {
          return;
        }

        const container = carouselRef.current?.el;
        if (container) {
          const slides = container.querySelectorAll('.swiper-slide');

          const containerWidth = container.clientWidth;
          const totalSlidesWidth = Array.from(slides).reduce((acc, slide) => acc + slide.offsetWidth + spaceBetween, 0);

          if (useGrid) {
            setIsSlidable(totalSlidesWidth / numberOfRows > containerWidth);
          } else {
            setIsSlidable(totalSlidesWidth > containerWidth);
          }
        }
      }
    };

    setTimeout(checkSlidable, 0);

    window.addEventListener('resize', checkSlidable);

    return () => {
      window.removeEventListener('resize', checkSlidable);
    };
  }, [theChildren, spaceBetween, setIsSlidable, useGrid, numberOfRows]);

  useEffect(() => {
    if (slidersWidth && carouselRef.current?.update) {
      carouselRef.current.update();
    }
  }, [slidersWidth]);

  useEffect(() => {
    setActive(false);
  }, [verticalsAsString]);

  useEffect(() => {
    if (!active) {
      setActive(true);
    }
  }, [active]);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.update();
    }
  }, [carouselRef.current]);

  useEffect(() => {
    const swiper = carouselRef.current;

    if (!swiper) {
      return;
    }

    const nextButton = document.querySelector(`.swiper-next${uid ? `-${uid}` : ''}`);
    const prevButton = document.querySelector(`.swiper-prev${uid ? `-${uid}` : ''}`);

    const handleNext = () => swiper.slideNext();
    const handlePrev = () => swiper.slidePrev();

    nextButton.addEventListener('click', handleNext);
    prevButton.addEventListener('click', handlePrev);

    return () => {
      nextButton.removeEventListener('click', handleNext);
      prevButton.removeEventListener('click', handlePrev);
    };
  }, [carouselRef.current]);

  const getVisibleSlides = (swiper) =>
    new Promise((resolve) => {
      const container = swiper.el;
      const slides = swiper.slides;
      const visibleSlides = [];

      // Ensure container is ready
      if (!container || !container.offsetWidth) {
        console.warn('Container not ready for intersection observer');
        resolve([]);
        return;
      }

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && entry.intersectionRatio > 0.8) {
              const slide = entry.target;
              const index = parseInt(slide.getAttribute('data-swiper-slide-index'));
              if (!isNaN(index)) {
                visibleSlides.push({
                  element: slide,
                  index,
                });
              }
            }
          });
        },
        {
          root: container,
          threshold: [0, 0.8], // Multiple thresholds for better detection
          rootMargin: '0px',
        }
      );

      slides.forEach((slide) => {
        observer.observe(slide);
      });

      // Wait for Chrome to process
      setTimeout(() => {
        const uniqueSlides = Array.from(new Map(visibleSlides.map((slide) => [slide.index, slide])).values());

        uniqueSlides.sort((a, b) => a.index - b.index);

        observer.disconnect();

        resolve(uniqueSlides);
      }, 150);
    });

  const handleVisibleSlides = async (swiper) => {
    if (!onSlideVisible) {
      return;
    }

    try {
      const visibleSlides = await getVisibleSlides(swiper);

      visibleSlides.forEach(({ element, index }) => {
        onSlideVisible(index, true);
      });
    } catch (error) {
      console.error('Error handling visible slides:', error);
    }
  };

  if (!active) {
    return null;
  }

  const shouldHaveTwoRows = children.length > 6;

  return (
    <Swiper
      grabCursor={isSlidable}
      grid={shouldHaveTwoRows && useGrid ? { rows: numberOfRows, fill: 'row' } : undefined}
      loop={loop}
      modules={[EffectCoverflow, Navigation, Autoplay, Grid]}
      noSwiping={isSlidable}
      observeParents
      observeSlideChildren
      onSlideChange={(swiper) => {
        if (!swiper) {
          return;
        }

        setTimeout(() => {
          handleVisibleSlides(swiper);
        }, 100);
      }}
      onSwiper={(swiper) => {
        if (!swiper) {
          return;
        }
        carouselRef.current = swiper;

        if (onSlideVisible && isInitialLoad.current) {
          setTimeout(() => {
            handleVisibleSlides(swiper);
            isInitialLoad.current = true;
          }, 100);
        }
      }}
      onTouchStart={(event, targetParams) => {
        const targetLink = targetParams?.target?.closest('a')?.href;
        currentSlideLink.current = targetLink;
      }}
      onTransitionEnd={(event) => {
        const threshold = 15;
        let touchLength = event?.touches?.diff;
        touchLength = Math.abs(touchLength);
        const targetLink = currentSlideLink.current;

        if (touchLength && touchLength < threshold && targetLink) {
          window.location.href = targetLink;
        }
      }}
      preventClicks
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      {...swiperParams}
    >
      {theChildren.map((child, index) => (
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
}
