import './Skeleton.react.scss';

const Skeleton = ({ height, width, heightMobile, widthMobile, className, isDark = false }) => (
  <div
    className={`skeleton ${className} ${isDark ? 'dark' : ''}`}
    style={{
      height,
      width,
      '--mobile-height': heightMobile || height,
      '--mobile-width': widthMobile || width,
    }}
  />
);

export default Skeleton;
