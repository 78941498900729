import PropTypes from 'prop-types';

import { Gtm } from '../../utils/gtm';
import OfferListingDetails from '../../components/OfferListingTable/OfferListingDetails.react';

const HomeFeaturedOffers = (props) => {
  const { featuredOffers } = props;

  return (
    <div className="row" id="home-featured-offers">
      {featuredOffers.map((offer, index) => {
        const thumbnail = offer.thumbnail?.thumbnail;
        const onClick = () => {
          Gtm.event('dashboard', 'Click', `top offers - ${offer.name}`);
        };
        const href = `/offers/${offer.id}`;

        return (
          <OfferListingDetails key={index} {...offer} thumbnail={thumbnail} section="TopOffers" onClick={onClick} href={href} />
        );
      })}
    </div>
  );
};

HomeFeaturedOffers.defaultProps = {
  featuredOffers: [],
};

HomeFeaturedOffers.propTypes = {
  featuredOffers: PropTypes.array,
};

export default HomeFeaturedOffers;
