export default {
  loginPageTitle: {
    id: 'loginPage.title',
    description: 'Login to your account',
    defaultMessage: 'Login to your account',
  },
  loginPageButton: {
    id: 'loginPage.button',
    description: 'Log in',
    defaultMessage: 'Log in',
  },
  loginPageForgottenPassword: {
    id: 'loginPage.forgottenPassword',
    description: 'Forgotten password?',
    defaultMessage: 'Forgotten password?',
  },
  loginFormLabelEmail: {
    id: 'loginFormLabel.email',
    description: 'Your Email Address',
    defaultMessage: 'Your Email Address',
  },
  loginFormLabelEmailResetPassword: {
    id: 'loginFormLabel.emailResetPassword',
    description: 'Email Address',
    defaultMessage: 'Email Address',
  },
  loginFormLabelPassword: {
    id: 'loginFormLabel.password',
    description: 'Your Password',
    defaultMessage: 'Your Password',
  },
  loginFormLabelRememberMe: {
    id: 'loginFormLabel.rememberMe',
    description: 'Remember me',
    defaultMessage: 'Remember me',
  },
  loginFormLabelUserId: {
    id: 'loginFormLabel.userId',
    description: 'User ID',
    defaultMessage: 'User ID',
  },
  loginFormEmailOrPasswordAreNotValid: {
    id: 'loginFormLabel.emailOrPasswordAreNotValid',
    description: 'Email or password are not valid',
    defaultMessage: 'Email or password are not valid',
  },
  loginFormAccountInValidation: {
    id: 'loginFormLabel.accountInValidation',
    description: "Your account is in validation, you will receive an email when it's ready!",
    defaultMessage: "Your account is in validation, you will receive an email when it's ready!",
  },
  loginFormLackOfPermissions: {
    id: 'loginFormLabel.lackOfPermissions',
    description: 'Affiliate lacks permission to have an API Key',
    defaultMessage: 'Affiliate lacks permission to have an API Key',
  },
  loginFormCantOverride: {
    id: 'loginFormLabel.cantOverride',
    description: 'Affiliate lacks permission to have an API Key',
    defaultMessage: 'Affiliate lacks permission to have an API Key',
  },
  loginFormStepVerification: {
    id: 'loginFormLabel.stepVerification',
    description: '2-Step Verification',
    defaultMessage: '2-Step Verification',
  },
  loginFormStepVerificationText: {
    id: 'loginFormLabel.stepVerificationText',
    description: 'You are trying to log into your CrakRevenue account from this device for the first time.',
    defaultMessage: 'You are trying to log into your CrakRevenue account from this device for the first time.',
  },
  loginFormStepVerificationText2: {
    id: 'loginFormLabel.stepVerificationText2',
    description: 'For security reasons, please enter the code you’ve received by email in order to access your account.',
    defaultMessage: 'For security reasons, please enter the code you’ve received by email in order to access your account.',
  },
  loginFormStepVerificationNotReceiving: {
    id: 'loginFormLabel.stepVerificationTextNotReceiving',
    description: '*NOT RECEIVING YOUR CODE?',
    defaultMessage: '*NOT RECEIVING YOUR CODE?',
  },
  loginFormStepVerificationText3: {
    id: 'loginFormLabel.stepVerificationText3',
    description:
      'Most people receive their code right away, but in some cases, it can take several minutes (or over 20 in extreme cases) to get it. Thank you for your understanding.',
    defaultMessage:
      'Most people receive their code right away, but in some cases, it can take several minutes (or over 20 in extreme cases) to get it. Thank you for your understanding.',
  },
  loginPageStartSession: {
    id: 'loginPage.startSession',
    description: 'Start a session',
    defaultMessage: 'Start a session',
  },
  loginPageGoBackToWebsite: {
    id: 'loginPage.goBackToWebsite',
    description: 'Go back to website',
    defaultMessage: 'Go back to website',
  },
  loginPageGoBackToLogin: {
    id: 'loginPage.goBackToLogin',
    description: 'Go back to login',
    defaultMessage: 'Go back to login',
  },
  loginPageNiceToMeetYou: {
    id: 'loginPage.niceToMeetYou',
    description: 'Nice to Meet You!',
    defaultMessage: 'Nice to Meet You!',
  },
  loginPageDontHaveAnAccountYet: {
    id: 'loginPage.dontHaveAnAccountYet',
    description: "Don't have an account yet",
    defaultMessage: "Don't have an account yet",
  },
  loginPageIWantToBuyTraffic: {
    id: 'loginPage.iWantToBuyTraffic',
    description: 'I want to make $$$ with my traffic',
    defaultMessage: 'I want to make $$$ with my traffic',
  },
  loginPageIWantToSellTraffic: {
    id: 'loginPage.iWantToSellTraffic',
    description: 'I want to add my offer / brand',
    defaultMessage: 'I want to add my offer / brand',
  },
  loginPageAlreadyHaveAnAccount: {
    id: 'loginPage.alreadyHaveAnAccount',
    description: 'You Already Have An Account?',
    defaultMessage: 'You Already Have An Account?',
  },
  loginPageForcePasswordReset: {
    id: 'loginPage.forcePasswordReset',
    description: `We kindly ask you to select a new password to ensure your account remains secure. Thank you for your understanding and cooperation.`,
    defaultMessage: `We kindly ask you to select a new password to ensure your account remains secure. Thank you for your understanding and cooperation.`,
  },
};
