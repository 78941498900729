const OffersSliderImage = ({ className, href, src, index, sliderGtmEvent }) => {
  const onClick = (event) => {
    if (event.button === 0) {
      event.preventDefault();
    }
    sliderGtmEvent({ level: 1, positionIndex: index, imgSrc: src });
  };

  return (
    <>
      <a className={`innerSlideLarge ${className}`} href={href} onClick={onClick} data-index={index}>
        <div className="imgBox">
          <img src={src} />
        </div>
      </a>
    </>
  );
};

export default OffersSliderImage;
