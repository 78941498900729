export const PadlockIconMini = (unlocked = true) =>
  unlocked ? (
    <svg fill="none" height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.54183 4.01384C7.54183 3.25346 7.84389 2.52422 8.38156 1.98655C8.91924 1.44887 9.64848 1.14681 10.4089 1.14681C11.1692 1.14681 11.8985 1.44887 12.4362 1.98655C12.9738 2.52422 13.2759 3.25346 13.2759 4.01384V11.3475H14.4227V4.01384C14.4227 2.94931 13.9998 1.92837 13.2471 1.17563C12.4943 0.422886 11.4734 0 10.4089 0C9.34432 0 8.32339 0.422886 7.57065 1.17563C6.81791 1.92837 6.39502 2.94931 6.39502 4.01384V4.82399H7.54183V4.01384Z"
        fill="#00BD00"
      />
      <g filter="url(#filter0_bd_2094_753691)">
        <path
          clipRule="evenodd"
          d="M5.0922 9.0293C4.489 9.0293 4 9.48273 4 10.0421V19.157C4 19.7163 4.489 20.1698 5.0922 20.1698H15.5619C16.1651 20.1698 16.6541 19.7163 16.6541 19.157V10.0421C16.6541 9.48273 16.1651 9.0293 15.5619 9.0293H5.0922ZM9.23483 13.6372C9.23483 13.0497 9.72387 12.574 10.327 12.574C10.9302 12.574 11.4192 13.0497 11.4192 13.6372C11.4192 14.0031 11.2294 14.3256 10.9405 14.5174C10.8765 14.56 10.837 14.6312 10.8479 14.7063L11.0255 15.9159C11.0411 16.0227 10.9561 16.1187 10.8453 16.1187H9.8088C9.69801 16.1187 9.61294 16.0227 9.62854 15.9159L9.80613 14.7063C9.81707 14.6312 9.7776 14.56 9.7136 14.5174C9.42469 14.3256 9.23483 14.0031 9.23483 13.6372Z"
          fill="url(#paint0_linear_2094_753691)"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="23.1405"
          id="filter0_bd_2094_753691"
          width="20.6543"
          x="0"
          y="5.0293"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2094_753691" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="effect1_backgroundBlur_2094_753691" mode="normal" result="effect2_dropShadow_2094_753691" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_2094_753691" mode="normal" result="shape" />
        </filter>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2094_753691"
          x1="1.80786"
          x2="14.5432"
          y1="21.2838"
          y2="6.9525"
        >
          <stop stopColor="#00BD00" />
          <stop offset="1" stopColor="#00BD00" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg fill="none" height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.54183 4.01384C7.54183 3.25346 7.84389 2.52422 8.38156 1.98655C8.91924 1.44887 9.64848 1.14681 10.4089 1.14681C11.1692 1.14681 11.8985 1.44887 12.4362 1.98655C12.9738 2.52422 13.2759 3.25346 13.2759 4.01384V10.3475H14.4227V4.01384C14.4227 2.9493 13.9998 1.92837 13.2471 1.17563C12.4943 0.422886 11.4734 0 10.4089 0C9.34432 0 8.32339 0.422886 7.57065 1.17563C6.81791 1.92837 6.39502 2.9493 6.39502 4.01384V5.82399H7.54183V4.01384Z"
        fill="#707070"
      />
      <g filter="url(#filter0_bd_2145_180888)">
        <path
          clipRule="evenodd"
          d="M5.0922 6.0293C4.489 6.0293 4 6.48273 4 7.04207V16.157C4 16.7163 4.489 17.1698 5.0922 17.1698H15.5619C16.1651 17.1698 16.6541 16.7163 16.6541 16.157V7.04207C16.6541 6.48273 16.1651 6.0293 15.5619 6.0293H5.0922ZM9.23483 10.6372C9.23483 10.0497 9.72387 9.57399 10.327 9.57399C10.9302 9.57399 11.4192 10.0497 11.4192 10.6372C11.4192 11.0031 11.2294 11.3256 10.9405 11.5174C10.8765 11.56 10.837 11.6312 10.8479 11.7063L11.0255 12.9159C11.0411 13.0227 10.9561 13.1187 10.8453 13.1187H9.8088C9.69801 13.1187 9.61294 13.0227 9.62854 12.9159L9.80613 11.7063C9.81707 11.6312 9.7776 11.56 9.7136 11.5174C9.42469 11.3256 9.23483 11.0031 9.23483 10.6372Z"
          fill="#707070"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="23.1405"
          id="filter0_bd_2145_180888"
          width="20.6541"
          x="0"
          y="2.0293"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2145_180888" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="effect1_backgroundBlur_2145_180888" mode="normal" result="effect2_dropShadow_2145_180888" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_2145_180888" mode="normal" result="shape" />
        </filter>
      </defs>
    </svg>
  );
