import PropTypes from 'prop-types';
import { reduxForm, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';

import { InputText } from '../../components/reusables';

const OverrideForm = (props) => {
  const { dispatch, fields, handleSubmit, location } = props;
  const { email, userId } = location.query;

  const emailOverride = !!email;
  if (!fields.email.value && emailOverride) {
    dispatch(change('overrideForm', 'email', email));
  }
  if (!fields.userId.value && !!userId) {
    dispatch(change('overrideForm', 'userId', userId));
  }

  return (
    <div id="login-form">
      <div className="row">
        <form
          action="#"
          className="col-lg-4 col-lg-offset-4 col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2"
          method="post"
          onSubmit={handleSubmit}
        >
          <div className="row">
            <InputText
              autoComplete="new"
              autoFocus={!emailOverride}
              className="col-md-12"
              field={fields.email}
              id="email"
              label={messages.loginFormLabelEmail}
              tabIndex={10}
              type="email"
            />
          </div>
          <div className="row">
            <InputText
              autoComplete="new"
              autoFocus={emailOverride}
              canShowPassword={false}
              className="col-md-12"
              field={fields.password}
              id="password"
              label={messages.loginFormLabelPassword}
              tabIndex={20}
              type="password"
            />
          </div>
          <div className="row">
            <InputText
              autoComplete="new"
              className="col-md-12"
              field={fields.userId}
              id="userId"
              label={messages.loginFormLabelUserId}
              tabIndex={30}
              type="text"
            />
          </div>
          <div className="row">
            <div className="col-md-12 form-buttons text-right">
              <button className="waves-effect waves-light btn">
                <FormattedMessage {...messages.loginPageButton} />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

OverrideForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default reduxForm(
  {
    form: 'overrideForm',
    touchOnChange: false,
    touchOnBlur: false,
    fields: ['email', 'password', 'userId'],
  },
  () => ({
    initialValues: {
      email: '',
      password: '',
      userId: '',
    },
  })
)(OverrideForm);
