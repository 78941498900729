import PropTypes from 'prop-types';

import messages from '../../i18n/base-en.js';

import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils';
import Elite from '../achievements/rankingIcon/Elite';
import Active from '../achievements/rankingIcon/Active';
import Vip from '../achievements/rankingIcon/VIP';
import RisingStar from '../achievements/rankingIcon/RisingStar';
import React from 'react';
import {
  isActiveRankFromRankStatusName,
  isEliteRankFromRankStatusName,
  isRisingStarRankFromRankStatusName,
  isVIPRankFromRankStatusName,
} from '../../utils/rankingHelper';
import { connect } from 'react-redux';

/** Section d'informations de l'utilisateur dans le header du profil */
const ProfileUserBlock = ({ userInfos, payableTo, statusRankName, skinConfigurations, affStatus }) => {
  const infosClasses = payableTo === 'Company' ? 'in#fos company' : 'infos';
  const skin = new SkinConfigurationsUtils(skinConfigurations);

  const isAffRank = isActiveRankFromRankStatusName(affStatus.rank_status_name);
  const isRisingStarRank = isRisingStarRankFromRankStatusName(affStatus.rank_status_name);
  const isVIPRank = isVIPRankFromRankStatusName(affStatus.rank_status_name);
  const isEliteRank = isEliteRankFromRankStatusName(affStatus.rank_status_name);

  return (
    <div className="title-block new-user-block">
      <div className="ranking-icon">
        {isEliteRank ? <Elite /> : null}
        {isAffRank ? <Active /> : null}
        {isVIPRank ? <Vip /> : null}
        {isRisingStarRank ? <RisingStar /> : null}
      </div>
      <div className={infosClasses}>
        <h1>
          {userInfos.first_name} {userInfos.last_name}
        </h1>
        <div className="profile-header-aff-id">
          {skin.getPropertyValue('Profile', 'affiliateIdLabel', 'affiliateId') || messages.genericAffiliateId.description}:{' '}
          {userInfos.affiliate_id}
        </div>
        <div className="subtitle">{statusRankName}</div>
      </div>
    </div>
  );
};

ProfileUserBlock.propTypes = {
  payableTo: PropTypes.any.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  statusRankName: PropTypes.string,
  userInfos: PropTypes.object.isRequired,
};

export default connect((state) => ({
  affStatus: state.profile.data.affStatus,
}))(ProfileUserBlock);
