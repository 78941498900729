import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import { getCookie } from '../utils/helpers';

import config from '../config';
import consts from '../utils/consts';

const { API_URL } = config;
const { ACTIONS } = consts;

const requestStatistics = () => ({ type: ACTIONS.REQUEST_STATISTICS });
const receiveStatistics = (data, level) => ({
  type: ACTIONS.RECEIVE_STATISTICS,
  level,
  data,
});
const failedToReceiveStatistics = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_STATISTICS,
});
export const fetchStatistics =
  (search, level, useCache = false) =>
  (dispatch) => {
    dispatch(requestStatistics());
    return new Promise((fulfill, reject) =>
      request
        .get(`${API_URL}/statistics`)
        .withCredentials()
        .query({ search: JSON.stringify(search), useCache })
        .end((err, res) => {
          if (!err) {
            dispatch(receiveStatistics(res.body, level));
            fulfill(res.body);
          } else {
            dispatch(failedToReceiveStatistics());
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            reject();
          }
        })
    );
  };

const receiveAllStatistics = (data) => ({
  type: ACTIONS.RECEIVE_ALL_STATISTICS,
  data,
});
const failedToReceiveAllStatistics = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_ALL_STATISTICS,
});
export const fetchAllStatistics =
  (search, useCache = false) =>
  (dispatch) => {
    dispatch(requestStatistics());
    return new Promise((fulfill, reject) =>
      request
        .get(`${API_URL}/statistics`)
        .withCredentials()
        .query({
          search: JSON.stringify({ ...search, totals: 1, limit: 0 }),
          useCache,
        })
        .end((err, res) => {
          if (!err) {
            const totals =
              typeof res.body.response.data !== 'undefined' && res.body.response.data.totals instanceof Array
                ? {}
                : res.body.response.data.totals;
            dispatch(receiveAllStatistics(totals));
            fulfill();
          } else {
            dispatch(failedToReceiveAllStatistics());
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            reject();
          }
        })
    );
  };

const requestGraphStatistics = () => ({
  type: ACTIONS.REQUEST_GRAPH_STATISTICS,
});
const receiveGraphStatistics = (data) => ({
  type: ACTIONS.RECEIVE_GRAPH_STATISTICS,
  data,
});
const failedToReceiveGraphStatistics = (data) => ({
  type: ACTIONS.FAILED_TO_RECEIVE_GRAPH_STATISTICS,
  data,
});
export const fetchGraphStatistics =
  (search, selectedRows, useCache = false) =>
  (dispatch) => {
    dispatch(requestGraphStatistics());
    return new Promise((fulfill, reject) =>
      request
        .get(`${API_URL}/graph/statistics`)
        .withCredentials()
        .query({
          search: JSON.stringify(search),
          selectedRows: JSON.stringify(selectedRows),
          graphdata: true,
          useCache,
        })
        .end((err, res) => {
          if (!err) {
            dispatch(receiveGraphStatistics(res.body));
            fulfill();
          } else {
            dispatch(failedToReceiveGraphStatistics());
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            reject();
          }
        })
    );
  };

const requestSaveReport = () => ({ type: ACTIONS.REQUEST_SAVE_REPORT });
const receiveSaveReport = (id) => ({ type: ACTIONS.RECEIVE_SAVE_REPORT, id });
const failedToReceiveSaveReport = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_SAVE_REPORT,
});
export const saveReport = (name, report) => (dispatch) => {
  dispatch(requestSaveReport());
  return new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/statistics/saveReport`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .query({ name, report: JSON.stringify(report) })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveSaveReport(res.body));
          fulfill(res.body);
        } else {
          dispatch(failedToReceiveSaveReport());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestSavedReports = () => ({ type: ACTIONS.REQUEST_SAVED_REPORTS });
const receiveSavedReports = (savedReportsList) => ({
  type: ACTIONS.RECEIVE_SAVED_REPORTS,
  savedReportsList,
});
const failedToReceiveSavedReports = () => ({
  type: ACTIONS.RECEIVE_SAVED_REPORTS,
});
export const fetchAllSavedReports = () => (dispatch) => {
  dispatch(requestSavedReports());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/statistics/savedReports`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveSavedReports(res.body.data));
          fulfill(res.body.data);
        } else {
          dispatch(failedToReceiveSavedReports());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

const requestDeleteSavedReport = () => ({ type: ACTIONS.REQUEST_DELETE_REPORT });
const receiveDeleteSavedReport = (id) => ({
  type: ACTIONS.RECEIVE_DELETE_REPORT,
  id,
});
const failedToReceiveDeleteSavedReport = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_DELETE_REPORT,
});
export const deleteSavedReport = (id) => (dispatch) => {
  dispatch(requestDeleteSavedReport());
  return new Promise((fulfill, reject) =>
    request
      .delete(`${API_URL}/statistics/savedReport`)
      .withCredentials()
      .query({ id })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveDeleteSavedReport(id));
          fulfill();
        } else {
          dispatch(failedToReceiveDeleteSavedReport());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};

export const addAllSelectedRows = () => ({
  type: ACTIONS.STATISTICS_ADD_ALL_SELECTED_ROWS,
});
export const addSelectedRow = (int, value) => ({
  type: ACTIONS.STATISTICS_ADD_SELECTED_ROW,
  int,
  value,
});
export const changeColumnSort = (field, way) => ({
  type: ACTIONS.STATISTICS_CHANGE_COLUMN_SORT,
  field,
  way,
});
export const changeStatisticsListPage = (page) => ({
  type: ACTIONS.CHANGE_STATISTICS_LIST_PAGE,
  page,
});
export const changeStatisticsRowsPerPage = (limit, page) => ({
  type: ACTIONS.CHANGE_STATISTICS_ROWS_PER_PAGE,
  limit,
  page,
});
export const removeAllStatisticsFilters = () => ({
  type: ACTIONS.REMOVE_ALL_STATISTICS_FILTERS,
});
export const removeSelectedRow = (int) => ({
  type: ACTIONS.STATISTICS_REMOVE_SELECTED_ROW,
  int,
});
export const removeStatisticsSearchFiltersTag = (id, value, compare, newRequest = false) => ({
  type: ACTIONS.REMOVE_STATISTICS_SEARCH_FILTERS_TAG,
  id,
  value,
  compare,
  newRequest,
});
export const resetIsFetching = () => ({ type: ACTIONS.RESET_IS_FETCHING });
export const resetStatistics = () => ({ type: ACTIONS.RESET_STATISTICS });
export const setDrillDownLevel = (level) => ({
  type: ACTIONS.SET_DRILL_DOWN_LEVEL_BACK,
  level,
});
export const setDrillDownValues = (obj, group, level, drillField, drillValue) => ({
  type: ACTIONS.SET_DRILL_DOWN_VALUES,
  obj,
  group,
  level,
  drillField,
  drillValue,
});
export const setSelectedRows = (values) => ({
  type: ACTIONS.STATISTICS_SET_SELECTED_ROWS,
  values,
});
export const setStatisticsColumns = (columns) => ({
  type: ACTIONS.SET_STATISTICS_COLUMNS,
  columns,
});
export const setStatisticsColumnsFilters = (filters) => ({
  type: ACTIONS.SET_STATISTICS_COLUMNS_FILTERS,
  filters,
});
export const setStatisticsDates = (startDate, endDate, timezone) => ({
  type: ACTIONS.SET_STATISTICS_DATES,
  startDate,
  endDate,
  timezone,
});
export const setStatisticsFilters = (filters) => ({
  type: ACTIONS.SET_STATISTICS_FILTERS,
  filters,
});
export const setStatisticsGroups = (groups) => ({
  type: ACTIONS.SET_STATISTICS_GROUPS,
  groups,
});
export const setStatisticsReport = (mainMenu, subMenu, report) => ({
  type: ACTIONS.SET_STATISTICS_REPORT,
  mainMenu,
  subMenu,
  report,
});
export const setStatisticsSearchFilters = (filters, newRequest = false) => ({
  type: ACTIONS.SET_STATISTICS_SEARCH_FILTERS,
  ...filters,
  newRequest,
});
