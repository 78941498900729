import notifications from './profile/notifications';
import paymentForm from './profile/paymentForm';
import paymentHistory from './profile/paymentHistory';
import referrals from './profile/referrals';
import userAndCompany from './profile/userAndCompany';

export default {
  profileTitle: {
    id: 'profile.title',
    description: 'Profile',
    defaultMessage: 'Profile',
  },
  profilePaymentHistoryTitle: {
    id: 'profile.profilePaymentHistoryTitle',
    description: 'Payments',
    defaultMessage: 'Payments',
  },
  profileBalanceTitle: {
    id: 'profile.profileBalanceTitle',
    description: 'Balance',
    defaultMessage: 'Balance',
  },
  profileHistoryTitle: {
    id: 'profile.profileHistoryTitle',
    description: 'History',
    defaultMessage: 'History',
  },
  profileUploadPopupTitle: {
    id: 'profile.profileUploadPopupTitle',
    description: 'Choose your profile avatar',
    defaultMessage: 'Choose your profile avatar',
  },
  profileUploadPopupDragAndDrop: {
    id: 'profile.profileUploadPopupDragAndDrop',
    description: 'Drag & drop your image',
    defaultMessage: 'Drag & drop your image',
  },
  profileUploadPopupUploadFromComputer: {
    id: 'profile.profileUploadPopupUploadFromComputer',
    description: 'Upload from computer',
    defaultMessage: 'Upload from computer',
  },
  profileUploadPopupOr: {
    id: 'profile.profileUploadPopupOr',
    description: 'or',
    defaultMessage: 'or',
  },
  profileMenuUserInfos: {
    id: 'profile.profileMenuUserInfos',
    description: 'User Details',
    defaultMessage: 'User Details',
  },
  profileMenuAccount: {
    id: 'profile.profileMenuAccount',
    description: 'Account',
    defaultMessage: 'Account',
  },
  profileMenuPersonalInformation: {
    id: 'profile.profileMenuPersonalInformation',
    description: 'Personal Information',
    defaultMessage: 'Personal Information',
  },
  profileMenuContactInformation: {
    id: 'profile.profileMenuPersonalInformation',
    description: 'Contact Information',
    defaultMessage: 'Contact Information',
  },
  profileMenuBillingInfosTitle: {
    id: 'profile.profileMenuBillingInfosTitle',
    description: 'Billing information',
    defaultMessage: 'Billing information',
  },
  profileMenuIdentity: {
    id: 'profile.profileMenuIdentity',
    description: 'Identity',
    defaultMessage: 'Identity',
  },
  profileMenuReferralsTitle: {
    id: 'profile.profileMenuReferralTitle',
    description: 'Referrals',
    defaultMessage: 'Referrals',
  },
  profileMenuReferralsListTitle: {
    id: 'profile.profileMenuReferralListTitle',
    description: 'List',
    defaultMessage: 'List',
  },
  profileMenuReferralsDescription: {
    id: 'profile.profileMenuReferralsDescription',
    description: 'Refer new affiliates to CrakRevenue and earn 5% of their income in commissions, for life!*',
    defaultMessage: 'Refer new affiliates to CrakRevenue and earn 5% of their income in commissions, for life!*',
  },
  profileMenuReferralsAsterisk: {
    id: 'profile.profileMenuReferralsAsterisk',
    description: '*See our Terms and Conditions for more details',
    defaultMessage: '*See our Terms and Conditions for more details',
  },
  profileMenuNotifications: {
    id: 'profile.profileMenuNotifications',
    description: 'Notifications',
    defaultMessage: 'Notifications',
  },
  profileMenuSettings: {
    id: 'profile.profileMenuSettings',
    description: 'Settings',
    defaultMessage: 'Settings',
  },
  profileBillingInfosTitle: {
    id: 'profile.profileBillingInfosTitle',
    description: 'Billing information',
    defaultMessage: 'Billing information',
  },
  profileUpdateBillingInfosCompleted: {
    id: 'profile.updateBillingInfosCompleted',
    description: 'Your billing information has been updated successfully',
    defaultMessage: 'Your billing information has been updated successfully',
  },
  profileUpdateSettingsCompleted: {
    id: 'profile.updateSettingsCompleted',
    description: 'Your settings has been updated successfully',
    defaultMessage: 'Your settings has been updated successfully',
  },
  profileEmailPreferencesTitle: {
    id: 'profile.profileMenuEmailPreferences',
    description: 'Email preferences',
    defaultMessage: 'Email preferences',
  },
  profileEmailPreferencesIntro: {
    id: 'profile.profileEmailPreferencesIntro',
    description: 'Select the content newsletters you wish to subscribe to.',
    defaultMessage: 'Select the content newsletters you wish to subscribe to.',
  },
  profileMenuEmailPreferences: {
    id: 'profile.profileMenuEmailPreferences',
    description: 'Email preferences',
    defaultMessage: 'Email preferences',
  },
  profileBillingInfoNotice: {
    id: 'profile.billingInfoNotice',
    description: 'Please note that any payment method must be updated AT LEAST 5 business days before the payment date',
    defaultMessage: 'Please note that any payment method must be updated AT LEAST 5 business days before the payment date',
  },
  profilePaymentHistoryInfo: {
    id: 'profile.paymentHistoryInfo',
    description:
      'Please note that it may take up to 5 business days following the end of a period for the payment history section to be updated',
    defaultMessage:
      'Please note that it may take up to 5 business days following the end of a period for the payment history section to be updated',
  },
  profileSettingsUnsavedChecked: {
    id: 'profile.settingsUnsavedChecked',
    description: 'You have unsaved information, are you sure you want to leave this page?',
    defaultMessage: 'You have unsaved information, are you sure you want to leave this page?',
  },
  profileNotReachedMinPayout: {
    id: 'profile.notReachedMinPayout',
    description: 'Since you did not reach your minimum payout (${amount}), your balance has been carried over.',
    defaultMessage: 'Since you did not reach your minimum payout (${amount}), your balance has been carried over.',
  },
  profileNotReachedMinPayoutInPaymentHistory: {
    id: 'profile.notReachedMinPayoutInPaymentHistory',
    description: 'You did not reach your minimum payout.',
    defaultMessage: 'You did not reach your minimum payout.',
  },
  profileYourCurrentBalance: {
    id: 'profile.yourCurrentBalance',
    description: 'Carried over earnings:',
    defaultMessage: 'Carried over earnings:',
  },
  profileBackToReferralsTable: {
    id: 'profile.backToReferralsTable',
    description: 'Back To Referrals Table',
    defaultMessage: 'Back To Referrals Table',
  },
  profileReferringCampaignsTitle: {
    id: 'profile.referringCampaignsTitle',
    description: 'Referring Campaigns',
    defaultMessage: 'Referring Campaigns',
  },
  profilePromoteCrakrevenue: {
    id: 'profile.promoteCrakrevenue',
    description: 'Promote CrakRevenue and earn some commissions',
    defaultMessage: 'Promote CrakRevenue and earn some commissions',
  },
  profileShowAdultContent: {
    id: 'profile.showAdultContent',
    description: 'Show adult content',
    defaultMessage: 'Show adult content',
  },
  profileByChecking: {
    id: 'profile.byChecking',
    description: "By unchecking this box, adult (18+) offers won't be shown by default in your offer list.",
    defaultMessage: "By unchecking this box, adult (18+) offers won't be shown by default in your offer list.",
  },
  profileWarningBillingChange: {
    id: 'profile.warningBillingChange',
    description:
      "In order to confirm the newly updated payment method, please click on the link we've sent to the email registered with your CrakRevenue account.",
    defaultMessage:
      "In order to confirm the newly updated payment method, please click on the link we've sent to the email registered with your CrakRevenue account.",
  },
  profileConfirmBillingChange: {
    id: 'profile.confirmBillingChange',
    description: 'Your payment method was successfully updated',
    defaultMessage: 'Your payment method was successfully updated',
  },
  profileBestTimeToCallLabel: {
    id: 'profile.bestTimeToCall',
    description: 'Best time to call you',
    defaultMessage: 'Best time to call you',
  },
  profileTimeZoneLabel: {
    id: 'profile.timeZone',
    description: 'Time Zone',
    defaultMessage: 'Time Zone',
  },
  profileSalutationsLabel: {
    id: 'profile.salutations',
    description: 'Title',
    defaultMessage: 'Title',
  },
  profileMainPhoneLabel: {
    id: 'profile.mainPhone',
    description: 'Mobile phone',
    defaultMessage: 'Mobile phone',
  },
  profileIdentityPerson: {
    id: 'Profile.identity.person',
    description: 'Profile - Identity - Person',
    defaultMessage: 'Individual Account',
  },
  profileIdentityCompany: {
    id: 'Profile.identity.company',
    description: 'Profile - Identity - Company',
    defaultMessage: 'Company Account',
  },
  profilePersonalInformationText: {
    id: 'profile.personalInformationText',
    description: 'Personal Information Paragraph',
    defaultMessage:
      'Please provide the following information to complete your file. Complete information ensures easy communication and a solid partnership.',
  },
  profileContactInformationText: {
    id: 'profile.contactInformationText',
    description: 'Contact Information Paragraph',
    defaultMessage:
      'Please provide your contact information so we can reach you if needed. Let us know your time zone and the best time to contact you.',
  },
  profileIdentityInformationText: {
    id: 'profile.identityInformationText',
    description: 'Identity Information Paragraph',
    defaultMessage:
      'Please specify to whom payments should be sent and provide the required information for accounting and compliance purposes.',
  },
  profileIdentityInformationTitle: {
    id: 'profile.identityInformationTitle',
    description: 'Identity Information Title',
    defaultMessage: 'Account Type',
  },
  profileBeneficiaryInformationTitle: {
    id: 'profile.beneficiaryInformationTitle',
    description: 'Beneficiary Information Title',
    defaultMessage: 'Beneficiary Information',
  },
  profileBeneficiaryCompanyInformationText: {
    id: 'profile.beneficiaryCompanyInformationText',
    description: 'Beneficiary Company Information Text',
    defaultMessage: 'Please provide your company address to ensure your payments are accurately sent to the appropriate entity.',
  },
  profileBeneficiaryPersonInformationText: {
    id: 'profile.beneficiaryPersonInformationText',
    description: 'Beneficiary Person Information Text',
    defaultMessage: 'Please provide your address to ensure your payments are accurately sent to the appropriate recipient.',
  },
  profileBillingInformationTitle: {
    id: 'profile.billingPersonInformationTitle',
    description: 'Billing Person Information Title',
    defaultMessage: 'Billing Information',
  },
  profileBillingInformationText: {
    id: 'profile.billingPersonInformationText',
    description: 'Billing Information Text',
    defaultMessage:
      'Please select your payment method, set the minimum payout, and provide all necessary details for the chosen method to ensure payments are accurately sent to the correct institution or account.',
  },
  ...notifications,
  ...paymentForm,
  ...paymentHistory,
  ...referrals,
  ...userAndCompany,
};
