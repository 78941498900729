export const POP_CODE_TYPES = {
  POPUNDER: 'POPUNDER',
  POPUP: 'POPUP',
};

export const POP_CODE_AFF_SUB4 = {
  [POP_CODE_TYPES.POPUNDER]: 'AT_0005',
  [POP_CODE_TYPES.POPUP]: 'AT_0019',
};

export const POP_CODE_CONTENT = Object.freeze({
  [POP_CODE_TYPES.POPUNDER]: {
    title: 'Popunder',
    paragraph: `Our popunder ad opens behind the current browser window, making it less visible initially. This format minimizes disruption to the user experience, allowing the ad to be noticed only after the active window is closed or minimized.`,
    icon: (
      <svg viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Item-Icon">
          <g id="Group 4">
            <path
              id="Vector"
              d="M8.28906 7.21425V5.0908C8.28906 3.93634 9.21252 3 10.3511 3H20.938C22.0766 3 23 3.93634 23 5.0908V15.0818C23 16.2363 22.0766 17.1726 20.938 17.1726H17.711V9.20563C17.711 8.11646 16.8417 7.23206 15.766 7.23058L8.28906 7.21574V7.21425Z"
              fill="currentColor"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <g id="Group">
              <path
                id="Vector_2"
                d="M3 15.1175V19.3792C3 20.5337 3.92346 21.47 5.06205 21.47H15.6489C16.7875 21.47 17.711 20.5337 17.711 19.3792V9.38817C17.711 8.2337 16.7875 7.29736 15.6489 7.29736H5.06205C3.92346 7.29736 3 8.2337 3 9.38817V16.7423"
                stroke="currentColor"
                stroke-miterlimit="10"
              />
              <path
                id="Vector_3"
                d="M7.33343 9.61084H5.28748"
                stroke="currentColor"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </g>
          </g>
        </g>
      </svg>
    ),
  },
  [POP_CODE_TYPES.POPUP]: {
    title: 'Popup',
    paragraph: `Our popup ad appears on top of the current browser window, immediately capturing the user's attention. This format highlights the offer being promoted, encouraging direct engagement.`,
    icon: (
      <svg viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Item-Icon">
          <g id="Group 3">
            <path
              id="Vector"
              d="M17.6932 17H20.9309C22.0733 17 22.9999 16.0751 22.9999 14.9347V5.06533C22.9999 3.92493 22.0733 3 20.9309 3H10.3087C9.16629 3 8.23975 3.92493 8.23975 5.06533V7.3095"
              stroke="currentColor"
              stroke-miterlimit="10"
            />
            <g id="Group">
              <g id="Vector_2">
                <path
                  d="M3 15.0781V19.3662C3 20.5278 3.92705 21.4699 5.07007 21.4699H15.6981C16.8411 21.4699 17.7682 20.5278 17.7682 19.3662V9.31343C17.7682 8.15183 16.8411 7.20972 15.6981 7.20972H5.07007C3.92705 7.20972 3 8.15183 3 9.31343V16.713"
                  fill="currentColor"
                />
                <path
                  d="M3 15.0781V19.3662C3 20.5278 3.92705 21.4699 5.07007 21.4699H15.6981C16.8411 21.4699 17.7682 20.5278 17.7682 19.3662V9.31343C17.7682 8.15183 16.8411 7.20972 15.6981 7.20972H5.07007C3.92705 7.20972 3 8.15183 3 9.31343V16.713"
                  stroke="currentColor"
                  stroke-miterlimit="10"
                />
              </g>
              <path
                id="Vector_3"
                d="M12.7451 5.29712H10.6912"
                stroke="currentColor"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </g>
          </g>
        </g>
      </svg>
    ),
  },
});

export const POP_CODE_TABS = [];

for (const key in POP_CODE_CONTENT) {
  const value = POP_CODE_CONTENT[key];
  POP_CODE_TABS.push({
    key,
    ...value,
  });
}
