import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { reduxForm } from 'redux-form';
import { injectIntl, intlShape } from 'react-intl';
import { Gtm } from '../../utils/gtm';

import messages from '../../i18n/base-en.js';

import { LineChart, SelectMulti } from '../../components/reusables';

import { fetchGraphStatistics } from '../../actions/statistics-actions';
import ErrorBoundary from '../../components/reusables/ErrorBoundary.react';
import SelectCustom from '../../components/reusables/form/SelectCustom';

class StatisticsGraphContainer extends Component {
  componentDidMount() {
    const { dispatch, statisticsDateStart, statisticsDateEnd, graphGroups, values, searchFilters, selectedRowsValues } =
      this.props;

    const useCache = process.env.REACT_APP_USE_STATISTICS_CACHE === 'true';

    dispatch(
      fetchGraphStatistics(
        {
          data_start: statisticsDateStart,
          data_end: statisticsDateEnd,
          groups: graphGroups,
          filters: searchFilters,
          fields: _.flattenDeep([graphGroups, values.graphFields]),
          page: 1,
          limit: 0,
        },
        selectedRowsValues,
        useCache
      )
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch, statisticsDateStart, statisticsDateEnd, selectedRowsValues, values, filters, searchFilters, refresh } =
      this.props;
    let numberOfFields = 0;

    if (nextProps.values.graphFields) {
      numberOfFields = nextProps.values.graphFields.length;
      if (
        (numberOfFields === 1 || numberOfFields === 2) &&
        (statisticsDateStart !== nextProps.statisticsDateStart ||
          statisticsDateEnd !== nextProps.statisticsDateEnd ||
          !_.isEqual(values.graphFields, nextProps.values.graphFields) ||
          !_.isEqual(filters, nextProps.filters) ||
          !_.isEqual(searchFilters, nextProps.searchFilters) ||
          !_.isEqual(selectedRowsValues, nextProps.selectedRowsValues) ||
          (nextProps.refresh && !_.isEqual(refresh, nextProps.refresh)))
      ) {
        const nextFields = nextProps.values.graphFields.length >= 1 ? nextProps.values.graphFields : ['Stat.payout'];

        const useCache = process.env.REACT_APP_USE_STATISTICS_CACHE === 'true';
        dispatch(
          fetchGraphStatistics(
            {
              data_end: nextProps.statisticsDateEnd,
              data_start: nextProps.statisticsDateStart,
              fields: _.flattenDeep([nextProps.graphGroups, nextFields]),
              filters: nextProps.searchFilters,
              groups: nextProps.graphGroups,
              limit: 0,
              page: 1,
            },
            nextProps.selectedRowsValues,
            useCache
          )
        );
      }
    }
    if (numberOfFields !== 1 && numberOfFields !== 2) {
      dispatch({
        type: 'redux-form/CHANGE',
        form: 'statisticsGraphFields',
        field: 'graphFields',
        value: ['Stat.payout'],
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { filters, refresh, statisticsGraphData } = this.props;

    return (
      !_.isEqual(filters, nextProps.filters) ||
      !_.isEqual(refresh, nextProps.refresh) ||
      !_.isEqual(statisticsGraphData, nextProps.statisticsGraphData)
    );
  }

  handleGtmEventGraph(statName) {
    Gtm.event('statistics', 'Click', `graph - ${statName.map((stat) => stat.split('Stat.')[1])}`);
  }

  render() {
    const { fields, statisticsGraphData, values, intl } = this.props;

    const graphStatsToShowList = [
      { value: 'Stat.payout', text: intl.formatMessage(messages.statisticsColumns['Stat.payout']) },
      { value: 'Stat.clicks', text: intl.formatMessage(messages.statisticsColumns['Stat.clicks']) },
      { value: 'Stat.impressions', text: intl.formatMessage(messages.statisticsColumns['Stat.impressions']) },
      { value: 'Stat.conversions', text: intl.formatMessage(messages.statisticsColumns['Stat.conversions']) },
    ];

    return (
      <div id="statistics-graph">
        <div className="row">
          <div className="col-md-12 graph-container">
            <div className="row">
              <div className="col-md-5">
                <div className="filterInput" style={{ marginTop: '10px' }}>
                  <div className="customSelects">
                    <div className="fields">
                      <SelectCustom
                        gtmEvent={() => this.handleGtmEventGraph(fields.graphFields.value)}
                        id="graphField"
                        isMulti
                        label="Show:"
                        maxSelected={2}
                        onChange={fields.graphFields.onChange}
                        options={graphStatsToShowList.map((graphStat) => ({ value: graphStat.value, text: graphStat.text }))}
                        showMaxSelectedNumber={false}
                        showSelectedFieldCheckmark={false}
                        value={fields.graphFields.value}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <ErrorBoundary errorText={intl.formatMessage(messages.genericTextErrorStats)}>
                <LineChart
                  className="col-md-12"
                  graphFields={values.graphFields || fields.graphFields.value}
                  statisticsGraphData={statisticsGraphData}
                  withLegend
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StatisticsGraphContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  graphGroups: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  refresh: PropTypes.bool.isRequired,
  searchFilters: PropTypes.object.isRequired,
  selectedRowsValues: PropTypes.object.isRequired,
  statisticsDateEnd: PropTypes.any,
  statisticsDateStart: PropTypes.any,
  statisticsGraphData: PropTypes.object,
  values: PropTypes.object.isRequired,
};

export default injectIntl(
  reduxForm(
    {
      form: 'statisticsGraphFields',
      destroyOnUnmount: false,
      fields: ['graphFields'],
    },
    () => ({
      initialValues: {
        graphFields: ['Stat.payout'],
      },
    })
  )(StatisticsGraphContainer)
);
