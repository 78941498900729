import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { change, getValues } from 'redux-form';
import { Container, NewsBox, Pagination, SwiperSlider } from '../components/reusables';
import OfferListingTable from '../components/OfferListingTable/OfferListingTable.react';

import {
  changeOffersListPage,
  changeOffersRowsPerPage,
  changeOffersLocationQuery,
  fetchOffers,
  setOffersSearchFilters,
} from '../components/Offers/actions/offers-actions';

import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';

import { Gtm } from '../utils/gtm';
import OffersSearch from '../components/Offers/OffersSearch.js';
import { OFFER_SEARCH_FORM_NAME, offerDropdownFields } from '../components/reusables/offersSearch/values.js';
import OffersSliderLarge from '../components/Offers/slider/OffersSliderLarge.react.js';
import { useCallback } from 'react';
import _ from 'lodash';

const OffersPage = ({
  allOffers,
  categoriesList,
  countries,
  dispatch,
  listChannels,
  listConversionTypes,
  listMediaPlacements,
  listVerticals,
  listCountries,
  location: { query },
  news,
  offersInfos,
  offersIsFetching,
  offersSearch,
  search,
  skinConfigurations,
  offersSlides,
  offersSlidesLoading,
  targeted_countries,
}) => {
  const timeoutRef = useRef();
  const firstLoad = useRef(true);
  const [viewMode, setViewMode] = useState('table');
  const [windowWidthSize, setWindowWidthSize] = useState(undefined);
  const [offersSlidesByVertical, setOffersSlidesByVertical] = useState(offersSlides);

  const [slides, setSlides] = useState({
    level1: [],
    level2: [],
  });

  const { level1, level2 } = slides;

  const offerByVerticalLookup = allOffers.reduce((acc, offer) => {
    offer.verticals.forEach((vertical) => {
      if (!acc[vertical.id]) {
        acc[vertical.id] = [];
      }
      acc[vertical.id].push(offer.id);
    });
    return acc;
  }, {});

  function getAvailableVerticals() {
    return listVerticals.filter((vertical) => offerByVerticalLookup[vertical.id]);
  }

  const othersSearch = ['approval_status'];
  const statusMapping = {
    approved: 'Approved',
    null: 'Required',
    pending: 'Pending',
    rejected: 'Rejected',
  };

  useEffect(() => {
    if (!offersIsFetching && !_.isEmpty(query) && !query.vertical) {
      timeoutRef.current = setTimeout(() => {
        const offersList = document.getElementById('offers-list');
        if (!offersList) {
          return;
        }
        offersList.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }, 100);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [offersIsFetching]);

  useEffect(() => {
    dispatch(setOffersSearchFilters(search));
    SkinConfigurationsUtils.manageAccess(browserHistory, skinConfigurations, 'offers');
    function handleResize() {
      setWindowWidthSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!firstLoad.current) {
      dispatch(fetchOffers(search));
    }
  }, [search]);

  const getOffersItemsVisibility = (skin) => {
    const SKIN_COMPONENT_NAME = 'Offers';
    return {
      itemVideoIcon: skin.isItemVisible(SKIN_COMPONENT_NAME, 'title.VideoModalIcon'),
      newsBox: skin.isItemVisible('Global', 'NewsBox'),
      offersSearch: skin.isItemVisible(SKIN_COMPONENT_NAME, 'OffersSearch'),
      offersList: skin.isItemVisible(SKIN_COMPONENT_NAME, 'offers-list'),
      recentOffers: skin.isItemVisible(SKIN_COMPONENT_NAME, 'recent-offers.FeaturedOffersList'),
      swiperSlider: skin.isItemVisible(SKIN_COMPONENT_NAME, 'SwiperSlider'),
      title: skin.isItemVisible(SKIN_COMPONENT_NAME, 'title'),
      topOffers: skin.isItemVisible(SKIN_COMPONENT_NAME, 'top-offers.FeaturedOffersList'),
      epcIcon: skin.isItemVisible(SKIN_COMPONENT_NAME, 'epcIcon'),
    };
  };

  const buildFilters = ({ updateFilters, offersSearch, search }) => {
    const categories = {};
    const filters = {};
    const others = {};
    const offersSearchValues = getValues(offersSearch) || {};

    for (const key in updateFilters) {
      const value = updateFilters[key];
      offersSearchValues[key] = value;
    }

    let featured = false;
    let searchString = '';

    Object.keys(offersSearchValues).map((key) => {
      if (key === 'searchString') {
        searchString = offersSearchValues[key];
      } else if (key === 'featured') {
        featured = offersSearchValues[key];
      } else if (othersSearch.indexOf(key) >= 0) {
        others[key] = offersSearchValues[key];
      } else if (Object.keys(search.categories).indexOf(key) <= -1) {
        filters[key] = offersSearchValues[key];
      } else {
        categories[key] = offersSearchValues[key];
      }
    });

    return {
      categories,
      featured,
      filters,
      others,
      searchString,
    };
  };

  const handlePageChange = (page) => {
    dispatch(changeOffersListPage(page));
  };

  const handleSearchSubmit = (props) => {
    const { updateFilters } = props || {};

    const newFilters = buildFilters({ updateFilters, offersSearch, search });

    dispatch(changeOffersListPage(1));
    dispatch(setOffersSearchFilters({ ...search, ...newFilters, page: 1, limit: 50 }));
  };

  const handleRowsChange = (value, page) => {
    dispatch(changeOffersRowsPerPage(value, page));
  };

  const SKIN = new SkinConfigurationsUtils(skinConfigurations);
  const IS_VISIBLE = getOffersItemsVisibility(SKIN);

  const searchLimit = parseInt(search.limit, 10);
  const getPaginationComponent = (selectId, isMobileHidden) => (
    <Pagination
      choices={[25, 50, 100, 200]}
      className="pull-right"
      currentPage={offersInfos.currentPage}
      handlePageChange={handlePageChange}
      handleRowsChange={handleRowsChange}
      pageCount={offersInfos.pageCount}
      rowsPerPage={searchLimit}
      selectId={selectId}
      totalResults={offersInfos.total}
    />
  );

  if (window && window.onresize) {
    window.onresize(true);
  }

  if (windowWidthSize <= 1240 && viewMode !== 'cards') {
    setViewMode('cards');
  }

  useEffect(() => {
    const queryVerticals = query.vertical ? (query.vertical.includes(',') ? query.vertical.split(',') : [query.vertical]) : [];
    const queryIds = query.id ? (query.id.includes(',') ? query.id.split(',') : [query.id]) : [];
    if (query?.vertical?.length > 0) {
      dispatch(changeOffersLocationQuery(query));
      search = {
        ...search,
        filters: {
          ...(search.filters = {
            ...search.filters,
            Vertical: queryVerticals,
          }),
        },
        page: 1,
        limit: 50,
      };
      dispatch(change(OFFER_SEARCH_FORM_NAME, offerDropdownFields.vertical, queryVerticals));
    }

    if (query?.id?.length > 0) {
      dispatch(changeOffersLocationQuery(query));
      search = {
        ...search,
        filters: {
          ...(search.filters = {
            ...search.filters,
            Id: queryIds,
          }),
        },
        page: 1,
        limit: 50,
        searchString: search.searchString,
      };
      dispatch(change(OFFER_SEARCH_FORM_NAME, offerDropdownFields.id, queryIds));
    }

    if (query.search) {
      dispatch(changeOffersLocationQuery(query));
      search = {
        ...search,
        filters: {
          ...(search.filters = {
            ...search.filters,
          }),
        },
        page: 1,
        limit: 50,
        searchString: query.search,
      };
      dispatch(change(OFFER_SEARCH_FORM_NAME, offerDropdownFields.id, queryIds));
    }

    dispatch(fetchOffers(search));

    firstLoad.current = false;
  }, [query.vertical, query.id]);

  useEffect(() => {
    let slidesLevel1 = [];
    const slidesLevel1AllLabel = [];

    let slidesLevel2 = [];
    const slidesLevel2AlternateVertical = [];

    const verticals = [...search.filters.Vertical];

    offersSlides.forEach((slide) => {
      const { level_one, vertical } = slide;

      const verticalValue = vertical.value;
      const isAllLabeledVertical = vertical.label?.toLowerCase() === 'all';

      if (level_one && isAllLabeledVertical) {
        slidesLevel1AllLabel.push(slide);
      }

      const searchedVertical = verticals.some((vertical) => vertical === verticalValue);

      if (searchedVertical) {
        if (level_one) {
          slidesLevel1.push(slide);
        } else {
          slidesLevel2.push(slide);
        }
      } else if (!level_one) {
        slidesLevel2AlternateVertical.push(slide);
      }
    });

    if (!slidesLevel1?.length) {
      slidesLevel1 = slidesLevel1AllLabel;
    }

    if (slidesLevel2?.length < 6) {
      slidesLevel2 = [...slidesLevel2, ...slidesLevel2AlternateVertical];
    }

    setSlides({
      level1: slidesLevel1,
      level2: slidesLevel2,
    });
  }, [search.filters.Vertical, offersSlides, query, setSlides]);

  const verticalsAsString = Array.isArray(search.filters.Vertical.length)
    ? search.filters.Vertical.join(',')
    : search.filters.Vertical;

  const sliderGtmEvent = useCallback(
    ({ positionIndex, level, imgSrc, arrowDirection, eventParamsName = 'click' }) => {
      const position = positionIndex + 1;

      let vertical = listVerticals?.find((vertical) => parseInt(vertical.id) === parseInt(verticalsAsString))?.name || 'all';
      vertical = vertical.toLowerCase();

      const eventParameterValue = arrowDirection || `${vertical}_${position}_${imgSrc}`;

      const eventNamePrefix = arrowDirection ? 'offers_carrousel_arrows' : 'offers_carrousel';
      const eventName = `${eventNamePrefix}_${level}`;

      Gtm.newEvent(eventName, false, eventParamsName, 'string', eventParameterValue);
    },
    [listVerticals, verticalsAsString]
  );

  const handleVisibleSlide = (index, isVisible) => {
    if (isVisible && level2[index]) {
      sliderGtmEvent({
        level: 2,
        positionIndex: index,
        imgSrc: level2[index].image_url,
        eventParamsName: 'impression',
      });
    }
  };

  if (offersSlidesLoading) {
    return null;
  }

  const ComponentOfferSearch = (
    <OffersSearch
      approvalStatusMapping={statusMapping}
      categoriesList={categoriesList}
      countries={countries}
      dispatch={dispatch}
      handleSearchSubmit={handleSearchSubmit}
      isVisibleVideoModalIcon={IS_VISIBLE.itemVideoIcon}
      listChannels={listChannels}
      listConversionTypes={listConversionTypes}
      listCountries={listCountries}
      listMediaPlacements={listMediaPlacements}
      listOptimizedForCountries={targeted_countries}
      listVerticals={getAvailableVerticals()}
      offersIsFetching={offersIsFetching}
      offersSearch={offersSearch}
      query={query}
      search={search}
    />
  );

  return (
    <div className="container-fluid app-container" id="offers">
      {news.active && IS_VISIBLE.newsBox ? (
        <div className="row">
          <Container className="col-xs-12 col-md-12 col-lg-12 shorter-xlg-col-12 z-depth-2 no-margin-top" id="newsbox">
            <NewsBox content={news} />
          </Container>
        </div>
      ) : null}

      {IS_VISIBLE.swiperSlider ? (
        <>
          {level1.length > 0 && (
            <OffersSliderLarge
              dispatch={dispatch}
              level1={level1}
              query={query}
              sliderGtmEvent={sliderGtmEvent}
              verticalsAsString={verticalsAsString}
            />
          )}
          {level2.length > 0 && (
            <div className="row offers-slider">
              <div className="offer-slider-title subtitle">
                <h2 className="primary-color">Recommended Offers</h2>
                <div className="offer-slider-arrows">
                  <div
                    className="prev swiper-prev"
                    onClick={() => {
                      sliderGtmEvent({ level: 2, arrowDirection: 'left' });
                    }}
                  >
                    <img src="/img/offers/arrow-left.svg" />
                  </div>
                  <div
                    className="next swiper-next"
                    onClick={() => {
                      sliderGtmEvent({ level: 2, arrowDirection: 'right' });
                    }}
                  >
                    <img src="/img/offers/arrow-right.svg" />
                  </div>
                </div>
              </div>
              <SwiperSlider onSlideVisible={handleVisibleSlide} verticalsAsString={verticalsAsString}>
                {typeof level2 !== 'undefined'
                  ? level2?.map((slide, index) => (
                      <div className="slide" key={`slide-${index}`}>
                        <div className="no-click" />
                        {slide.cta_link && !slide.cta_text ? (
                          <Link
                            className="btn wrap-image"
                            onClick={() => {
                              sliderGtmEvent({ level: 2, positionIndex: index, imgSrc: slide.image_url });
                            }}
                            onlyActiveOnIndex={false}
                            to={() => {
                              const url = new URL(slide.cta_link);
                              return url.search ? url.pathname + url.search : url.pathname;
                            }}
                          >
                            <img alt={slide.cta_text} src={slide.image_url} />
                          </Link>
                        ) : null}
                        {(slide.cta_link && slide.cta_text) || (!slide.cta_link && !slide.cta_text) ? (
                          <img alt={slide.cta_text} className="has-placeholder" src={slide.image_url} />
                        ) : null}
                        {slide.cta_text && slide.cta_link ? (
                          <Link
                            className="btn"
                            onClick={() => {
                              Gtm.event('offers slide', 'Click', `offer carrousel - ${slide.image_url}`);
                            }}
                            onlyActiveOnIndex={false}
                            to={() => {
                              const url = new URL(slide.cta_link);
                              return url.search ? url.pathname + url.search : url.pathname;
                            }}
                          >
                            {slide.cta_text}
                          </Link>
                        ) : null}
                      </div>
                    ))
                  : offersSlidesByVertical?.map((slide, index) => (
                      <div className="placeholder" key={`slide-${index}`}>
                        <div className="slide-placeholder" />
                      </div>
                    ))}
              </SwiperSlider>
            </div>
          )}
        </>
      ) : null}

      <OfferListingTable componentOfferSearch={ComponentOfferSearch} getPaginationComponent={getPaginationComponent} />
    </div>
  );
};

OffersPage.propTypes = {
  categoriesList: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  listChannels: PropTypes.array,
  listConversionTypes: PropTypes.array,
  listCountries: PropTypes.array,
  listMediaPlacements: PropTypes.array,
  listVerticals: PropTypes.array,
  location: PropTypes.object,
  news: PropTypes.object.isRequired,
  offersInfos: PropTypes.object.isRequired,
  offersIsFetching: PropTypes.bool.isRequired,
  offersSearch: PropTypes.object,
  offersSlides: PropTypes.array,
  offersViewMode: PropTypes.string.isRequired,
  profileSettings: PropTypes.object,
  search: PropTypes.object.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  allOffers: state.application.lists.allOffers,
  categoriesList: state.application.lists.offersCategories,
  countries: state.application.lists.countries,
  listChannels: state.application.lists.searchFields.Channels,
  listConversionTypes: state.application.lists.searchFields.ConversionTypes,
  listMediaPlacements: state.application.lists.searchFields.MediaPlacements,
  listVerticals: state.application.lists.searchFields.Verticals,
  listCountries: state.application.lists.countries,
  targeted_countries: state.application.lists.countries,
  news: state.news.data,
  offersInfos: state.offers.offersInfos,
  offersIsFetching: state.offers.offersIsFetching,
  offersLocationQuery: state.offers.offersLocationQuery,
  offersSearch: state.form.offersSearch,
  offersSlides: state.offersSlides.data,
  offersSlidesLoading: state.offersSlides.isFetching,
  offersViewMode: state.application.ui.offersViewMode,
  profileSettings: state.profile.data.settings,
  search: state.offers.search,
  skinConfigurations: state.skinConfigurations.data,
  isConnected: state.login.isConnected,
}))(OffersPage);
