import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import messages from '../../../i18n/base-en.js';

import BankInfos from './wire-section/BankInfos.react';
import BeneficiaryInfos from './wire-section/BeneficiaryInfos.react';
import IntermediaryBankInfos from './wire-section/IntermediaryBankInfos.react';

import { Checkbox } from '../../../components/reusables';
import ExcludedCountriesHelper from '../../../utils/ExcludedCountriesHelper.js';

function WireSection({ fields, values, willRender, countries, accountType, intl }) {
  const [savedIntermediaryBankCountry, setSavedIntermediaryBankCountry] = useState(values.wireIntermediaryBankCountry || '');
  const [savedIntermediaryBankName, setSavedIntermediaryBankName] = useState(values.wireIntermediaryBankName || '');
  const [savedIntermediaryBankAddress, setSavedIntermediaryBankAddress] = useState(values.wireIntermediaryBankAddress || '');
  const [savedIntermediaryBankCity, setSavedIntermediaryBankCity] = useState(values.wireIntermediaryBankCity || '');
  const [savedIntermediaryBankState, setSavedIntermediaryBankState] = useState(values.wireIntermediaryBankState || '');
  const [savedIntermediaryBankZipcode, setSavedIntermediaryBankZipcode] = useState(values.wireIntermediaryBankZipcode || '');
  const [savedIntermediaryBankBicSwift, setSavedIntermediaryBankBicSwift] = useState(values.wireIntermediaryBicSwift || '');
  const [savedIntermediaryABArouting, setSavedIntermediaryABArouting] = useState(values.wireIntermediaryABArouting || '');
  const [savedOtherInformation, setSavedOtherInformation] = useState(values.otherInformation || '');

  useEffect(() => {}, [willRender]);

  let wireSection = false;

  const filteredCountries = ExcludedCountriesHelper.getFilteredCountries(
    countries,
    ExcludedCountriesHelper.getBillingWireExcludedCountries()
  );

  if (willRender) {
    wireSection = (
      <div className="wire_section">
        {accountType === 'Legacy' && <BeneficiaryInfos countries={filteredCountries} fields={fields} values={values} />}
        <BankInfos countries={filteredCountries} fields={fields} values={values} />

        <div className="row">
          <div className="col-md-12">
            <h2>
              <FormattedMessage {...messages.paymentInfosIntermediaryBankInfosTitle} />
            </h2>
          </div>
        </div>
        <div className="row">
          <Checkbox
            className="col-md-12"
            field={fields.wireIntermediaryBankStatus}
            customOnChange={(e) => {
              fields.wireIntermediaryBankStatus.onChange(!fields.wireIntermediaryBankStatus.checked);
              if (!fields.wireIntermediaryBankStatus.checked) {
                fields.wireIntermediaryBankCountry.onChange(savedIntermediaryBankCountry);
                fields.wireIntermediaryBankName.onChange(savedIntermediaryBankName);
                fields.wireIntermediaryBankAddress.onChange(savedIntermediaryBankAddress);
                fields.wireIntermediaryBankCity.onChange(savedIntermediaryBankCity);
                fields.wireIntermediaryBankState.onChange(savedIntermediaryBankState);
                fields.wireIntermediaryBankZipcode.onChange(savedIntermediaryBankZipcode);
                fields.wireIntermediaryBicSwift.onChange(savedIntermediaryBankBicSwift);
                fields.wireIntermediaryABArouting.onChange(savedIntermediaryABArouting);
                fields.otherInformation.onChange(savedOtherInformation);
              } else {
                setSavedIntermediaryBankCountry(values.wireIntermediaryBankCountry);
                setSavedIntermediaryBankName(values.wireIntermediaryBankName);
                setSavedIntermediaryBankAddress(values.wireIntermediaryBankAddress);
                setSavedIntermediaryBankCity(values.wireIntermediaryBankCity);
                setSavedIntermediaryBankState(values.wireIntermediaryBankState);
                setSavedIntermediaryBankZipcode(values.wireIntermediaryBankZipcode);
                setSavedIntermediaryBankBicSwift(values.wireIntermediaryBicSwift);
                setSavedIntermediaryABArouting(values.wireIntermediaryABArouting);
                setSavedOtherInformation(values.otherInformation);
                fields.wireIntermediaryBicSwift.onChange('');
                fields.wireIntermediaryBankCountry.onChange('');
                fields.wireIntermediaryBankName.onChange('');
                fields.wireIntermediaryBankAddress.onChange('');
                fields.wireIntermediaryBankCity.onChange('');
                fields.wireIntermediaryBankState.onChange('');
                fields.wireIntermediaryBankZipcode.onChange('');
                fields.wireIntermediaryABArouting.onChange('');
                fields.otherInformation.onChange('');
              }
            }}
            id="wireIntermediaryBankStatus"
            label={messages.paymentInfosIntermediaryBankStatusLabel.defaultMessage}
            tabIndex={190}
          />
        </div>

        <IntermediaryBankInfos
          countries={filteredCountries}
          fields={fields}
          values={values}
          willRender={values.wireIntermediaryBankStatus === true}
        />
      </div>
    );
  }

  return wireSection;
}

WireSection.propTypes = {
  countries: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  willRender: PropTypes.bool.isRequired,
};

export default WireSection;
