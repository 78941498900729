import consts from '../../utils/consts';
import RankingIcon from '../../components/reusables/svg/ranking/UpRankingIcon.react';
import React from 'react';

const ProfileRankingPositionBox = ({ affRank }) =>
  affRank.current_aff_rank > 0 || affRank.hidden_rank ? (
    <div className="ranking-position">
      <span className="ranking-text">Position</span>
      <div className="aff-ranking-box">
        <span className={`ranking-number ${affRank.hidden_rank ? 'top-20-size' : ''}`}>
          {affRank.hidden_rank
            ? `TOP ${consts.DESCENDING_RANKS.HIDDEN_RANK.rank}`
            : affRank.current_aff_rank > 0
            ? affRank.current_aff_rank
            : null}
        </span>
        <span className="rank-indicator">
          {affRank.current_aff_rank > affRank.current_aff_yesterday_rank && (
            <span className="inverse-arrow">
              <RankingIcon />
            </span>
          )}
          {affRank.current_aff_rank < affRank.current_aff_yesterday_rank && <RankingIcon />}
          {affRank.current_aff_rank === affRank.current_aff_yesterday_rank && <i className="material-icons equal">remove</i>}
        </span>
      </div>
    </div>
  ) : null;

export default ProfileRankingPositionBox;
