export const LockedSectionIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 43" fill="none">
    <path
      d="M1.20825 13.5562C1.42492 9.18117 2.07284 6.452 4.0145 4.5145C5.952 2.57284 8.68117 1.92492 13.0562 1.70825M40.7916 13.5562C40.5749 9.18117 39.927 6.452 37.9853 4.5145C36.0478 2.57284 33.3187 1.92492 28.9437 1.70825M28.9437 41.2916C33.3187 41.0749 36.0478 40.427 37.9853 38.4853C39.927 36.5478 40.5749 33.8187 40.7916 29.4437M13.0562 41.2916C8.68117 41.0749 5.952 40.427 4.0145 38.4853C2.07284 36.5478 1.42492 33.8187 1.20825 29.4437M15.7916 18.4895C15.7916 16.7353 15.5166 14.0291 16.5749 12.4499C18.9145 8.9645 23.8749 9.427 25.6249 12.8291C26.4833 14.4999 26.1603 16.8353 26.1999 18.4895M15.7916 18.4895C13.0895 18.4895 12.5374 20.0353 12.1249 21.2499C11.7437 22.6145 11.3541 25.8749 11.9499 29.4478C12.3958 31.7228 14.1353 32.7228 15.6312 32.8499C17.0624 32.9708 23.1041 32.9249 24.852 32.9249C27.5624 32.9249 29.2541 32.3291 30.0499 29.5978C30.4312 27.4749 30.5353 23.677 29.8999 21.2499C29.0582 18.8228 27.3583 18.4895 26.1999 18.4895M15.7916 18.4895C18.6541 18.3749 24.5624 18.3978 26.1999 18.4895"
      stroke="#00BD00"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
