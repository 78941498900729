export const getInvoices = (paymentsList) => {
  const invoicesCarriedOverList = [];
  const invoicesPendingArray = [];
  let pendingFound = false;
  for (const invoice of paymentsList) {
    if (invoice.status === 'Pending') {
      pendingFound = true;
      invoicesPendingArray.push(invoice);
    } else if (invoice.status === 'Carried Over') {
      if (pendingFound) {
        invoicesPendingArray.push(invoice);
      } else {
        invoicesCarriedOverList.push(invoice);
      }
    }
  }
  return {
    invoicesCarriedOverList,
    invoicesPendingArray,
  };
};

export const getCarriedOverEarnings = (carriedOverList) => {
  if (!carriedOverList || carriedOverList.length === 0) {
    return 0;
  }
  return carriedOverList.reduce((acc, payment) => {
    return acc + parseFloat(payment.amount);
  }, 0);
};
