import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { default as validate, asyncValidate } from '../validateConnectionInfos';

import { InputText } from '../../../components/reusables';

import messages from '../../../i18n/base-en.js';
import Gear from './svg/Gear.react';
import { ProfileSectionContainer } from '../../../components/reusables/ProfileSectionContainer.react.js';

const ConnectionInfos = ({ isOpen, setIsOpen, fields, handleSubmit, invalid, isLoading }) => (
  <ProfileSectionContainer
    iconComponent={<Gear />}
    invalid={null}
    isLoading={isLoading}
    isOpen={isOpen}
    sectionSubtitle="View your email, affiliate ID, and update your password in this section."
    sectionTitleAsFormattedMessage={messages.profileMenuAccount}
    setIsOpen={setIsOpen}
    wrapperClass="connection-infos"
  >
    <form action="#" className={isOpen ? 'open' : ''} method="post" onSubmit={handleSubmit}>
      <InputText
        autofocus
        className=""
        disabled
        field={fields.email}
        id="email"
        label={messages.genericTextEmail}
        tabIndex={10}
        type="email"
      />
      <InputText
        autofocus
        className=""
        disabled
        field={fields.affiliateId}
        id="affiliateId"
        label={messages.genericAffiliateId}
        tabIndex={10}
        type="text"
      />
      <InputText
        autofocus
        canShowPassword={false}
        className=""
        field={fields.password}
        id="password"
        label={messages.genericTextCurrentPassword}
        tabIndex={20}
        type="password"
      />
      <InputText
        autofocus
        canShowPassword
        className=""
        field={fields.newPassword}
        id="newPassword"
        label={messages.userAndCompanyNewPasswordLabel}
        tabIndex={30}
        type="password"
      />
      <InputText
        autofocus
        canShowPassword
        className=""
        field={fields.newPasswordConfirmation}
        id="newPasswordConfirmation"
        label={messages.userAndCompanyNewPasswordConfirmationLabel}
        tabIndex={40}
        type="password"
      />

      <div className="">
        <div className="form-buttons text-right">
          <button className="waves-effect waves-light btn bg-primary-color" disabled={invalid} id="btn-save-connection-infos">
            <FormattedMessage {...messages.genericTextSaveChanges} />
          </button>
        </div>
      </div>
    </form>
  </ProfileSectionContainer>
);

ConnectionInfos.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.any,
};

export default reduxForm(
  {
    form: 'profileUserInfos',
    touchOnChange: false,
    touchOnBlur: true,
    fields: ['email', 'affiliateId', 'password', 'newPassword', 'newPasswordConfirmation'],
    validate,
    asyncValidate,
    asyncBlurFields: ['password'],
  },
  (state) => ({
    initialValues: {
      email: state.profile.data.affUserInfos.email,
      affiliateId: state.profile.data.affInfos.id,
    },
  })
)(ConnectionInfos);
