import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { openModal } from '../../../Modal/modal-actions/modal-actions';

import messages from '../../../../i18n/base-en.js';
import { InputText } from '../../../reusables';

import consts from '../../../../utils/consts';

import 'materialize-css';
import SelectCustom from '../../../reusables/form/SelectCustom';

const { WEBSITE_WITH_PROTOCOL_AND_BRAKETS } = consts.REGEXES;

const validate = (values) => {
  const errors = {};
  if (!values.link || values.link === '') {
    errors.link = 'You must provide a URL';
  } else if (!values.link.match(WEBSITE_WITH_PROTOCOL_AND_BRAKETS)) {
    errors.link = 'You must provide a valid and complete URL (including the protocol)';
  }
  return errors;
};

const OfferAddPostback = (props) => {
  const { fields, goals, handleAddVariables, handleCreatePostback, intl, values, dispatch } = props;

  const disabled = !validate(values).link ? '' : 'disabled';
  const openPostBackModal = () => {
    dispatch(
      openModal({
        name: 'OfferPostbacksAddVariablesModal',
        modalProps: {
          onSubmit: handleAddVariables,
        },
      })
    );
  };

  return (
    <form className="customSelects">
      <div className="fields" id="add-postback">
        <div className="postback-goal">
          <SelectCustom
            id="postback-goal"
            label={messages.genericTextGoal.description}
            onChange={fields.goal.onChange}
            options={goals.map((goal) => ({ value: goal.id, text: `${goal.id} - ${goal.name}` }))}
            showSelectedFieldCheckmark={false}
            touched={fields.goal.touched}
            value={fields.goal.value}
            valueDisabled={intl.formatMessage(messages.offerChooseYourGoal)}
          />
        </div>
        <div className="postback-link">
          <InputText field={fields.link} id="postback-link" label={messages.offerTypeYourPostbackURLHere} type="text" />
        </div>
        <div className="actions-section">
          <button
            className="filter-btn waves-effect waves-light btn"
            disabled={disabled}
            id="btn-add-postback-variables"
            onClick={() => openPostBackModal()}
            type="button"
          >
            <FormattedMessage {...messages.offerAddVariables} />
          </button>
          <button
            className="filter-btn waves-effect waves-light btn"
            disabled={disabled}
            id="btn-create-postback"
            onClick={(e) => {
              e.preventDefault();
              handleCreatePostback(e);
            }}
          >
            <FormattedMessage {...messages.offerCreatePostback} />
          </button>
        </div>
      </div>
    </form>
  );
};

OfferAddPostback.propTypes = {
  fields: PropTypes.object.isRequired,
  goals: PropTypes.array.isRequired,
  handleAddVariables: PropTypes.func.isRequired,
  handleCreatePostback: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  values: PropTypes.object.isRequired,
};

export default injectIntl(
  reduxForm({
    form: 'addPostback',
    fields: ['goal', 'link'],
    validate,
    initialValues: {
      goal: '0',
      link: '',
    },
  })(OfferAddPostback)
);
