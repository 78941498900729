export const PadlockIcon = ({ windowWidthSize }) =>
  windowWidthSize >= 992 ? (
    <svg fill="none" height="151" width="140" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 73">
        <g filter="url(#filter0_f_2197_33452)" id="Blur" opacity="0.55">
          <path
            d="M63.0953 64.6592C63.0953 62.7882 63.8386 60.9938 65.1616 59.6708C66.4846 58.3478 68.279 57.6045 70.1501 57.6045C72.0211 57.6045 73.8155 58.3478 75.1385 59.6708C76.4615 60.9938 77.2048 62.7882 77.2048 64.6592V77.4613H80.0267V64.6592C80.0267 62.0398 78.9861 59.5276 77.1339 57.6754C75.2817 55.8232 72.7695 54.7826 70.1501 54.7826C67.5306 54.7826 65.0185 55.8232 63.1662 57.6754C61.314 59.5276 60.2734 62.0398 60.2734 64.6592V77.4613H63.0953V64.6592Z"
            fill="url(#paint0_radial_2197_33452)"
            id="Vector"
          />
          <path
            clipRule="evenodd"
            d="M57.0674 68.9742C55.5831 68.9742 54.3799 70.09 54.3799 71.4663V93.8949C54.3799 95.2712 55.5831 96.3869 57.0674 96.3869H82.8295C84.3138 96.3869 85.517 95.2712 85.517 93.8949V71.4663C85.517 70.09 84.3138 68.9742 82.8295 68.9742H57.0674ZM67.2609 80.3126C67.2609 78.867 68.4643 77.6965 69.9485 77.6965C71.4326 77.6965 72.636 78.867 72.636 80.3126C72.636 81.2131 72.1688 82.0065 71.4579 82.4786C71.3004 82.5832 71.2033 82.7585 71.2302 82.9434L71.6672 85.9198C71.7056 86.1826 71.4963 86.4187 71.2236 86.4187H68.6733C68.4006 86.4187 68.1913 86.1826 68.2297 85.9198L68.6667 82.9434C68.6936 82.7585 68.5965 82.5832 68.439 82.4786C67.7281 82.0065 67.2609 81.2131 67.2609 80.3126Z"
            fill="url(#paint1_radial_2197_33452)"
            fillRule="evenodd"
            id="Exclude"
          />
        </g>
        <g id="Lock">
          <path
            d="M63.0953 64.6592C63.0953 62.7882 63.8386 60.9938 65.1616 59.6708C66.4846 58.3478 68.279 57.6045 70.1501 57.6045C72.0211 57.6045 73.8155 58.3478 75.1385 59.6708C76.4615 60.9938 77.2048 62.7882 77.2048 64.6592V69.1133H80.0267V64.6592C80.0267 62.0398 78.9861 59.5276 77.1339 57.6754C75.2817 55.8232 72.7695 54.7826 70.1501 54.7826C67.5306 54.7826 65.0185 55.8232 63.1662 57.6754C61.314 59.5276 60.2734 62.0398 60.2734 64.6592V69.1133H63.0953V64.6592Z"
            fill="url(#paint2_radial_2197_33452)"
            id="Vector_2"
          />
          <g filter="url(#filter1_bd_2197_33452)" id="Exclude_2">
            <path
              clipRule="evenodd"
              d="M57.0674 68.9742C55.5831 68.9742 54.3799 70.09 54.3799 71.4663V93.8949C54.3799 95.2712 55.5831 96.3869 57.0674 96.3869H82.8295C84.3138 96.3869 85.517 95.2712 85.517 93.8949V71.4663C85.517 70.09 84.3138 68.9742 82.8295 68.9742H57.0674ZM67.2609 80.3126C67.2609 78.867 68.4643 77.6965 69.9485 77.6965C71.4326 77.6965 72.636 78.867 72.636 80.3126C72.636 81.2131 72.1688 82.0065 71.4579 82.4786C71.3004 82.5832 71.2033 82.7585 71.2302 82.9434L71.6672 85.9198C71.7056 86.1826 71.4963 86.4187 71.2236 86.4187H68.6733C68.4006 86.4187 68.1913 86.1826 68.2297 85.9198L68.6667 82.9434C68.6936 82.7585 68.5965 82.5832 68.439 82.4786C67.7281 82.0065 67.2609 81.2131 67.2609 80.3126Z"
              fill="url(#paint3_linear_2197_33452)"
              fillRule="evenodd"
            />
            <path
              d="M72.1619 85.8471L72.1619 85.8475C72.2463 86.4255 71.7874 86.9187 71.2236 86.9187H68.6733C68.1095 86.9187 67.6506 86.4255 67.735 85.8475L67.735 85.8471L68.1678 82.8991L72.1619 85.8471ZM72.1619 85.8471L71.7291 82.8991C71.7307 82.8978 71.7325 82.8964 71.7346 82.895C72.5753 82.3366 73.136 81.3918 73.136 80.3126C73.136 78.5782 71.6959 77.1965 69.9485 77.1965C68.201 77.1965 66.7609 78.5782 66.7609 80.3126C66.7609 81.3918 67.3216 82.3366 68.1623 82.895L72.1619 85.8471ZM54.8799 71.4663C54.8799 70.401 55.823 69.4742 57.0674 69.4742H82.8295C84.0739 69.4742 85.017 70.401 85.017 71.4663V93.8949C85.017 94.9602 84.0739 95.8869 82.8295 95.8869H57.0674C55.823 95.8869 54.8799 94.9602 54.8799 93.8949V71.4663Z"
              stroke="url(#paint4_linear_2197_33452)"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="149.604"
          id="filter0_f_2197_33452"
          width="139.137"
          x="0.379883"
          y="0.782593"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_2197_33452" stdDeviation="27" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="39.4127"
          id="filter1_bd_2197_33452"
          width="39.1372"
          x="50.3799"
          y="64.9742"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2197_33452" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="effect1_backgroundBlur_2197_33452" mode="normal" result="effect2_dropShadow_2197_33452" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_2197_33452" mode="normal" result="shape" />
        </filter>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(54.437 66.1915) scale(32.0007 72.4524)"
          gradientUnits="userSpaceOnUse"
          id="paint0_radial_2197_33452"
          r="1"
        >
          <stop stopColor="#1D1D1B" />
          <stop offset="0.5" stopColor="#707070" />
          <stop offset="1" stopColor="#1D1D1B" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(70.0199 82.6093) rotate(-54.5095) scale(39.3027 38.5137)"
          gradientUnits="userSpaceOnUse"
          id="paint1_radial_2197_33452"
          r="1"
        >
          <stop stopColor="#707070" />
          <stop offset="1" stopColor="#1D1D1B" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(54.437 60.6262) scale(32.0007 72.4524)"
          gradientUnits="userSpaceOnUse"
          id="paint2_radial_2197_33452"
          r="1"
        >
          <stop stopColor="#1D1D1B" />
          <stop offset="0.5" stopColor="#707070" />
          <stop offset="1" stopColor="#1D1D1B" />
        </radialGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2197_33452"
          x1="48.9858"
          x2="80.3229"
          y1="99.1282"
          y2="63.864"
        >
          <stop stopColor="#707070" stopOpacity="0.15" />
          <stop offset="1" stopColor="#707070" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint4_linear_2197_33452"
          x1="82.8485"
          x2="59.1701"
          y1="99.1282"
          y2="65.8546"
        >
          <stop stopColor="#1D1D1B" stopOpacity="0" />
          <stop offset="0.5" stopColor="#EDEEEB" stopOpacity="0.45" />
          <stop offset="1" stopColor="#1D1D1B" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg fill="none" height="76" viewBox="0 0 76 76" width="76" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f_2145_167980)" opacity="0.55">
        <path
          d="M33.5288 30.5389C33.5288 29.3323 34.0081 28.1751 34.8613 27.3219C35.7146 26.4687 36.8718 25.9894 38.0784 25.9894C39.285 25.9894 40.4422 26.4687 41.2954 27.3219C42.1486 28.1751 42.6279 29.3323 42.6279 30.5389V38.795H44.4478V30.5389C44.4478 28.8497 43.7767 27.2296 42.5822 26.0351C41.3877 24.8406 39.7676 24.1696 38.0784 24.1696C36.3891 24.1696 34.769 24.8406 33.5745 26.0351C32.38 27.2296 31.709 28.8497 31.709 30.5389V38.795H33.5288V30.5389Z"
          fill="url(#paint0_radial_2145_167980)"
        />
        <path
          clipRule="evenodd"
          d="M29.6416 33.3217C28.6844 33.3217 27.9084 34.0412 27.9084 34.9288V49.3929C27.9084 50.2805 28.6844 51 29.6416 51H46.2555C47.2127 51 47.9886 50.2805 47.9886 49.3929V34.9288C47.9886 34.0412 47.2127 33.3217 46.2555 33.3217H29.6416ZM36.2154 40.6338C36.2154 39.7015 36.9914 38.9466 37.9485 38.9466C38.9057 38.9466 39.6817 39.7015 39.6817 40.6338C39.6817 41.2145 39.3804 41.7261 38.922 42.0306C38.8204 42.0981 38.7578 42.2111 38.7751 42.3304L39.057 44.2498C39.0817 44.4193 38.9467 44.5716 38.7709 44.5716H37.1262C36.9504 44.5716 36.8154 44.4193 36.8401 44.2498L37.122 42.3304C37.1393 42.2111 37.0767 42.0981 36.9751 42.0306C36.5167 41.7261 36.2154 41.2145 36.2154 40.6338Z"
          fill="url(#paint1_radial_2145_167980)"
          fillRule="evenodd"
        />
      </g>
      <path
        d="M33.5288 30.5389C33.5288 29.3323 34.0081 28.1751 34.8613 27.3219C35.7146 26.4687 36.8718 25.9894 38.0784 25.9894C39.285 25.9894 40.4422 26.4687 41.2954 27.3219C42.1486 28.1751 42.6279 29.3323 42.6279 30.5389V33.4114H44.4478V30.5389C44.4478 28.8497 43.7767 27.2296 42.5822 26.0351C41.3877 24.8406 39.7676 24.1696 38.0784 24.1696C36.3891 24.1696 34.769 24.8406 33.5745 26.0351C32.38 27.2296 31.709 28.8497 31.709 30.5389V33.4114H33.5288V30.5389Z"
        fill="url(#paint2_radial_2145_167980)"
      />
      <g filter="url(#filter1_bd_2145_167980)">
        <path
          clipRule="evenodd"
          d="M29.6416 33.3217C28.6844 33.3217 27.9084 34.0412 27.9084 34.9288V49.3929C27.9084 50.2805 28.6844 51 29.6416 51H46.2555C47.2127 51 47.9886 50.2805 47.9886 49.3929V34.9288C47.9886 34.0412 47.2127 33.3217 46.2555 33.3217H29.6416ZM36.2154 40.6338C36.2154 39.7015 36.9914 38.9466 37.9485 38.9466C38.9057 38.9466 39.6817 39.7015 39.6817 40.6338C39.6817 41.2145 39.3804 41.7261 38.922 42.0306C38.8204 42.0981 38.7578 42.2111 38.7751 42.3304L39.057 44.2498C39.0817 44.4193 38.9467 44.5716 38.7709 44.5716H37.1262C36.9504 44.5716 36.8154 44.4193 36.8401 44.2498L37.122 42.3304C37.1393 42.2111 37.0767 42.0981 36.9751 42.0306C36.5167 41.7261 36.2154 41.2145 36.2154 40.6338Z"
          fill="url(#paint3_linear_2145_167980)"
          fillRule="evenodd"
        />
        <path
          d="M39.5517 44.1776L39.5517 44.1772L39.2884 42.3842C39.8268 41.9875 40.1817 41.3539 40.1817 40.6338C40.1817 39.4127 39.169 38.4466 37.9485 38.4466C36.7281 38.4466 35.7154 39.4127 35.7154 40.6338C35.7154 41.3539 36.0703 41.9875 36.6087 42.3842L36.3454 44.1772L36.3454 44.1776C36.2746 44.6622 36.6592 45.0716 37.1262 45.0716H38.7709C39.2379 45.0716 39.6225 44.6622 39.5517 44.1776ZM28.4084 34.9288C28.4084 34.3523 28.9243 33.8217 29.6416 33.8217H46.2555C46.9728 33.8217 47.4886 34.3523 47.4886 34.9288V49.3929C47.4886 49.9695 46.9728 50.5 46.2555 50.5H29.6416C28.9243 50.5 28.4084 49.9695 28.4084 49.3929V34.9288Z"
          stroke="url(#paint4_linear_2145_167980)"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="134.83"
          id="filter0_f_2145_167980"
          width="128.08"
          x="-26.0916"
          y="-29.8304"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_2145_167980" stdDeviation="27" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="29.6783"
          id="filter1_bd_2145_167980"
          width="28.0803"
          x="23.9084"
          y="29.3217"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2145_167980" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="effect1_backgroundBlur_2145_167980" mode="normal" result="effect2_dropShadow_2145_167980" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_2145_167980" mode="normal" result="shape" />
        </filter>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(27.9451 31.5271) scale(20.6371 46.7242)"
          gradientUnits="userSpaceOnUse"
          id="paint0_radial_2145_167980"
          r="1"
        >
          <stop stopColor="#1D1D1B" />
          <stop offset="0.5" stopColor="#707070" />
          <stop offset="1" stopColor="#1D1D1B" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(37.9946 42.1149) rotate(-54.5095) scale(25.3461 24.8373)"
          gradientUnits="userSpaceOnUse"
          id="paint1_radial_2145_167980"
          r="1"
        >
          <stop stopColor="#707070" />
          <stop offset="1" stopColor="#1D1D1B" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(27.9451 27.9381) scale(20.6371 46.7242)"
          gradientUnits="userSpaceOnUse"
          id="paint2_radial_2145_167980"
          r="1"
        >
          <stop stopColor="#1D1D1B" />
          <stop offset="0.5" stopColor="#707070" />
          <stop offset="1" stopColor="#1D1D1B" />
        </radialGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_2145_167980"
          x1="24.4298"
          x2="44.639"
          y1="52.7679"
          y2="30.0261"
        >
          <stop stopColor="#707070" stopOpacity="0.15" />
          <stop offset="1" stopColor="#707070" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint4_linear_2145_167980"
          x1="46.2678"
          x2="30.9977"
          y1="52.7679"
          y2="31.3099"
        >
          <stop stopColor="#1D1D1B" stopOpacity="0" />
          <stop offset="0.5" stopColor="#EDEEEB" stopOpacity="0.45" />
          <stop offset="1" stopColor="#1D1D1B" />
        </linearGradient>
      </defs>
    </svg>
  );

export default PadlockIcon;
