import PropTypes from 'prop-types';

const BadgeOwnership = ({
  highlightBox = false,
  ownBy,
  selected = false,
  windowWidthSize,
  isProgressiveAndLocked,
  isUpcoming,
}) => {
  const text = () =>
    isProgressiveAndLocked ? (
      <p className="locked">Reach the next level to unlock</p>
    ) : isUpcoming ? (
      <p>Data will be available as soon as possible</p>
    ) : (
      <p>Owned by {ownBy}% of affiliates</p>
    );

  const mobileText = () => (isUpcoming ? <p className="upcoming">???</p> : <p>{ownBy}%</p>);
  return (
    <div className={`owned-by-section ${highlightBox ? 'highlight' : ''}`}>
      <div className={`owned-by-icon-box ${isProgressiveAndLocked ? 'locked' : ''}`}>
        <svg fill="none" height="17" viewBox="0 0 16 17" width="16" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1567_23340)" id="icon-park-outline:five-star-badge">
            <path
              d="M7.56495 7.31224C7.59838 7.2444 7.65013 7.18728 7.71435 7.14733C7.77857 7.10739 7.85268 7.08622 7.92831 7.08622C8.00394 7.08622 8.07805 7.10739 8.14227 7.14733C8.20648 7.18728 8.25824 7.2444 8.29166 7.31224L9.50083 9.76216C9.52985 9.82113 9.57278 9.87215 9.62592 9.91082C9.67906 9.9495 9.74081 9.97466 9.80585 9.98414L12.5098 10.3771C12.5845 10.388 12.6547 10.4196 12.7124 10.4683C12.7701 10.517 12.8131 10.5809 12.8364 10.6527C12.8598 10.7245 12.8626 10.8015 12.8445 10.8748C12.8264 10.9481 12.7882 11.015 12.7342 11.0677L10.7776 12.9748C10.7306 13.0207 10.6954 13.0772 10.6751 13.1397C10.6547 13.2021 10.6499 13.2686 10.661 13.3333L11.1228 16.0263C11.1355 16.1007 11.1271 16.1773 11.0986 16.2472C11.0702 16.3171 11.0227 16.3777 10.9616 16.4221C10.9006 16.4665 10.8283 16.493 10.753 16.4985C10.6776 16.504 10.6023 16.4883 10.5354 16.4533L8.11708 15.1821C8.05893 15.1515 7.99421 15.1355 7.92851 15.1355C7.86281 15.1355 7.7981 15.1515 7.73995 15.1821L5.32163 16.4533C5.25472 16.4885 5.17926 16.5044 5.10381 16.499C5.02836 16.4936 4.95593 16.4672 4.89471 16.4228C4.83349 16.3783 4.78594 16.3176 4.75744 16.2476C4.72894 16.1775 4.72063 16.1008 4.73345 16.0263L5.19524 13.3333C5.20636 13.2686 5.20159 13.2022 5.18134 13.1397C5.16109 13.0773 5.12596 13.0207 5.07898 12.9748L3.12245 11.0677C3.06829 11.015 3.02997 10.9481 3.01183 10.8747C2.99369 10.8013 2.99645 10.7243 3.01981 10.6524C3.04316 10.5805 3.08616 10.5166 3.14396 10.4678C3.20176 10.4191 3.27205 10.3875 3.34686 10.3767L6.05077 9.98374C6.11576 9.9743 6.17749 9.94919 6.23063 9.91059C6.28377 9.87199 6.32672 9.82105 6.35579 9.76216L7.56495 7.31224ZM12.7893 0.500023H3.06736V4.55081L7.92831 6.57621L12.7893 4.55081V0.500023Z"
              fill={isProgressiveAndLocked || isUpcoming ? '#707070' : '#00BD00'}
              id="Vector"
            />
          </g>
        </svg>
      </div>
      {windowWidthSize >= 992 || selected ? text() : mobileText()}
    </div>
  );
};

BadgeOwnership.propTypes = {
  highlightBox: PropTypes.bool,
  ownBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
  windowWidthSize: PropTypes.number,
};

export default BadgeOwnership;
