import request from '../../../utils/Req';
import HandleAPIError from '../../../utils/handleAPIError';
import apiqlFactory from '../../../utils/apiqlFactory';
import { explodeDescription } from '../../../utils/helpers';
import { getCookie } from '../../../utils/helpers';

import config from '../../../config';
import consts from '../../../utils/consts';

const autoOptimizedOffer = {
  id: 'optimized',
  name: 'Auto-Optimized Landing Pages',
  epc_affiliation: 'Optimal',
  description:
    'Our landing page optimizer enables you to rotate the best-performing landing pages available automatically. CrakRevenue tests each one of them, and the rotation is continuously optimized by AI algorithms.',
  screenshot: {
    desktop_thumbnail: 'https://assets.crakrevenue.com//wp-content/uploads/2022/05/landing_optimizer_gif.gif',
    mobile_thumbnail: 'https://assets.crakrevenue.com//wp-content/uploads/2022/05/landing_optimizer_gif.gif',
    desktop: '/img/offer/autoOptimize/landing-desktop.png',
    mobile: '/img/offer/autoOptimize/landing-mobile.png',
  },
  status: 'active',
};

const { API_URL, OVERLAY_CREATIVE_FILE_ID, POPCODE_CREATIVE_FILE_ID, POPUPCODE_CREATIVE_FILE_ID } = config;
const { ACTIONS } = consts;

export const requestTrackingLink = () => ({
  type: ACTIONS.REQUEST_TRACKING_LINK,
});
export const receiveTrackingLink = (data) => ({
  type: ACTIONS.RECEIVE_TRACKING_LINK,
  data,
});
export const failedToReceiveTrackingLink = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_TRACKING_LINK,
});
export const fetchTrackingLink =
  (offerId, params = {}, signal) =>
  (dispatch) => {
    dispatch(requestTrackingLink());
    return new Promise((fulfill, reject) => {
      const req = request
        .get(`${API_URL}/offer/generate-tracking-link`)
        .query({ offerId, params: JSON.stringify(params) })
        .withCredentials();

      // Attach the abort signal to the request if provided
      if (signal) {
        signal.addEventListener('abort', () => {
          req.abort();
        });
      }

      req.end((err, res) => {
        if (!err) {
          dispatch(receiveTrackingLink(res.body.data));
          fulfill(res.body.data);
        } else if (err && err.code === 'ABORT_ERR') {
          // Handle the abort error specifically if needed
          console.log('Request canceled:', err);
        } else {
          dispatch(failedToReceiveTrackingLink());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage(err);
          reject(err);
        }
      });
    });
  };

export const receiveSearchModelSuggestions = (data) => ({
  type: ACTIONS.RECEIVE_PERFORMER_PAGE_MODEL_SUGGESTIONS,
  data,
});

export const performerPageSearchModelSuggestions = (query) => async (dispatch) =>
  new Promise((fulfill, reject) => {
    request
      .get(`${process.env.REACT_APP_CATALOG_API}/items`)
      .query({
        query: query,
        itemType: 'performer',
        lang: 'en',
        hostname: 'cl.jerkmate',
        startPosition: 0,
        size: 3,
        sorting: 'creator',
      })
      .then((res) => {
        const itemsData = [];
        const sortedItems = res.body.items.sort((a, b) => {
          if (!!a.creator < !!b.creator) {
            return 1;
          }
          if (!!a.creator > !!b.creator) {
            return -1;
          }
          return 0;
        });
        sortedItems.forEach((currentItem) => {
          const item = {};
          if (currentItem.name) {
            item.name = currentItem.name;
          }

          if (currentItem.nameClean) {
            item.nameClean = currentItem.nameClean;
          }

          if (currentItem.imageUrlFull) {
            item.imageUrlFull = currentItem.imageUrlFull;
          }
          itemsData.push(item);
        });

        dispatch(receiveSearchModelSuggestions(itemsData));
        fulfill();
      })
      .catch((err) => {
        const APIErrorhandler = new HandleAPIError(err, dispatch);
        APIErrorhandler.redirectIfNotLoggedIn();
        APIErrorhandler.showErrorMessage();
        reject();
      });
  });

export const fetchPostitialTrackingLink = (offerId, params) =>
  new Promise((fulfill, reject) => {
    request
      .get(`${API_URL}/offer/generate-tracking-link`)
      .query({ offerId, params: JSON.stringify(params) })
      .withCredentials()
      .end((err, res) => {
        const { click_url } = res?.body?.data || {};
        if (err || !click_url) {
          reject('no tracking url found for this offer');
        } else {
          fulfill(click_url);
        }
      });
  });

export const requestCampaignLink = () => ({
  type: ACTIONS.REQUEST_CAMPAIGN_LINK,
});
export const receiveCampaignLink = (data) => ({
  type: ACTIONS.RECEIVE_CAMPAIGN_LINK,
  data,
});
export const receiveCampaignLinkDone = () => ({
  type: ACTIONS.RECEIVE_CAMPAIGN_LINK_DONE,
});
export const failedToReceiveCampaignLink = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_CAMPAIGN_LINK,
});
export const fetchCampaignLink =
  (campaignId, offerId, params = {}) =>
  (dispatch) => {
    dispatch(requestCampaignLink());
    return new Promise((fulfill, reject) =>
      request
        .get(`${API_URL}/offer/generate-campaign-link`)
        .query({ campaignId, offerId, params: JSON.stringify(params) })
        .withCredentials()
        .end((err, res) => {
          if (!err) {
            dispatch(receiveCampaignLink(res.body.data));
            dispatch(receiveCampaignLinkDone());
            fulfill(res.body.data);
          } else {
            dispatch(failedToReceiveCampaignLink());
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage(err);
            reject(err);
          }
        })
    );
  };

export const resetOffer = () => ({ type: ACTIONS.RESET_OFFER });
export const requestOffer = () => ({ type: ACTIONS.REQUEST_OFFER });
export const receiveOffer = (offer) => ({
  type: ACTIONS.RECEIVE_OFFER,
  offersReceivedAt: Date.now(),
  offer,
});
export const failedToReceiveOffer = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_OFFER,
});

export const fetchOffer = (id, reset = true) => {
  const getExplodedData = (offerDescription) => {
    const explodedDescription = explodeDescription(offerDescription);
    return {
      config: explodedDescription.config,
      conversionTags: explodedDescription.conversionTags,
      conversionTitle: explodedDescription.conversionTitle,
      campaignId: explodedDescription.offerCampaignId || null,
      creativeId: explodedDescription.offerCreativeReplaceId || null,
      mailingOfferId: explodedDescription.mailingOfferId || null,
    };
  };

  const getRestrictions = (termsAndConditions) => {
    try {
      return JSON.parse(termsAndConditions).restrictions;
    } catch (e) {
      return [];
    }
  };
  return (dispatch) => {
    if (reset) {
      dispatch(resetOffer());
    }
    return new Promise((fulfill, reject) => {
      dispatch(requestOffer());
      return request
        .post(`${API_URL}/apiql`)
        .query({
          query: apiqlFactory({ Offer: { id } }),
        })
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .withCredentials()
        .end((err, res) => {
          if (res.body.data?.Offer?.id) {
            const offerData = res.body.data.Offer;
            const restrictions = getRestrictions(offerData.terms_and_conditions);
            const explodedData = getExplodedData(offerData.description);

            if (explodedData.campaignId) {
              offerData.landing_pages.push(autoOptimizedOffer);
            }
            const newOfferData = {
              ...offerData,
              ...explodedData,
              restrictions,
              noPop: restrictions && restrictions.some((restriction) => restriction.match(/^No.{0,1}Pop.{0,1}Traffic$/i)),
            };
            dispatch(receiveOffer(newOfferData));
            fulfill();
          } else {
            dispatch(failedToReceiveOffer());
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
          }
        });
    });
  };
};

export const requestOfferOverlay = () => ({
  type: ACTIONS.REQUEST_OFFER_OVERLAY,
});
export const receiveOfferOverlay = (data) => ({
  type: ACTIONS.RECEIVE_OFFER_OVERLAY,
  data,
});
export const failedToReceiveOfferOverlay = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_OFFER_OVERLAY,
});
export const fetchOfferOverlay = () => (dispatch) => {
  dispatch(requestOfferOverlay());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/creative/get-creative-code`)
      .withCredentials()
      .query({ id: OVERLAY_CREATIVE_FILE_ID, isOverlay: true })
      .then((res) => {
        dispatch(receiveOfferOverlay(res.body));
        fulfill();
      })
      .catch((err) => {
        dispatch(failedToReceiveOfferOverlay());
        const APIErrorhandler = new HandleAPIError(err, dispatch);
        APIErrorhandler.redirectIfNotLoggedIn();
        APIErrorhandler.showErrorMessage();
        reject();
      })
  );
};

export const requestOfferPopCode = () => ({
  type: ACTIONS.REQUEST_OFFER_POPCODE,
});
export const receiveOfferPopCode = (data) => ({
  type: ACTIONS.RECEIVE_OFFER_POPCODE,
  data,
});
export const failedToReceiveOfferPopCode = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_OFFER_POPCODE,
});
export const fetchOfferPopCode = () => (dispatch) => {
  dispatch(requestOfferPopCode());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/creative/get-creative-code`)
      .withCredentials()
      .query({ id: POPCODE_CREATIVE_FILE_ID })
      .then((res) => {
        dispatch(receiveOfferPopCode(res.body));
        fulfill();
      })
      .catch((err) => {
        dispatch(failedToReceiveOfferPopCode());
        const APIErrorhandler = new HandleAPIError(err, dispatch);
        APIErrorhandler.redirectIfNotLoggedIn();
        APIErrorhandler.showErrorMessage();
        reject();
      })
  );
};

export const requestOfferPopUpCode = () => ({
  type: ACTIONS.REQUEST_OFFER_POPUPUPCODE,
});
export const receiveOfferPopUpCode = (data) => ({
  type: ACTIONS.RECEIVE_OFFER_POPUPUPCODE,
  data,
});
export const failedToReceiveOfferPopUpCode = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_OFFER_POPUPUPCODE,
});
export const fetchOfferPopUpCode = () => (dispatch) => {
  dispatch(requestOfferPopUpCode());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/creative/get-creative-code`)
      .withCredentials()
      .query({ id: POPUPCODE_CREATIVE_FILE_ID, isPopupCode: true })
      .then((res) => {
        dispatch(receiveOfferPopUpCode(res.body));
        fulfill();
      })
      .catch((err) => {
        dispatch(failedToReceiveOfferPopUpCode());
        const APIErrorhandler = new HandleAPIError(err, dispatch);
        APIErrorhandler.redirectIfNotLoggedIn();
        APIErrorhandler.showErrorMessage();
        reject();
      })
  );
};

export const requestWidgetDomain = () => ({
  type: ACTIONS.REQUEST_WIDGET_DOMAIN,
});
export const receiveWidgetDomain = (data) => ({
  type: ACTIONS.RECEIVE_WIDGET_DOMAIN,
  data,
});
export const failedToReceiveWidgetDomain = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_WIDGET_DOMAIN,
});
export const fetchWidgetDomain = () => (dispatch) => {
  dispatch(requestWidgetDomain());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/creative/get-creative-code`)
      .withCredentials()
      .query({ id: 'widget_domain', getWidgetDomain: true })
      .then((res) => {
        dispatch(receiveWidgetDomain(res.body));
        fulfill();
      })
      .catch((err) => {
        dispatch(failedToReceiveWidgetDomain());
        const APIErrorhandler = new HandleAPIError(err, dispatch);
        APIErrorhandler.redirectIfNotLoggedIn();
        APIErrorhandler.showErrorMessage();
        reject();
      })
  );
};

export const requestOfferUrl = () => ({ type: ACTIONS.REQUEST_OFFER_URL });
export const receiveOfferUrl = (data) => ({
  type: ACTIONS.RECEIVE_OFFER_URL,
  offersReceivedAt: Date.now(),
  offerUrl: data.offer_url,
});
export const failedToReceiveOfferUrl = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_OFFER_URL,
});
export const fetchOfferUrl = (id) => (dispatch) => {
  dispatch(requestOfferUrl());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/offerUrl/findById`)
      .withCredentials()
      .query({ id })
      .then((res) => {
        dispatch(receiveOfferUrl(res.body.data));
        fulfill();
      })
      .catch((err) => {
        dispatch(failedToReceiveOfferUrl());
        const APIErrorhandler = new HandleAPIError(err, dispatch);
        APIErrorhandler.redirectIfNotLoggedIn();
        APIErrorhandler.showErrorMessage();
        reject();
      })
  );
};

export const requestOfferAccess =
  (offerId, message, fetch = true) =>
  (dispatch) =>
    new Promise((fulfill, reject) =>
      request
        .post(`${API_URL}/offer/requestOfferAccess`)
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .withCredentials()
        .send({ offerId, message })
        .end((err, res) => {
          if (!err) {
            if (fetch) {
              dispatch(fetchOffer(offerId, false));
            }
            fulfill();
          } else {
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            reject();
          }
        })
    );

export const requestCreativeLink = () => ({
  type: ACTIONS.REQUEST_CREATIVE_LINK,
});
export const receiveCreatedCreative = (data) => ({
  type: ACTIONS.RECEIVE_CREATIVE_LINK,
  data,
});

export const failedToReceiveCreativeLink = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_CREATIVE_LINK,
});

export const fetchCreativeLink =
  (creativeId, placeholders = {}) =>
  (dispatch) => {
    dispatch(requestCreativeLink());
    return new Promise((fulfill, reject) =>
      request
        .post(`${API_URL}/creative/get-creative-code`)
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .withCredentials()
        .send({
          creativeId,
          placeholders,
        })
        .end((err, res) => {
          if (!err) {
            dispatch(receiveCreatedCreative(res.body));
            fulfill();
          } else {
            dispatch(failedToReceiveCreativeLink());
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            reject();
          }
        })
    );
  };

export const requestOfferAccessV2 =
  (
    offerId,
    offerIdPrerequisiteMediaPlacements,
    question_id_1,
    answer_1,
    question_id_2,
    answer_2,
    question_id_3,
    answer_3,
    fetch = true
  ) =>
  (dispatch) =>
    new Promise((fulfill, reject) =>
      request
        .post(`${API_URL}/offer/requestOfferAccess/v2`)
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .withCredentials()
        .send({
          offerId,
          offerIdPrerequisiteMediaPlacements,
          question_id_1,
          answer_1,
          question_id_2,
          answer_2,
          question_id_3,
          answer_3,
        })
        .end((err, res) => {
          if (!err) {
            if (fetch) {
              dispatch(fetchOffer(offerId, false));
            }
            fulfill();
          } else {
            const APIErrorhandler = new HandleAPIError(res, dispatch);
            APIErrorhandler.redirectIfNotLoggedIn();
            APIErrorhandler.showErrorMessage();
            const errorMessage = res?.body?.errorMessage || 'Whoops! Something went wrong please try again later.';
            reject(errorMessage);
          }
        })
    );

export const sendCreativesByEmail = (id, userEmail, selectedCreatives, linkParams) => (dispatch) =>
  new Promise((fulfill, reject) =>
    request
      .post(`${API_URL}/offer/send-creatives`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .send({
        offerId: id,
        email: userEmail,
        selectedCreatives,
        linkParams,
      })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          fulfill();
        } else {
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );

export const requestUnsubscribeLinks = () => ({
  type: ACTIONS.REQUEST_UNSUBSCRIBE_LINKS,
});
export const receiveUnsubscribeLinks = (data) => ({
  type: ACTIONS.RECEIVE_UNSUBSCRIBE_LINKS,
  data,
});
export const failedToReceiveUnsubscribeLinks = () => ({
  type: ACTIONS.FAILED_TO_RECEIVE_UNSUBSCRIBE_LINKS,
});
export const fetchUnsubscribeLinks = (id) => (dispatch) => {
  dispatch(requestUnsubscribeLinks());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/offer/unsubscribeLinks`)
      .query({ id })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          dispatch(receiveUnsubscribeLinks(res.body.data));
          fulfill(res.body.data);
        } else {
          dispatch(failedToReceiveUnsubscribeLinks());
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      })
  );
};
