import { FormattedNumber } from 'react-intl';
import InfoIcon from './InfoIcon';
import moment from 'moment';
import { getCurrentPeriod } from '../../utils/dateHelper';
import React from 'react';

const PaymentDueInfo = ({
  currentEarnings,
  paymentTab,
  nextDatePayment,
  daysLeftToPeriod,
  minimumPayoutAmount,
  paymentTerm,
  windowWidthSize,
}) => {
  const dueDate = moment(getCurrentPeriod(undefined, paymentTerm).dateEnd).format('MMMM Do YYYY');
  return (
    paymentTab === 'current' &&
    (currentEarnings >= minimumPayoutAmount && minimumPayoutAmount > 0 ? (
      <div className="next-payment-box">
        <span className="next-payment-date">
          Total payment due on <br /> {nextDatePayment}
        </span>
        <span className="next-payment-amount">
          <FormattedNumber currency="USD" style="currency" value={currentEarnings} />
        </span>
        <span className="next-payment-warning-box">
          <InfoIcon /> This amount may vary until the end of the period in {daysLeftToPeriod} days
        </span>
      </div>
    ) : currentEarnings < minimumPayoutAmount && currentEarnings > 0 ? (
      <div className="next-payment-box">
        <span className="next-payment-date">
          Earn at least <FormattedNumber currency="USD" style="currency" value={minimumPayoutAmount - currentEarnings} /> by{' '}
          {dueDate} {windowWidthSize >= 420 && <br />} to get paid on {nextDatePayment}, or we’ll carry over your earnings to the
          next period.
        </span>
        <span className="next-payment-warning-box">
          <InfoIcon /> Current period ends in {daysLeftToPeriod} days
        </span>
      </div>
    ) : (
      <div className="next-payment-box">
        <span className="next-payment-date">You have not generated any revenue yet.</span>
        <span className="next-payment-warning-box">
          <InfoIcon /> Current period ends in {daysLeftToPeriod} days
        </span>
        <a
          className="how-to-link"
          href="https://www.crakrevenue.com/seven-figure-affiliate-cookbook/"
          rel="noreferrer"
          target="_blank"
        >
          How to make money with CrakRevenue?
        </a>
      </div>
    ))
  );
};

export default PaymentDueInfo;
