import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';

import { InputText, Textarea, InputNotice } from '../../../../components/reusables';

import provincesList from '../../../../utils/list-options/provinces';
import statesList from '../../../../utils/list-options/states';

import messages from '../../../../i18n/base-en.js';
import SelectCustom from '../../../../components/reusables/form/SelectCustom.js';

const IntermediaryBankInfos = ({ values, fields, willRender, countries, intl }) => {
  const [savedRoutingNumber, setRoutingNumber] = useState(fields.wireIntermediaryABArouting.value || '');

  const isCountryWithRoutingNumber = (country) => ['US', 'CA', 'PR'].includes(country);

  useEffect(() => {
    if (isCountryWithRoutingNumber(values.wireIntermediaryBankCountry)) {
      setRoutingNumber(fields.wireIntermediaryABArouting.value || '');
    }
  }, [fields.wireIntermediaryABArouting.value, values.wireIntermediaryBankCountry]);

  let wireIntemediaryBank = false;

  if (willRender) {
    wireIntemediaryBank = (
      <div className="wireIntemediaryBank_section">
        <div className="customSelects">
          <div className="fields" data-cy="select-wrapper-wireIntermediaryBankCountry">
            <SelectCustom
              enableSearch
              id="wireIntermediaryBankCountry"
              label={messages.paymentInfosWireIntermediaryBankInformationCountry.description}
              onChange={(e) => {
                fields.wireIntermediaryBankCountry.onChange(e);
                if (isCountryWithRoutingNumber(e)) {
                  fields.wireIntermediaryABArouting.onChange(savedRoutingNumber);
                } else {
                  fields.wireIntermediaryABArouting.onChange('');
                }
              }}
              options={countries.map((country) => ({ value: country.code, text: country.name }))}
              showSelectedFieldCheckmark={false}
              tabIndex={230}
              value={fields.wireIntermediaryBankCountry.value || ''}
              valueDisabled={intl.formatMessage(messages.genericTextChoose)}
            />
          </div>
          {(fields.wireIntermediaryBankCountry.value === 'UA' || fields.wireIntermediaryBankCountry.value === 'RU') && (
            <InputNotice className="col-md-12">
              <span>
                <FormattedHTMLMessage {...messages.paymentInfosRussiaUkraineMessage} />
              </span>
            </InputNotice>
          )}
        </div>

        {values.wireIntermediaryBankCountry ? (
          <div>
            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.wireIntermediaryBankName}
                id="wireIntermediaryBankName"
                label={messages.paymentInfosWireIntermediaryBankInformationName}
                maxLength={50}
                tabIndex={200}
                type="text"
              />
            </div>

            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.wireIntermediaryBankAddress}
                id="wireIntermediaryBankAddress"
                label={messages.paymentInfosWireIntermediaryBankInformationAddress}
                maxLength={60}
                tabIndex={210}
                type="text"
              />
            </div>

            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.wireIntermediaryBankCity}
                id="wireIntermediaryBankCity"
                label={messages.paymentInfosWireIntermediaryBankInformationCity}
                maxLength={20}
                tabIndex={220}
                type="text"
              />
            </div>
            {values.wireIntermediaryBankCountry === 'CA' && (
              <div className="select-field customSelects">
                <div className="fields">
                  <SelectCustom
                    id="wireIntermediaryBankProvince"
                    label={messages.paymentInfosWireIntermediaryBankInformationProvince.description}
                    onChange={fields.wireIntermediaryBankState.onChange}
                    options={provincesList.map((province) => ({
                      value: province.value,
                      text: intl.formatMessage(messages[province.label.id]),
                    }))}
                    showSelectedFieldCheckmark={false}
                    tabIndex={240}
                    value={fields.wireIntermediaryBankState.value || ''}
                    valueDisabled={intl.formatMessage(messages.genericTextChoose)}
                  />
                  {(!fields.wireIntermediaryBankState.value || fields.wireIntermediaryBankState.value === '') && (
                    <div className="col-md-12 field-error ">Please select the province of the intermediary bank.</div>
                  )}
                </div>
              </div>
            )}

            {values.wireIntermediaryBankCountry === 'US' && (
              <div className="select-field customSelects">
                <div className="fields">
                  <SelectCustom
                    id="wireIntermediaryBankState"
                    label={messages.paymentInfosWireIntermediaryBankInformationState.description}
                    onChange={fields.wireIntermediaryBankState.onChange}
                    options={statesList.map((state) => ({
                      value: state.value,
                      text: intl.formatMessage(messages[state.label.id]),
                    }))}
                    showSelectedFieldCheckmark={false}
                    tabIndex={240}
                    value={fields.wireIntermediaryBankState.value || ''}
                    valueDisabled={intl.formatMessage(messages.genericTextChoose)}
                  />
                  {(!fields.wireIntermediaryBankState.value || fields.wireIntermediaryBankState.value === '') && (
                    <div className="col-md-12 field-error">Please select the state of the intermediary bank.</div>
                  )}
                </div>
              </div>
            )}

            {values.wireIntermediaryBankCountry !== 'CA' && values.wireIntermediaryBankCountry !== 'US' && (
              <div className="row">
                <InputText
                  className="col-md-12"
                  displayErrorInstantly
                  field={fields.wireIntermediaryBankState}
                  id="wireIntermediaryBankState"
                  label={messages.paymentInfosWireIntermediaryBankInformationRegion}
                  tabIndex={240}
                  type="text"
                />
              </div>
            )}

            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.wireIntermediaryBankZipcode}
                id="wireIntermediaryBankZipcode"
                label={
                  values.wireIntermediaryBankCountry === 'US'
                    ? messages.paymentInfosWireIntermediaryBankInformationZipcode
                    : values.wireIntermediaryBankCountry === 'CA'
                    ? messages.paymentInfosWireIntermediaryBankInformationPostalcode
                    : messages.paymentInfosWireIntermediaryBankInformationZipPostalcode
                }
                tabIndex={250}
                type="text"
              />
            </div>

            <div className="row">
              <InputText
                className="col-md-12"
                displayErrorInstantly
                field={fields.wireIntermediaryBicSwift}
                id="wireIntermediaryBicSwift"
                label={messages.paymentInfosWireIntermediaryBicSwiftLabel}
                maxLength={12}
                placeholder={null}
                tabIndex={270}
                type="text"
              />
            </div>

            {(values.wireIntermediaryBankCountry === 'US' ||
              values.wireIntermediaryBankCountry === 'CA' ||
              values.wireIntermediaryBankCountry === 'PR') && (
              <div className="row">
                <InputText
                  className="col-md-12"
                  displayErrorInstantly
                  field={fields.wireIntermediaryABArouting}
                  id="wireIntermediaryABArouting"
                  label={
                    values.wireIntermediaryBankCountry === 'CA'
                      ? messages.paymentInfosWireIntermediaryTransitNumberLabel
                      : messages.paymentInfosWireIntermediaryABAroutingLabel
                  }
                  mask={values.wireIntermediaryBankCountry === 'CA' ? '999-99999' : null}
                  maxLength={9}
                  tabIndex={290}
                  type="text"
                />
              </div>
            )}

            <div className="row">
              <Textarea
                className="col-md-12"
                field={fields.otherInformation}
                id="referenceInformation"
                label={messages.paymentInfosReferenceInformationLabel}
                optional
                tabIndex={300}
              />
              {fields.otherInformation.value === ' ' ||
              (fields.otherInformation.value &&
                fields.otherInformation.value[fields.otherInformation.value.length - 1] === ' ') ? (
                <div className="field-error" style={{ paddingLeft: '15px' }}>
                  The field must not start or end with an empty space.
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  return wireIntemediaryBank;
};

IntermediaryBankInfos.propTypes = {
  countries: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  values: PropTypes.object.isRequired,
  willRender: PropTypes.bool.isRequired,
};

export default injectIntl(IntermediaryBankInfos);
