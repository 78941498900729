import { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import messages from '../../../i18n/base-en';
import { Gtm } from '../../../utils/gtm';
import { FormattedMessage } from 'react-intl';
import { VideoModalIcon } from '../../reusables';
import OffersSliderImage from './OffersSliderImage.react';

const DEFAULT_STATE = {
  first: 0,
  right: 1,
  next: 2,
  sliding: false,
  reverse: false,
  stopped: false,
  reset: false,
  isSliding: false,
  dotActive: 0,
  sliderActive: false,
};

const MOUSE_EVENT_DEFAULTS = {
  startX: null,
  swipeThreshold: 15,
  moveX: 0,
};

const goToSlideHref = (event) => {
  const slideLink = event?.target?.closest('a')?.href;
  if (slideLink) {
    window.location.href = slideLink;
  }
};

const OffersSliderLarge = ({ dispatch, level1, verticalsAsString, sliderGtmEvent, query }) => {
  const touchXStartRef = useRef(0);
  const startSliderTimeoutRef = useRef(null);
  const startSliderHoverTimeoutRef = useRef(null);
  const slidesContainerRef = useRef(null);
  const mouseDownXStartRef = useRef(MOUSE_EVENT_DEFAULTS.startX);
  const mouseMoveXRef = useRef(MOUSE_EVENT_DEFAULTS.moveX);
  const visibleSlidesRef = useRef(new Set());

  const slides = level1.map(({ image_url, cta_link }, index) => ({ image_url, cta_link, index, verticalsAsString }));

  const totalSlides = slides?.length;
  const slideTotalIndexes = totalSlides - 1;
  const singleSlide = totalSlides === 1;
  const twoSlides = totalSlides === 2;

  const animateWhenTwoSlides = () => {
    const windowWidth = window.innerWidth;

    if (twoSlides && windowWidth >= 768) {
      return false;
    }

    return true;
  };

  const [active, setActive] = useState(DEFAULT_STATE);

  const { sliderActive, first, right, next, sliding, reverse, stopped, reset, isSliding, dotActive } = active;

  const clearSliderTimeout = () => {
    if (startSliderTimeoutRef.current) {
      clearTimeout(startSliderTimeoutRef.current);
    }
    if (startSliderHoverTimeoutRef.current) {
      clearTimeout(startSliderHoverTimeoutRef.current);
    }
  };

  const startSlide = () => {
    if (singleSlide || !animateWhenTwoSlides()) {
      return;
    }

    clearSliderTimeout();

    setActive((active) => ({
      ...active,
      sliding: true,
      isSliding: true,
    }));
  };

  const nextSlide = useCallback(() => {
    if (isSliding) {
      return;
    }

    sliderGtmEvent({ level: 1, arrowDirection: 'right' });

    startSlide();
  }, [isSliding, startSlide, sliderGtmEvent]);

  const prevSlide = useCallback(() => {
    if (isSliding || !animateWhenTwoSlides()) {
      return;
    }

    sliderGtmEvent({ level: 1, arrowDirection: 'left' });

    clearSliderTimeout();

    setActive((active) => {
      const { first, right } = active;

      if (twoSlides) {
        return {
          ...active,
          reverse: true,
        };
      }

      let newRight = first - 1;

      if (newRight < 0) {
        newRight = slideTotalIndexes;
      }

      return {
        ...active,
        first: right,
        next: first,
        right: newRight,
        reverse: true,
      };
    });
  }, [isSliding, animateWhenTwoSlides, twoSlides, clearSliderTimeout, setActive, slideTotalIndexes, sliderGtmEvent]);

  const onHover = (event) => {
    const touchXStart = event.changedTouches?.[0]?.pageX;

    if (touchXStart) {
      touchXStartRef.current = touchXStart;
    }

    clearSliderTimeout();
  };

  const onHoverOut = (event) => {
    if (event.changedTouches?.length) {
      const swipeThreshold = 30;
      const touchXEnd = event.changedTouches?.[0]?.pageX;
      const touchDistance = touchXStartRef.current - touchXEnd;

      if (touchDistance > swipeThreshold) {
        nextSlide();
      } else if (touchDistance < swipeThreshold * -1) {
        prevSlide();
      }
    } else {
      startSliderHoverTimeoutRef.current = setTimeout(() => {
        nextSlide();
      }, 7000);
    }
  };

  const onMouseDown = (event) => {
    if (singleSlide || !animateWhenTwoSlides() || event.button !== 0) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    mouseDownXStartRef.current = event.pageX;
  };

  const onMouseUp = (event) => {
    const totalMouseMove = Math.abs(mouseMoveXRef.current);

    if (totalMouseMove < MOUSE_EVENT_DEFAULTS.swipeThreshold && event.button === 0) {
      goToSlideHref(event);
    }

    mouseMoveXRef.current = MOUSE_EVENT_DEFAULTS.moveX;
  };

  const onMouseMove = (event) => {
    const resetMouseDownXStartRef = () => (mouseDownXStartRef.current = MOUSE_EVENT_DEFAULTS.startX);

    if (mouseDownXStartRef.current !== MOUSE_EVENT_DEFAULTS.startX) {
      const swipeThreshold = MOUSE_EVENT_DEFAULTS.swipeThreshold;
      const swipeDistance = mouseDownXStartRef.current - event.pageX;
      mouseMoveXRef.current = swipeDistance;

      if (swipeDistance > swipeThreshold) {
        resetMouseDownXStartRef();
        nextSlide();
      } else if (swipeDistance < swipeThreshold * -1) {
        resetMouseDownXStartRef();
        prevSlide();
      }
    }
  };

  useEffect(() => {
    if (reverse) {
      setTimeout(() => {
        startSlide();
      }, 10);
    }
  }, [reverse]);

  useEffect(() => {
    if (!sliding && sliderActive) {
      startSliderTimeoutRef.current = setTimeout(() => {
        startSlide();
      }, 10000);
    }

    return () => {
      clearSliderTimeout();
    };
  }, [sliding, startSlide, sliderActive]);

  useEffect(() => {
    let timeout;

    if (sliding) {
      timeout = setTimeout(() => {
        setActive((active) => ({
          ...active,
          stopped: true,
        }));
      }, 601);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [sliding, setActive]);

  useEffect(() => {
    if (stopped) {
      setActive((active) => {
        let { first, right, next, reverse } = active;

        if (twoSlides) {
          return {
            ...active,
            right: first,
            first: right,
            reset: true,
            reverse: false,
          };
        } else if (!reverse) {
          next = next + 1;
          right = next - 1;
          first = right - 1;

          if (right === slideTotalIndexes) {
            next = 0;
            right = slideTotalIndexes;
            first = slideTotalIndexes - 1;
          } else if (first < 0) {
            next = 1;
            right = 0;
            first = slideTotalIndexes;
          }

          return {
            ...active,
            next,
            right,
            first,
            reset: true,
          };
        } else {
          return {
            ...active,
            first: right,
            right: next,
            next: first,
            reset: true,
            reverse: false,
          };
        }
      });
    }
  }, [stopped, twoSlides, slideTotalIndexes]);

  useEffect(() => {
    let timeout;

    if (reset) {
      timeout = setTimeout(() => {
        setActive((active) => ({
          ...active,
          sliding: false,
          stopped: false,
          reset: false,
          isSliding: false,
          reverse: false,
          dotActive: active.first,
        }));
      }, 100);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [reset, first]);

  useEffect(() => {
    setActive((slider) => ({
      ...slider,
      sliderActive: false,
    }));
    mouseDownXStartRef.current = MOUSE_EVENT_DEFAULTS.startX;
    mouseMoveXRef.current = MOUSE_EVENT_DEFAULTS.moveX;
  }, [verticalsAsString]);

  useEffect(() => {
    if (!sliderActive) {
      setActive({
        ...DEFAULT_STATE,
        sliderActive: true,
      });
    }
  }, [verticalsAsString, sliderActive]);

  const trackVisibleSlides = useCallback(() => {
    const visibleIndices = [first, right];
    visibleIndices.forEach((slideIndex) => {
      if (!visibleSlidesRef.current.has(slideIndex)) {
        const currentSlide = slides[slideIndex];
        if (currentSlide) {
          sliderGtmEvent({
            level: 1,
            positionIndex: slideIndex,
            imgSrc: currentSlide.image_url,
            eventParamsName: 'impression',
          });

          visibleSlidesRef.current.add(slideIndex);
        }
      }
    });
  }, [first, right, slides, sliderGtmEvent]);

  useEffect(() => {
    if (!sliderActive || sliding) {
      return;
    }

    // Add a delay to ensure state is fully updated after vertical change
    const timeoutId = setTimeout(() => {
      visibleSlidesRef.current.clear();

      trackVisibleSlides();
    }, 200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [sliderActive, trackVisibleSlides, query.vertical, sliding]);

  // Reset the visible slides tracking whenever active slides change
  useEffect(() => {
    visibleSlidesRef.current.clear();
  }, [first, right]);

  const slideFirst = singleSlide ? slides[0] : slides[first];
  const slideRight = slides[right];
  const slideNext = slides[next];

  if (!slideFirst || !sliderActive || !level1?.length) {
    return null;
  }

  return (
    <div className={`offers-slider-large totalSlides${totalSlides}`}>
      <div className="offer-slider-title">
        <h1 className="primary-color">
          <FormattedMessage {...messages.genericTextOffers} />
          <VideoModalIcon
            className="pointer offersVideoModal"
            dispatch={dispatch}
            gtmEvent={() => {
              Gtm.event('offers', 'Click', 'video icon');
            }}
            videoUrl="https://player.vimeo.com/video/391533716"
          />
        </h1>
        <div className="offer-slider-arrows">
          <div className="prev" onClick={prevSlide}>
            <img src="/img/offers/arrow-left.svg" />
          </div>
          <div className="next" onClick={nextSlide}>
            <img src="/img/offers/arrow-right.svg" />
          </div>
        </div>
      </div>
      <div
        className={`slidesLarge ${reset || (!sliding && reverse) ? 'reset' : sliding ? 'sliding' : ''} ${
          reverse ? 'reverse' : ''
        }`}
        onMouseDown={onMouseDown}
        onMouseEnter={onHover}
        onMouseLeave={onHoverOut}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onTouchEnd={onHoverOut}
        onTouchStart={onHover}
        ref={slidesContainerRef}
      >
        <OffersSliderImage
          className="first"
          href={slideFirst.cta_link}
          index={slideFirst.index}
          sliderGtmEvent={sliderGtmEvent}
          src={slideFirst.image_url}
        />

        <div className="sideSlides">
          {slideRight ? (
            <OffersSliderImage
              className="right"
              href={slideRight.cta_link}
              index={slideRight.index}
              sliderGtmEvent={sliderGtmEvent}
              src={slideRight.image_url}
            />
          ) : null}

          {slideNext ? (
            <OffersSliderImage
              className="next"
              href={slideNext.cta_link}
              index={slideNext.index}
              sliderGtmEvent={sliderGtmEvent}
              src={slideNext.image_url}
            />
          ) : null}
        </div>
      </div>

      <div className="dots">
        {[...Array(totalSlides).keys()].map((index) => (
          <div className={`dot ${dotActive === index ? 'active' : ''}`} key={index} />
        ))}
      </div>
    </div>
  );
};

export default OffersSliderLarge;
