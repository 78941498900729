import { Link } from 'react-router';
import { LockedSectionIcon } from '../../pages/profile/user-infos/svg/LockedSectionIcon.react';

export const LockedSectionWrapper = ({ lockedData }) => {
  return lockedData?.isLocked ? (
    <>
      <div className="locked-header">
        <div className="header-wrapper">
          <LockedSectionIcon />
          <div className="locked-header-content">
            <h2>{lockedData?.title ? lockedData?.title : 'Locked'}</h2>
            {lockedData?.subTitle ? (
              <span className="subtitle" dangerouslySetInnerHTML={{ __html: lockedData.subTitle }}></span>
            ) : null}
            {lockedData?.ctaUrl ? (
              <Link className="btn" onlyActiveOnIndex={false} to={lockedData?.ctaUrl}>
                {lockedData?.ctaText}
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </>
  ) : null;
};
