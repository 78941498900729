import React from 'react';
import './styles/OfferListingDetails.scss';
import OfferListingTags from './OfferListingTags.react';
import OfferListingBottomDetails from './OfferListingBottomDetails.react';
import { Link } from 'react-router';

const OfferListingDetails = ({ thumbnail, name, verticals, id, tags, section, onClick, href }) => {
  const onClickOffer = () => {
    if (onClick) {
      onClick();
    }
  };

  const classNames = `offerListingDetails  offerListingDetails${section || 'Default'}`;

  const children = (
    <>
      <div className="thumnnailImg">
        {thumbnail ? <img src={thumbnail} alt={name} /> : <div className="preview">Preview</div>}
      </div>
      <div className="listingDetails">
        <div className="listingName">{name}</div>
        {tags && !!tags?.length && <OfferListingTags tags={tags} />}
        {verticals && !!verticals?.length && <OfferListingBottomDetails verticals={verticals} id={id} />}
      </div>
    </>
  );

  let Component = (
    <div className={classNames} onClick={onClickOffer}>
      {children}
    </div>
  );

  if (href) {
    Component = (
      <Link onClick={onClickOffer} onlyActiveOnIndex={false} to={href} className={classNames}>
        {children}
      </Link>
    );
  }

  return Component;
};

export default OfferListingDetails;
