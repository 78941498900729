import { connect } from 'react-redux';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import SelectedBadge from './achievements/SelectedBadge';
import AchievementBadgeSlider from './achievements/AchievementBadgeSlider';
import { upcomingBadge, upcomingBadgeProgressive } from '../badgeDataConfig';
import { Info } from '../components/reusables';
import AchivementsRankingBox from './achievements/AchivementsRankingBox';
import {
  getSpecialAchievementBadges,
  getStreakAchievementBadges,
  MAX_LOGIN_STREAK_BADGE_LEVEL,
} from '../utils/gamificationHelper';
import { Gtm } from '../utils/gtm';
import { anyUniqueEventsIsFetching } from '../reducers/gamification-reducers';
import { useCallback } from 'react';

const Achievements = ({
  affiliateBadges,
  gamificationAffProgress,
  gamificationBadges,
  loginStreakBadges,
  badgeDataIsFetching,
  affiliateBadgesIsFetching,
  uniqueEventsIsFetching,
}) => {
  const [slideRight, setSlideRight] = useState(null);
  const [windowWidthSize, setWindowWidthSize] = useState(undefined);
  const [selectedBadgeId, setSelectedBadgeId] = useState(null);
  const [selectedAchievementBadge, setSelectedAchievementBadge] = useState(null);
  const achievementsContainerRef = useRef(null);
  const selectedBadgeContainerRef = useRef(null);
  const [transformY, setTransformY] = useState(0);
  const [sectionHeight, setSectionHeight] = useState('100%');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [slidersWidth, setSlidersWidth] = useState('100%');
  const [selectedBadgeTimer, setSelectedBadgeTimer] = useState(null);
  const [numberOfProgressiveBadges, setNumberOfProgressiveBadges] = useState(0);
  const numberOfSpecialBadges = 12;
  const [firstClickedBadgeId, setFirstClickedBadgeId] = useState(null);
  const [onLoadSelectedBadgeDisplayed, setOnLoadSelectedBadgeDisplayed] = useState(null);
  const eventsAreBeingProcessed = anyUniqueEventsIsFetching(uniqueEventsIsFetching);

  useEffect(() => {
    setNumberOfProgressiveBadges(windowWidthSize >= 992 ? 6 : 4);
  }, [windowWidthSize]);

  const serializedAffiliateBadges = useMemo(() => JSON.stringify(affiliateBadges), [affiliateBadges]);
  const serializedGamificationBadges = useMemo(() => JSON.stringify(gamificationBadges), [gamificationBadges]);
  const serializedGamificationAffProgress = useMemo(() => JSON.stringify(gamificationAffProgress), [gamificationAffProgress]);

  const streakAchievementBadges = useMemo(() => {
    if (!gamificationBadges || eventsAreBeingProcessed) {
      return [];
    }

    const affiliateStreakBadges = getStreakAchievementBadges(gamificationBadges, affiliateBadges, gamificationAffProgress);

    while (affiliateStreakBadges.length < numberOfProgressiveBadges) {
      affiliateStreakBadges.push(upcomingBadgeProgressive);
    }

    return affiliateStreakBadges;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    serializedAffiliateBadges,
    serializedGamificationBadges,
    serializedGamificationAffProgress,
    numberOfProgressiveBadges,
    eventsAreBeingProcessed,
  ]);

  const specialAchievementBadges = useMemo(() => {
    if (!gamificationBadges || eventsAreBeingProcessed) {
      return [];
    }

    const affiliateSpecialBadges = getSpecialAchievementBadges(gamificationBadges, affiliateBadges, gamificationAffProgress);

    while (affiliateSpecialBadges.length < numberOfSpecialBadges) {
      affiliateSpecialBadges.push(upcomingBadge);
    }

    return affiliateSpecialBadges;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializedAffiliateBadges, serializedGamificationBadges, serializedGamificationAffProgress, eventsAreBeingProcessed]);

  useEffect(() => {
    setDataLoaded(
      !(affiliateBadgesIsFetching && badgeDataIsFetching && eventsAreBeingProcessed) &&
        specialAchievementBadges.length >= 12 &&
        streakAchievementBadges.length >= numberOfProgressiveBadges
    );
  }, [
    badgeDataIsFetching,
    affiliateBadgesIsFetching,
    specialAchievementBadges.length,
    streakAchievementBadges,
    numberOfProgressiveBadges,
    eventsAreBeingProcessed,
  ]);

  useEffect(() => {
    () => {
      selectedBadgeTimer && clearTimeout(selectedBadgeTimer);
    };
  }, [selectedBadgeTimer]);

  const handleBadgeClick = useCallback(
    (badgeId, badge) => {
      if (badge?.disabled) {
        return;
      }
      if (selectedBadgeTimer) {
        clearTimeout(selectedBadgeTimer);
      }
      if (!badge) {
        setSelectedBadgeTimer(
          setTimeout(() => {
            setSelectedAchievementBadge(badge);
          }, 700)
        );
      } else {
        setSelectedAchievementBadge(badge);

        if (badge.badgeData.progressive) {
          Gtm.newEvent('progressive_badge', false, 'click', 'string', `${badge.title} - ${badge.level}`);
        } else {
          Gtm.newEvent(
            'signature_badge',
            false,
            'click',
            'string',
            `${badge.title} - ${badge.isUnlocked ? 'unlocked' : 'locked'}`
          );
        }
      }
      setSelectedBadgeId(badgeId);

      if (selectedBadgeContainerRef.current && achievementsContainerRef.current) {
        const selectedBadgeHeight = selectedBadgeContainerRef.current.offsetHeight;
        const dynamicOffset = selectedBadgeHeight + 20; // 20px gap
        setTransformY(badgeId !== null ? dynamicOffset : 0);
      }

      if (firstClickedBadgeId === null && badgeId !== null) {
        setFirstClickedBadgeId(badgeId);
      } else if (firstClickedBadgeId === badgeId && badgeId !== null) {
        setFirstClickedBadgeId(0);
      }

      if (badgeId === null) {
        setFirstClickedBadgeId(null);
      }

      setSlideRight(badgeId !== null);
      if (slideRight) {
        window.scroll({ top: 0, behavior: 'smooth' });
      } else {
        setTimeout(() => {
          window.scroll({ top: 0, behavior: 'smooth' });
        }, 100);
      }
    },
    [firstClickedBadgeId, selectedBadgeTimer, slideRight]
  );

  useEffect(() => {
    if (windowWidthSize >= 992) {
      const achievementHeight = achievementsContainerRef.current.offsetHeight;
      setSectionHeight(achievementHeight);
    }
  }, [achievementsContainerRef?.current?.offsetHeight, windowWidthSize]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidthSize(window.innerWidth);

      if (selectedBadgeContainerRef.current && slideRight) {
        const selectedBadgeHeight = selectedBadgeContainerRef.current.offsetHeight;
        const achievementHeight = achievementsContainerRef.current.offsetHeight;

        const dynamicOffset = selectedBadgeHeight + 20; // 20px gap
        const selectedBadgeWidth = selectedBadgeContainerRef.current.offsetWidth;
        const achievementWidth = achievementsContainerRef.current.offsetWidth;
        setSlidersWidth(achievementWidth - selectedBadgeWidth);
        setTransformY(dynamicOffset);
        if (windowWidthSize <= 992) {
          setSectionHeight(selectedBadgeHeight + achievementHeight);
        }
      }

      if (windowWidthSize >= 992 && !slideRight) {
        setSlidersWidth('100%');
      }

      if (selectedBadgeId === null) {
        const achievementHeight = achievementsContainerRef.current.offsetHeight;
        setSectionHeight(achievementHeight);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [slideRight, windowWidthSize, selectedBadgeId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codeId = urlParams.get('codeId');
    if (codeId === process.env.REACT_APP_TES_SEVILLE_SHOW_CODE && dataLoaded) {
      if (specialAchievementBadges?.length) {
        const showTESAffiliateSevilleAchievementBadgeIndex = specialAchievementBadges.findIndex(
          (sb) => sb.type === 'show_tes_affiliate_seville' && sb.isUnlocked
        );
        if (showTESAffiliateSevilleAchievementBadgeIndex >= 0 && !onLoadSelectedBadgeDisplayed) {
          handleBadgeClick(
            `special-${showTESAffiliateSevilleAchievementBadgeIndex}`,
            specialAchievementBadges[showTESAffiliateSevilleAchievementBadgeIndex]
          );
          setOnLoadSelectedBadgeDisplayed(true);
        }
      }
    }
  }, [dataLoaded, specialAchievementBadges, handleBadgeClick, onLoadSelectedBadgeDisplayed]);

  const currentLevel = gamificationAffProgress?.progressData.streak;
  const logoLevel = currentLevel >= MAX_LOGIN_STREAK_BADGE_LEVEL ? MAX_LOGIN_STREAK_BADGE_LEVEL : currentLevel;
  return (
    <div className="container-fluid app-container" id="achievements">
      <div className="header">
        <h1>
          Achievements
          <Info
            title="Click here for more informations on Achievements"
            url="https://support.crakrevenue.com/knowledge-base/crakrevenue-achievement-program/"
          />
        </h1>
        <AchivementsRankingBox />
      </div>
      <div className="section-container" style={{ height: sectionHeight }}>
        <SelectedBadge
          affiliateBadges={affiliateBadges}
          currentLoginStreak={currentLevel}
          gamificationBadges={loginStreakBadges}
          hasLoaded={dataLoaded}
          logoLevel={logoLevel}
          selectedAchievementBadge={selectedAchievementBadge}
          selectedBadgeContainerRef={selectedBadgeContainerRef}
          slideRight={slideRight}
          windowWidthSize={windowWidthSize}
        />

        <div
          className={`achievements-container ${
            dataLoaded && slideRight !== null ? (slideRight ? 'slide-right' : 'slide-left') : ''
          }`}
          ref={achievementsContainerRef}
          style={{
            transform: windowWidthSize <= 992 ? `translateY(${transformY}px)` : 'unset',
            transition: windowWidthSize <= 992 ? 'transform 0.7s cubic-bezier(0, 1.25, 1, 1)' : 'unset',
          }}
        >
          <AchievementBadgeSlider
            achievementBadges={streakAchievementBadges}
            affiliateBadges={affiliateBadges}
            currentLevel={currentLevel}
            dataLoaded={dataLoaded}
            firstClickedBadgeId={firstClickedBadgeId}
            handleBadgeClick={handleBadgeClick}
            loginStreakBadges={loginStreakBadges}
            logoLevel={logoLevel}
            sectionClassName="streak-section"
            selectedBadgeId={selectedBadgeId}
            slidersWidth={slidersWidth}
            title="Progressive Badges"
            uid="streak"
            windowWidthSize={windowWidthSize}
          />
          <AchievementBadgeSlider
            achievementBadges={specialAchievementBadges}
            affiliateBadges={affiliateBadges}
            dataLoaded={dataLoaded}
            firstClickedBadgeId={firstClickedBadgeId}
            handleBadgeClick={handleBadgeClick}
            logoLevel={logoLevel}
            sectionClassName="special-section"
            selectedBadgeId={selectedBadgeId}
            slideRight={slideRight}
            slidersWidth={slidersWidth}
            title="Signature Badges"
            uid="special"
            useGrid
            windowWidthSize={windowWidthSize}
          />
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  gamificationAffProgress: state.gamification.eventsData?.notification?.affiliateProgress,
  gamificationBadges: state.gamification.gamificationBadges,
  badgeDataIsFetching: state.gamification.badgeDataIsFetching,
  uniqueEventsIsFetching: state.gamification.uniqueEventsIsFetching,
  affiliateBadgesIsFetching: state.gamification.affiliateBadgesIsFetching,
  loginStreakBadges: state.gamification.loginStreakBadges,
  affiliateBadges: state.gamification.affiliateBadges,
}))(Achievements);
