import moment from 'moment';

export const getCurrentPeriod = (date, paymentTerm) => {
  let isWeekly = false;

  try {
    if (paymentTerm?.includes('Weekly')) {
      isWeekly = true;
    }
  } catch (e) {
    console.error('Error at parsing period days from payment terms:', e);
  }

  const getFormattedDate = (theDate) => theDate.startOf('day').format('YYYY-MM-DD');
  const currentDate = date ? moment(date) : moment();
  let periodStart, periodEnd;

  if (isWeekly) {
    // Determine which of the four payment periods in the month the current date falls into
    const dayOfMonth = currentDate.date();

    if (dayOfMonth >= 1 && dayOfMonth <= 7) {
      periodStart = currentDate.clone().startOf('month');
      periodEnd = periodStart.clone().add(6, 'days').endOf('day');
    } else if (dayOfMonth >= 8 && dayOfMonth <= 15) {
      periodStart = currentDate.clone().startOf('month').add(7, 'days');
      periodEnd = periodStart.clone().add(7, 'days').endOf('day');
    } else if (dayOfMonth >= 16 && dayOfMonth <= 22) {
      periodStart = currentDate.clone().startOf('month').add(15, 'days');
      periodEnd = periodStart.clone().add(6, 'days').endOf('day');
    } else {
      periodStart = currentDate.clone().startOf('month').add(22, 'days');
      periodEnd = currentDate.clone().endOf('month');
    }
  } else {
    // Bi-monthly periods: 1-15 and 16-end of the month
    periodStart = currentDate.date() <= 15 ? currentDate.clone().startOf('month') : currentDate.clone().date(16);
    periodEnd = periodStart.clone().add(14, 'days').endOf('day');
  }

  // Prevent issues on shorter month like February
  if (periodEnd > periodStart.clone().endOf('month')) {
    periodEnd = periodStart.clone().endOf('month');
  }

  return {
    dateStart: getFormattedDate(periodStart),
    dateEnd: getFormattedDate(periodEnd),
  };
};

export const getLastPeriod = (date, paymentTerm) => {
  const { dateStart } = getCurrentPeriod(date ? moment(date) : moment(), paymentTerm);
  return getCurrentPeriod(moment(dateStart).subtract(1, 'week'), paymentTerm);
};

export const getLastSevenPeriod = () => ({
  dateStart: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  dateEnd: moment().format('YYYY-MM-DD'),
});

export const getCurrentPeriodDaysLeft = (paymentTerm) => {
  const daysLeft = moment(getCurrentPeriod(undefined, paymentTerm).dateEnd).startOf('day').diff(moment().startOf('day'), 'days');
  return daysLeft >= 0 ? daysLeft : 0;
};

export const getPaymentDueDate = (paymentDate, paymentTerm) => {
  let dueDate;

  const currentPeriod = getCurrentPeriod(paymentDate, paymentTerm);
  if (paymentTerm.startsWith('Net 7')) {
    dueDate = moment(currentPeriod.dateEnd).add(7, 'days').startOf('day');
  } else if (paymentTerm.startsWith('Net 15')) {
    dueDate = moment(currentPeriod.dateEnd).add(15, 'days').startOf('day');
  } else {
    dueDate = moment(currentPeriod.dateEnd).add(1, 'months').startOf('day');
  }
  const payDays = [7, 15, 22, dueDate.clone().endOf('month').date()];

  if (!payDays.includes(dueDate.date())) {
    if (dueDate.date() > 22) {
      dueDate = moment(dueDate).endOf('month').startOf('day');
    } else if (dueDate.date() < 7) {
      dueDate = moment(dueDate).startOf('month').add(6, 'days').startOf('day');
    } else if (dueDate.date() < 15) {
      dueDate = moment(dueDate).startOf('month').add(14, 'days').startOf('day');
    } else if (dueDate.date() < 22) {
      dueDate = moment(dueDate).startOf('month').add(21, 'days').startOf('day');
    }
  }
  return dueDate;
};
