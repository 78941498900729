import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { browserHistory, Link } from 'react-router';

import messages from '../i18n/base-en.js';
import LoginErrors from '../components/Login/LoginErrors';
import Offline from '../components/Offline/Offline';

import { setLoginError } from '../components/Login/actions/login-actions';
import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';

import { getNiceToSeeYouFromSkinName } from '../utils/logoHelper';
import { getCookie } from '../utils/helpers';
import PasswordResetForm from './password-reset/PasswordResetForm.react';
import PasswordResetEmailForm from './password-reset/PasswordResetEmailForm.react';
import request from '../utils/Req';
import { setActionMessage } from '../components/Application/actions/application-actions';
import config from '../config';
import consts from '../utils/consts';
const { API_URL } = config;
const { FORCE_PASSWORD_RESET_PARAM } = consts;
const PasswordReset = ({ dispatch, error, location, skinConfigurations, application }) => {
  const SKIN = new SkinConfigurationsUtils(skinConfigurations);
  const SKIN_NAME = SKIN.getSkinName().toLowerCase();

  const loginError = error || (application.ui.actionMessage.status === 'error' ? application.ui.actionMessage.textObj.text : '');
  const [isDisabled, setIsDisabled] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const forcePasswordReset = urlParams.get(FORCE_PASSWORD_RESET_PARAM);

  const handlePasswordResetEmailSubmit = (data) => {
    setIsDisabled(true);
    request
      .post(`${API_URL}/reset-password`)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .send({
        email: data.email,
      })
      .withCredentials()
      .end((err, res) => {
        if (res.body || err) {
          dispatch(setLoginError(messages.passwordResetConfirmation.defaultMessage));
        }
        setIsDisabled(false);
      });
  };

  const handlePasswordResetSubmit = (data) => {
    if (data.password === data.passwordConfirmation) {
      setIsDisabled(true);
      request
        .post(`${API_URL}/change-password`)
        .set({
          'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
        })
        .send({
          password: data.password,
          code: location.query.c,
        })
        .end((err, res) => {
          if (err) {
            dispatch(setLoginError('An error occured'));
          }

          if (res.body.success) {
            dispatch(
              setActionMessage(
                'success',
                {
                  text: 'Your password has been changed, you can log in with this new password.',
                },
                3000
              )
            );

            setTimeout(() => {
              browserHistory.push('/login');

              setIsDisabled(false);
            }, 4000);
          } else {
            dispatch(setLoginError(res.body.errorMessage));
            setIsDisabled(false);
          }
        });
    }
  };

  return (
    <Offline
      isLoginPage
      link={{
        url: '/login',
        text: <FormattedMessage {...messages.loginPageGoBackToLogin} />,
      }}
    >
      <div className="row" id="password-reset-page">
        <div
          className="hidden-xs image-container text-center image-login"
          style={{
            backgroundImage: SKIN_NAME !== 'crakrevenue' && `url(${getNiceToSeeYouFromSkinName(SKIN_NAME)})`,
          }}
        />
        <div className="text-container">
          {typeof location.query.c !== 'undefined' && (
            <PasswordResetForm dispatch={dispatch} isDisabled={isDisabled} onSubmit={handlePasswordResetSubmit} />
          )}
          {typeof location.query.c === 'undefined' && (
            <PasswordResetEmailForm
              dispatch={dispatch}
              forcePasswordReset={forcePasswordReset}
              isDisabled={isDisabled}
              onSubmit={handlePasswordResetEmailSubmit}
            />
          )}
          <LoginErrors data-cy="error-login-form" dispatch={dispatch} error={loginError} showErrorTime={6000} />
        </div>
      </div>
    </Offline>
  );
};

PasswordReset.propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.any,
  isFetchingAuth: PropTypes.bool.isRequired,
  isFetchingTfaValidation: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  'route.path': PropTypes.any,
  skinConfigurations: PropTypes.object.isRequired,
};

export default connect((state) => ({
  application: state.application,
  error: state.login.error,
  skinConfigurations: state.skinConfigurations.data,
}))(PasswordReset);
