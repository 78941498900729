import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  CRAKANNIVERSARY_TYPE,
  LOGIN_STREAK_TYPE,
  PAYOUT_MILESTONE_TYPE,
  REFERRAL_QUALIFIED_COUNT_TYPE,
  getCrakanniversaryYears,
} from '../../utils/gamificationHelper';

const ProgressBar = ({
  allBadges,
  affInfos,
  badge,
  logoLevel = 0,
  gamificationBadges,
  affiliateBadges,
  payoutTotal,
  isSelected = false,
  isUpcoming = false,
}) => {
  if (!gamificationBadges?.length) {
    return null;
  }
  let progress = 0;
  let nextPayoutValue = 0;
  let progressBarLogoLevel = badge?.level >= 16 ? 16 : badge?.level;
  let nextBadge = allBadges?.find((coldBadge) => badge?.type === coldBadge?.type && coldBadge?.level === badge?.level + 1);
  let relatedAffiliateBadge = affiliateBadges?.find((affBadge) => badge?.id === affBadge?.badge?.id);
  let leftIconSrc = '';
  let rightIconSrc = '';

  switch (badge?.type) {
    case LOGIN_STREAK_TYPE: {
      const today = new Date();
      const sunday = new Date(moment().startOf('week').toDate());
      const diffTime = Math.abs(sunday - today);
      progress = (Math.floor(diffTime / (1000 * 60 * 60 * 24)) / 7) * 100;
      progressBarLogoLevel = logoLevel;
      break;
    }
    case PAYOUT_MILESTONE_TYPE: {
      const parsedPayoutTotal = parseFloat(payoutTotal).toFixed(2);
      const lastPayoutMilestone = badge?.badgeData?.value;
      const payoutMilestoneBadges = allBadges?.filter((badge) => badge?.type === PAYOUT_MILESTONE_TYPE);
      nextPayoutValue = payoutMilestoneBadges?.find((payoutMilestone) => payoutMilestone?.level === badge?.level + 1)?.badgeData
        ?.value;
      if (nextPayoutValue < parsedPayoutTotal || badge?.level >= 16) {
        progress = 100;
      } else if (parsedPayoutTotal === 'NaN') {
        progress = 0;
      } else {
        progress = ((parsedPayoutTotal - (lastPayoutMilestone ?? 0)) / (nextPayoutValue - (lastPayoutMilestone ?? 0))) * 100;
      }
      break;
    }
    case REFERRAL_QUALIFIED_COUNT_TYPE: {
      progress = 0;
      const lastReferralQualifiedCount = badge?.badgeData?.value;
      const nextReferralQualifiedCount = nextBadge?.badgeData?.value;
      const currentReferralQualifiedCount = relatedAffiliateBadge?.affiliateProgress?.progressData?.qualifiedCount;
      if (!nextReferralQualifiedCount || nextReferralQualifiedCount <= currentReferralQualifiedCount) {
        progress = 100;
      } else {
        progress =
          ((currentReferralQualifiedCount - (lastReferralQualifiedCount ?? 0)) /
            (nextReferralQualifiedCount - (lastReferralQualifiedCount ?? 0))) *
          100;
      }
      break;
    }
    case CRAKANNIVERSARY_TYPE: {
      progress = 0;
      const dateAdded = !relatedAffiliateBadge
        ? new Date(affInfos?.date_added)
        : new Date(relatedAffiliateBadge?.affiliateProgress?.progressData?.dateAdded);
      if (dateAdded) {
        const lastAnniversaryDate = new Date(
          dateAdded.getFullYear() + Math.floor((new Date() - dateAdded) / (365.25 * 24 * 60 * 60 * 1000)),
          dateAdded.getMonth(),
          dateAdded.getDate()
        );
        const timeSinceLastCrakanniversary = new Date().getTime() - lastAnniversaryDate.getTime();
        const progressDays = timeSinceLastCrakanniversary / (24 * 60 * 60 * 1000);
        progress = (progressDays / 365) * 100;
      }
      break;
    }
  }
  if (!isUpcoming) {
    if ([REFERRAL_QUALIFIED_COUNT_TYPE].includes(badge?.type)) {
      const currentBadgeLevel = badge?.level;
      const nextBadgeLevel = nextBadge?.level ?? currentBadgeLevel;
      const leftLogoIndex = currentBadgeLevel && progressBarLogoLevel < 4 ? currentBadgeLevel * 4 - 1 : 0;
      const rightLogoIndex = nextBadgeLevel * 4 - 1;
      leftIconSrc = leftLogoIndex ? gamificationBadges[leftLogoIndex].badgeData.whaleUrl : '';
      rightIconSrc = rightLogoIndex ? gamificationBadges[rightLogoIndex].badgeData.whaleUrl : '';
    } else if (badge?.type === CRAKANNIVERSARY_TYPE) {
      leftIconSrc = 'yearOfService';
      rightIconSrc = nextBadge?.badgeData?.whaleUrl;
    } else {
      leftIconSrc =
        progressBarLogoLevel && progressBarLogoLevel < 16 ? gamificationBadges[progressBarLogoLevel - 1].badgeData.whaleUrl : '';
      rightIconSrc =
        gamificationBadges[progressBarLogoLevel >= 16 ? progressBarLogoLevel - 1 : progressBarLogoLevel].badgeData.whaleUrl;
    }
  }

  const whaleClassName = (img) => {
    if (badge?.type === CRAKANNIVERSARY_TYPE) {
      return 'crakanniversary-badge';
    } else if (img.includes('2')) {
      return 'lvl-2';
    } else if (img.includes('3')) {
      return 'lvl-3';
    } else if (img.includes('4')) {
      return 'lvl-4';
    }
    return 'lvl-1';
  };

  function formatNumber(num) {
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'; // Format millions
    }
    if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'k'; // Format thousands
    }
    return num.toString(); // Return the number as is if < 1000
  }

  return (
    <>
      <div className="progress-bar-box">
        {leftIconSrc && !isUpcoming ? (
          <div className="left-icon-box">
            {badge?.type === CRAKANNIVERSARY_TYPE ? (
              <div className="crakanniversary-badge">{getCrakanniversaryYears(relatedAffiliateBadge)}</div>
            ) : (
              <img
                className={`${whaleClassName(leftIconSrc)} ${whaleClassName(leftIconSrc) === 'lvl-4' ? 'left-lvl-4' : ''}`}
                src={leftIconSrc}
              />
            )}
          </div>
        ) : null}
        <div
          className={`progress-bar ${leftIconSrc ? 'with-left-icon' : ''} ${rightIconSrc ? 'with-right-icon' : ''} ${
            isUpcoming ? 'upcoming-progress-bar' : ''
          }`}
        >
          {!isUpcoming ? (
            <div
              className={`bar ${leftIconSrc ? 'with-left-icon' : ''} ${rightIconSrc ? 'with-right-icon' : ''}`}
              style={{ width: `${!progress || isNaN(progress) ? 0 : progress}%` }}
            />
          ) : null}
        </div>
        {!isUpcoming ? (
          <div className="right-icon-box">
            {badge?.type === CRAKANNIVERSARY_TYPE ? (
              <div className="crakanniversary-badge">{getCrakanniversaryYears(relatedAffiliateBadge) + 1}</div>
            ) : (
              <img className={whaleClassName(rightIconSrc)} src={rightIconSrc} />
            )}
          </div>
        ) : null}
      </div>
      {badge?.type === 'payout_milestone' && isSelected ? (
        <div className="progress-info">
          ${new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(payoutTotal)}{' '}
          {nextPayoutValue ? `/ $${formatNumber(nextPayoutValue)}` : ''} · {parseFloat(progress).toFixed(0)}%
        </div>
      ) : null}
    </>
  );
};

export default connect((state) => ({
  affRank: state.profile.data.affRank,
  payoutTotal: state.payoutTotal.payoutTotal,
  allBadges: state.gamification.gamificationBadges,
  affiliateBadges: state.gamification.affiliateBadges,
  affInfos: state.profile.data.affInfos,
}))(ProgressBar);
