import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import messages from '../../i18n/base-en';
import { InputText } from '../../components/reusables';
import { postAffiliateBeneficiary, putAffiliateBeneficiary } from '../../actions/affiliate-beneficiary-actions';
import validate from '../../pages/profile/validateBeneficiaryForm';
import { Gtm } from '../../utils/gtm';
import ActionMessageHelper, { BILLING_INFOS_SUCCESS } from '../../utils/ActionMessageHelper';
import { useConfirmationOnFields } from '../reusables/useConfirmationOnFields';
import { withRouter } from 'react-router';
import { getCountryLabelDefaultMessage } from '../../utils/list-options/countries';
import _ from 'lodash';
import 'materialize-css';
import { useEffect, useMemo, useState } from 'react';
import BeneficiaryInfoAutoSuggestionAddressReact from '../../pages/profile/billing-infos/BeneficiaryInfoAutoSuggestionAddress.react';
import { ADDRESS_MODE, addressMode, buildAddressLabel } from '../../utils/addressHelper';
import BeneficiaryAddressSection from './BeneficiaryAddressSection.react';
const PersonBeneficiary = ({
  beneficiaryInfos,
  errors,
  fields,
  fields: {
    name,
    city,
    province,
    state,
    region,
    country,
    postalCode,
    address1,
    address2,
    address3,
    address4,
    address5,
    apartment_suite_unit_building,
  },
  dispatch,
  invalid,
  parentRoute,
  router,
  setBeneficiaryInvalid,
  submitRef,
}) => {
  const countryLabelDefaultMessage = getCountryLabelDefaultMessage(country.initialValue);
  country.value = countryLabelDefaultMessage;
  const [currentBeneficiaryValues, setCurrentBeneficiaryValues] = useState(fields._meta.values);
  const [isAddressModeSearchTool, setIsAddressModeSearchTool] = useState(true);
  const [isFetchingAddressData, setIsFetchingAddressData] = useState(false);
  const [isSuggestedAddressComplete, setIsSuggestedAddressComplete] = useState(undefined);
  const [inviteUserToUseAddressTool, setInviteUserToUseAddressTool] = useState(true);

  const [addressAnimation, setAddressAnimation] = useState({
    fadeIn: addressMode(isAddressModeSearchTool),
    fadeOut: null,
  });

  const { fadeIn, fadeOut } = addressAnimation;

  const startSetIsAddressModeSearchTool = (isSearchMode) => {
    if (isSearchMode === isAddressModeSearchTool) {
      return;
    }

    setAddressAnimation((state) => ({
      ...state,
      fadeOut: state.fadeIn,
    }));

    setTimeout(() => {
      setIsAddressModeSearchTool(isSearchMode);
    }, 300);
  };

  const newValuesToSubmit = useMemo(
    () =>
      !_.isEqual(
        {
          ...currentBeneficiaryValues,
          address_label: undefined,
          autocompleted_address_label: undefined,
        },
        {
          ...fields._meta.values,
          address_label: undefined,
          autocompleted_address_label: undefined,
        }
      ),
    [currentBeneficiaryValues, fields._meta.values]
  );

  useEffect(() => {
    if (isSuggestedAddressComplete !== undefined) {
      if (!isSuggestedAddressComplete || (isSuggestedAddressComplete && invalid && !errors.address_label)) {
        if (inviteUserToUseAddressTool) {
          setInviteUserToUseAddressTool(false);
        }
        if (isAddressModeSearchTool) {
          startSetIsAddressModeSearchTool(false);
        }
      }
    }
  }, [errors.address_label, invalid, isSuggestedAddressComplete, setInviteUserToUseAddressTool]);

  useEffect(() => {
    if (country.value) {
      if (isAddressModeSearchTool) {
        Gtm.event('billing - beneficiary person', 'Click', `use address validation tool - ${country.value}`);
      } else {
        Gtm.event('billing - beneficiary person', 'Click', `customize address manually - current country ${country.value}`);
      }
    }
  }, [isAddressModeSearchTool, country.value, isSuggestedAddressComplete]);

  const getSubdivisionValue = () => {
    switch (country.initialValue) {
      case 'CA':
        return province.value;
      case 'US':
        return state.value;
      default:
        return region.value;
    }
  };

  const handleSubmitPerson = () => {
    const affiliateBeneficiary = {
      name: name.initialValue,
      country_code: country.initialValue,
      subdivision: getSubdivisionValue(),
      postal_code: postalCode.value || null,
      city: city.value || null,
      address_line1: address1.value,
      address_line2: address2.value || null,
      address_line3: address3.value || null,
      address_line4: address4.value || null,
      address_line5: address5.value || null,
      apartment_suite_unit_building: apartment_suite_unit_building.value || null,
    };

    if (!beneficiaryInfos) {
      dispatch(postAffiliateBeneficiary(affiliateBeneficiary)).then(() => {
        ActionMessageHelper.showSuccess(dispatch, BILLING_INFOS_SUCCESS);
      });
    } else {
      dispatch(putAffiliateBeneficiary(affiliateBeneficiary)).then(() => {
        ActionMessageHelper.showSuccess(dispatch, BILLING_INFOS_SUCCESS);
      });
    }
    setIsSuggestedAddressComplete(undefined);

    Gtm.event('profile - billing information', 'Click', `Save changes - Beneficiary`);
  };

  const watchedItems = {
    city,
    province,
    state,
    region,
    postalCode,
    address1,
    address2,
    address3,
    address4,
    address5,
  };

  useConfirmationOnFields({
    router,
    route: parentRoute,
    items: watchedItems,
    alertMessage: messages.profileSettingsUnsavedChecked.description,
  });

  country.value = getCountryLabelDefaultMessage(country.initialValue);

  useEffect(() => {
    setBeneficiaryInvalid(invalid);
  }, [invalid, setBeneficiaryInvalid]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAddressAnimation((state) => ({
        ...state,
        fadeIn: addressMode(isAddressModeSearchTool),
      }));
    }, 2);
    return () => {
      clearTimeout(timeout);
    };
  }, [isAddressModeSearchTool]);

  return (
    <div className="company-validate">
      <div className="beneficiary-input">
        <InputText disabled field={name} id="name" label={messages.genericTextName} labelClasses="" type="text" />
      </div>
      <div className="beneficiary-input">
        <InputText
          disabled
          displayErrorInstantly
          field={country}
          id="country-id"
          label={messages.genericTextCountry}
          type="text"
        />
      </div>

      {isAddressModeSearchTool ? (
        <div
          className={`valid-address-information-box ${fadeIn === ADDRESS_MODE.SEARCH ? 'fadeIn' : ''} ${
            fadeOut === ADDRESS_MODE.SEARCH ? 'fadeOut' : ''
          }`}
        >
          <BeneficiaryInfoAutoSuggestionAddressReact
            fields={fields}
            setIsAddressModeSearchTool={startSetIsAddressModeSearchTool}
            setIsFetchingAddressData={setIsFetchingAddressData}
            setIsSuggestedAddressComplete={setIsSuggestedAddressComplete}
          />
          {fields.address1.value ? (
            <div className="beneficiary-input">
              <InputText
                displayErrorInstantly
                field={apartment_suite_unit_building}
                id="address-apt-suite"
                label={{
                  ...messages.genericTextAddressAptSuite,
                }}
                type="text"
              />
            </div>
          ) : null}
        </div>
      ) : (
        <div
          className={`valid-address-information-box${fadeIn === ADDRESS_MODE.MANUAL ? 'fadeIn' : ''} ${
            fadeOut === ADDRESS_MODE.MANUAL ? 'fadeOut' : ''
          }`}
        >
          <BeneficiaryAddressSection extendedAddressLines fields={fields} />
          <div className="valid-address-information">
            <p>
              Warning: Your details must be accurate and complete for timely payments and a solid partnership. Please verify
              before submitting.
              {inviteUserToUseAddressTool ? (
                <>
                  <br />
                  If you’re not sure about the validity of your address, use our{' '}
                  <span onClick={() => startSetIsAddressModeSearchTool(true)}>address search tool</span>.
                </>
              ) : null}
            </p>
          </div>
        </div>
      )}

      <div className="form-buttons">
        <button
          className="waves-effect waves-light btn bg-primary-color"
          disabled={invalid || !newValuesToSubmit || isFetchingAddressData}
          onClick={() => {
            handleSubmitPerson();
            setCurrentBeneficiaryValues(fields._meta.values);
          }}
          ref={submitRef}
        >
          Save changes
        </button>
      </div>
    </div>
  );
};

export default connect((state) => ({
  beneficiaryInfos: state.profile.data?.affiliateBeneficiary,
  accountType: state.profile.data.customProfile.affiliate.affiliate_type?.type,
  affCompany: state.profile?.data?.affCompany,
}))(
  withRouter(
    reduxForm(
      {
        form: 'personBeneficiaryInfos',
        touchOnChange: false,
        touchOnBlur: false,
        fields: [
          'name',
          'country',
          'province',
          'state',
          'region',
          'city',
          'address1',
          'address2',
          'address3',
          'address4',
          'address5',
          'apartment_suite_unit_building',
          'postalCode',
          'address_label',
          'autocompleted_address_label',
        ],
        validate,
      },
      (state) => {
        const addressLabel = buildAddressLabel({
          address: state.profile.data.affiliateBeneficiary?.address_line1 || '',
          city: state.profile.data.affiliateBeneficiary?.city || '',
          region: state.profile.data.affiliateBeneficiary?.subdivision || '',
          postalCode: state.profile.data.affiliateBeneficiary?.postal_code || '',
          country: getCountryLabelDefaultMessage(state.profile.data.affiliateBeneficiary?.country_code),
        });
        return {
          initialValues: {
            name: state.profile.data.affiliateBeneficiary?.name,
            country: state.profile.data.affiliateBeneficiary?.country_code,
            state: state.profile.data.affiliateBeneficiary?.subdivision,
            province: state.profile.data.affiliateBeneficiary?.subdivision,
            region: state.profile.data.affiliateBeneficiary?.subdivision,
            address1: state.profile.data.affiliateBeneficiary?.address_line1 || null,
            address2: state.profile.data.affiliateBeneficiary?.address_line2 || null,
            address3: state.profile.data.affiliateBeneficiary?.address_line3 || null,
            address4: state.profile.data.affiliateBeneficiary?.address_line4 || null,
            address5: state.profile.data.affiliateBeneficiary?.address_line5 || null,
            apartment_suite_unit_building: state.profile.data.affiliateBeneficiary?.apartment_suite_unit_building || null,
            postalCode: state.profile.data.affiliateBeneficiary?.postal_code || null,
            city: state.profile.data.affiliateBeneficiary?.city || null,
            address_label: addressLabel,
          },
        };
      }
    )(PersonBeneficiary)
  )
);
