import { browserHistory } from 'react-router';

import { fetchNewAlerts } from '../components/Application/actions/alerts-actions';
import {
  addCompletedFormToApp,
  fetchAllCountries,
  fetchAllTimezones,
  fetchBestTimesToCall,
  fetchMinimumPayouts,
  fetchOffersCategories,
  fetchPaymentMethods,
  fetchPaymentMethodsV2,
  fetchSalutations,
  fetchSearchFields,
  fetchValidationStatus,
  fetchAffiliateTypes,
  fetchAllOffers,
} from '../components/Application/actions/application-actions';

import {
  fetchDailyStatistics,
  fetchFeaturedOffers,
  fetchLastPeriodPayouts,
  fetchCurrentPeriodPayouts,
} from '../components/Home/actions/home-actions';
import { fetchBlogPosts } from '../components/Home/actions/blog-actions';
import { fetchPromo } from '../components/Home/actions/promo-actions';
import { fetchNews } from '../components/Home/actions/news-actions';

import { login, logout } from '../components/Login/actions/login-actions';

import { fetchTopRecentOffers } from '../components/Offers/actions/topoffers-actions.js';
import { fetchOffersSlides } from '../components/Offers/actions/offers-slides-actions.js';

import {
  fetchProfileCompleteVerification,
  fetchPaymentCompleteVerification,
} from '../components/Profile/actions/profile-completed-actions';

import { decryptString, getLocalStorageValue, setCookie, getCookie } from '../utils/helpers';
import intercom from '../utils/intercom';
import config from '../config';
import consts from '../utils/consts';
import store from '../create-store';
import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';
import { fetchProfile } from '../actions/profile-actions';
import { fetchJerkmateOffers } from '../pages/jerkmate/Jerkmate-actions/Jerkmate-actions';
import { fetchPayoutTotal } from '../actions/payout-total-actions';
import {
  fetchGamificationAffiliateBadges,
  fetchGamificationBadge,
  postGamificationEvents,
} from '../actions/gamification-actions';
import { LOGIN_EVENT_TYPE, SHOW_TES_AFFILIATE_EVENT_TYPE } from './gamificationHelper.js';
import { fetchPaymentHistory } from '../actions/payment-history-actions.js';

const { OFFER_AUDIENCES } = config;
const { TOP_OFFERS_OPTIONS } = consts;

export const initialFetching = (dispatch) =>
  new Promise((fulfill) => {
    Promise.all([
      dispatch(fetchOffersCategories()),
      dispatch(fetchSalutations()),
      dispatch(fetchBestTimesToCall()),
      dispatch(fetchValidationStatus()),
      dispatch(fetchAffiliateTypes()),
      dispatch(fetchPaymentMethods()),
      dispatch(fetchMinimumPayouts()),
      dispatch(fetchAllTimezones()),
      dispatch(fetchAllCountries()),
      dispatch(fetchAllOffers(consts.OFFERS_FIND_ALL)),
      dispatch(fetchJerkmateOffers()),
    ])
      .then(fulfill)
      .catch((e) => {
        fulfill(e);
      });
  });

/**
 * Lance les action qui ne peuvent qu'être lancé par le client
 * @param dispatch
 * @param state
 */
export const initialDefering = (dispatch, state) =>
  new Promise((fulfill) => {
    const promises = [];
    const regforms = global.localStorage.getItem('regforms');

    // Ajoute le formulaire enregistrer dans le localStorage dans l'application
    if (regforms && !state.application.savedForm.connectionInfos) {
      promises.push(decryptString(regforms).then((obj) => dispatch(addCompletedFormToApp(obj))));
    }

    Promise.all(promises).then(fulfill).catch(fulfill);
  });

export const loggedInFetching = (profile, dispatch, location) =>
  new Promise((fulfill) => {
    const promises = [];

    dispatch(login());
    initialFetching(dispatch);

    if (window.dataLayer) {
      window.dataLayer.push({
        affId: profile.affUserInfos ? profile.affUserInfos.affiliate_id : '',
        userScope: profile.isOverrided ? 'override' : 'login',
      });
    }

    const forcePasswordReset = profile?.affUserInfos?.force_password_reset;

    const isOverride = profile.isOverrided || getLocalStorageValue('isOverrided');

    const urlReferrer = decodeURIComponent(getCookie('urlReferrer'));
    setCookie('urlReferrer', null, -1);

    if (forcePasswordReset && !isOverride) {
      dispatch(logout({ forcePasswordReset: true }));
      return;
    }

    const state = store.getState();
    const isMainstream = state.profile.data.settings && state.profile.data.settings.general === '1';

    if (!!urlReferrer && urlReferrer !== 'null') {
      browserHistory.push(urlReferrer);
    } else if (location.path) {
      browserHistory.push(location.path);
    }

    if (!isOverride) {
      const SKIN_NAME = SkinConfigurationsUtils.getSkinName(state.skinConfigurations.data).toLowerCase();
      intercom.boot(profile, SKIN_NAME);
    }

    const shortCategoriesListIds = state.application.lists.offersCategories
      .filter((cat) => TOP_OFFERS_OPTIONS.categories.indexOf(cat.name) >= 0)
      .map((cat) => cat.id);

    const timezone = state.application.lists.timezones.find((tz) => tz.id === state.profile.data.customProfile.user.time_zone_id);
    promises.push([
      dispatch(
        postGamificationEvents({
          type: LOGIN_EVENT_TYPE,
          isOverride: !!isOverride,
        })
      ),
      dispatch(fetchGamificationBadge()),
      dispatch(fetchGamificationAffiliateBadges()),
      dispatch(fetchFeaturedOffers(isMainstream ? OFFER_AUDIENCES.offerAudienceOffersG : [])),
      dispatch(
        fetchTopRecentOffers({
          categories: { Vertical: shortCategoriesListIds },
        })
      ),
      dispatch(fetchNewAlerts({ isDismissed: 0 })),
      dispatch(fetchDailyStatistics(null, timezone ? timezone.offset || 0 : 0)),
      dispatch(fetchProfile()),
      dispatch(fetchProfileCompleteVerification()),
      dispatch(fetchPaymentCompleteVerification()),
      dispatch(fetchSearchFields()),
      dispatch(fetchCurrentPeriodPayouts()),
      dispatch(fetchPaymentHistory()),
      dispatch(fetchLastPeriodPayouts()),
      dispatch(fetchPaymentMethodsV2()),
      dispatch(fetchPayoutTotal()),
      dispatch(
        fetchBlogPosts(
          isMainstream
            ? {
                filters: JSON.stringify({
                  category: { exclude: 'Adult' },
                }),
              }
            : null
        )
      ),
      dispatch(fetchPromo(isMainstream ? 'mainstream-promo' : 'promo')),
      dispatch(fetchNews()),
      dispatch(fetchOffersSlides()),
    ]);

    const isShowTESaffiliateSeville = getCookie('codeId') === process.env.REACT_APP_TES_SEVILLE_SHOW_CODE;
    if (isShowTESaffiliateSeville) {
      const showTESTAffiliateSevilleGamificationEvent = {
        type: SHOW_TES_AFFILIATE_EVENT_TYPE,
        data: { year: '2025', location: 'seville' },
        isOverride: !!isOverride,
      };
      dispatch(postGamificationEvents(showTESTAffiliateSevilleGamificationEvent));
    }
    Promise.all(promises).then(fulfill).catch(fulfill);
  });
