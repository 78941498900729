import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PersonForm from '../../../components/Profile/PersonForm.react';
import CompanyForm from '../../../components/Profile/CompanyForm.react';
import { AffiliateTypeEnum } from '../../../enum/AffiliateTypeEnum';
import PersonnalInfo from '../user-infos/svg/PersonnalInfo.react';
import { ProfileSectionContainer } from '../../../components/reusables/ProfileSectionContainer.react';
import messages from '../../../i18n/base-en';

const BeneficarySection = ({
  accountType,
  dispatch,
  affCompany,
  validationStatus,
  listValidationStatus,
  parentRoute,
  personBeneficiarySubmitRef,
  companyBeneficiarySubmitRef,
  isLoading,
}) => {
  const [beneficiarySectionIsOpen, setBeneficiarySectionIsOpen] = useState(false);
  const [beneficiaryInvalid, setBeneficiaryInvalid] = useState(false);

  useEffect(() => {
    listValidationStatus.filter((status) => status.id === validationStatus?.validation_status_id);
  }, [validationStatus?.validation_status_id]);

  return (
    <ProfileSectionContainer
      iconComponent={<PersonnalInfo />}
      invalid={beneficiaryInvalid}
      isLoading={isLoading}
      isOpen={beneficiarySectionIsOpen}
      lockedData={{}}
      sectionSubtitle={
        accountType?.toUpperCase() === AffiliateTypeEnum.COMPANY
          ? messages.profileBeneficiaryCompanyInformationText.defaultMessage
          : messages.profileBeneficiaryPersonInformationText.defaultMessage
      }
      sectionTitleAsFormattedMessage={messages.profileBeneficiaryInformationTitle}
      setIsOpen={setBeneficiarySectionIsOpen}
      wrapperClass="profile-infos"
    >
      {accountType?.toUpperCase() !== AffiliateTypeEnum.COMPANY && (
        <PersonForm
          dispatch={dispatch}
          setBeneficiaryInvalid={setBeneficiaryInvalid}
          parentRoute={parentRoute}
          submitRef={personBeneficiarySubmitRef}
        />
      )}

      {accountType?.toUpperCase() === AffiliateTypeEnum.COMPANY && (
        <CompanyForm
          affCompany={affCompany}
          dispatch={dispatch}
          parentRoute={parentRoute}
          setBeneficiaryInvalid={setBeneficiaryInvalid}
          submitRef={companyBeneficiarySubmitRef}
        />
      )}
    </ProfileSectionContainer>
  );
};

export default connect((state) => ({
  listValidationStatus: state?.application?.lists?.validationStatus,
  validationStatus: state?.profile?.data?.affValidationStatus,
  accountType: state?.profile?.data?.customProfile?.affiliate?.affiliate_type?.type,
  affCompany: state?.profile?.data?.affCompany,
}))(BeneficarySection);
