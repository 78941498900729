import { Route, Redirect, IndexRoute, IndexRedirect, browserHistory } from 'react-router';
import qs from 'qs';
import messages from './i18n/base-en.js';

import { getLocalStorageValue, setCookie } from './utils/helpers';
import { loggedInFetching } from './utils/initialFetching';
import { fetchProfile } from './actions/profile-actions';
import { showApplication } from './components/Application/actions/application-actions';

import App from './components/App.react';
import Override from './pages/Override.react';

import SimplifiedRegistration from './pages/SimplifiedRegistration.react';
import CreateAccount from './pages/access/CreateAccount/CreateAccountContainer/CreateAccountContainer.react';
import ConfirmEmail from './pages/access/ConfirmEmail/ConfirmEmailContainer/ConfirmEmailContainer.react';
import BusinessSignupFlowContainer from './pages/access/BusinessSignupFlow/BusinessSignupFlowContainer.react';
import AccountRegistered from './pages/access/AccountRegistered/AccountRegistered.react';
import LoginSplash from './pages/access/LoginSplashContainer/LoginSplashContainer.react';

import Login from './pages/Login';
import SmartLink from './pages/SmartLink.react';
import OffersPage from './pages/OffersPage.react';
import Offer from './pages/offer/Offer';
import Home from './pages/Home.react';
import Statistics from './pages/Statistics.react';
import Referrals from './pages/Referrals.react';

import PaymentHistory from './pages/PaymentHistory.react';

import Profile from './pages/Profile.react';
import ProfileUserInfos from './pages/profile/UserInfosContainer.react';
import Identity from './pages/profile/Identity.react';
import ProfileBillingInfos from './pages/profile/BillingInfosContainer.react';
import ProfileEmailPreferences from './pages/profile/EmailPreferencesContainer.react';
import ProfileNotifications from './pages/profile/Notifications.react';

import SurveyGenerator from './pages/SurveyGenerator.react';
import TemplateOptions from './pages/survey-generator/TemplateOptions.react';
import BasicConfiguration from './pages/survey-generator/BasicConfiguration.react';
import RewardOptions from './pages/survey-generator/RewardOptions.react';

import LiveCamWidget from './pages/LiveCamWidget.react';
import {
  LiveCamWidgetCustomCssSettings,
  LiveCamWidgetInfobarSettings,
  LiveCamWidgetLayoutSettings,
  LiveCamWidgetTemplateSettings,
  LiveCamWidgetThumbnailsSettings,
  LiveCamWidgetTypeSettings,
} from './pages/live-cam-widget';

import DecemberChallenge from './pages/others/DecemberChallenge.react';
import MarchChallenge from './pages/others/MarchChallenge.react';

import PromoTools from './pages/PromoTools/PromoTools';
import Jerkmate2 from './pages/jerkmate2/Jerkmate2.js';
import VastPreRoll from './pages/vast-pre-roll/VastPreRoll.react.js';
import { VAST_PREROLL_PATH } from './pages/vast-pre-roll/values.js';
import Achievements from './pages/Achievements.react';
import PasswordReset from './pages/PasswordReset';

let authInfoFetch = false;

const scrollTop = () => {
  if (typeof document !== 'undefined' && document.location.search.indexOf('?') === -1) {
    document.body.scrollIntoView(true);
  }
};

const Routes = (store) => {
  const canAccess = () => {
    const { dispatch } = store;
    dispatch(showApplication());
    scrollTop();
  };

  const redirectToLogin = (nextState, replace) => {
    const urlPath = nextState.location.pathname;
    let urlReferrer =
      Object.keys(nextState.location.query).length > 0 ? `${urlPath}?${qs.stringify(nextState.location.query)}` : urlPath;

    if (nextState.location.hash) {
      urlReferrer += nextState.location.hash;
    }
    setCookie('urlReferrer', urlReferrer, 1);
    if (browserHistory) {
      replace('/login');
    }
  };

  const requireAuth = async (nextState, replace, callback) => {
    const { dispatch, getState } = store;

    const isAuthenticated = getLocalStorageValue('isConnected');
    if (!isAuthenticated) {
      redirectToLogin(nextState, replace);
      callback();
      return;
    }

    if (!authInfoFetch) {
      authInfoFetch = true;
      await dispatch(fetchProfile())
        .then(() => {
          const newState = getState();
          const fetchingPromises = [
            loggedInFetching(newState.profile.data, dispatch, {
              query: nextState.location.query,
            }).then(() => {
              replace('/');
            }),
          ];
          Promise.all(fetchingPromises);
        })
        .catch(() => {
          redirectToLogin(nextState, replace);
        });
    }

    canAccess();
    callback();
  };

  return (
    <Route component={App} path="/" title={messages.crakrevenue}>
      <IndexRoute component={Home} onEnter={requireAuth} title={messages.MainNavigation.items.dashboard.text} />

      <Route component={Login} onEnter={canAccess} path="login" title={messages.loginPageTitle} />
      <Route component={Override} onEnter={canAccess} path="override" title={messages.statisticsTitle} />
      <Route
        component={Override}
        onEnter={canAccess}
        path="override(?email=:email&userId=:userId)"
        title={messages.statisticsTitle}
      />

      <Route component={SimplifiedRegistration} onEnter={canAccess} path="access" title={messages.createAccount}>
        <Route component={CreateAccount} onEnter={canAccess} path="create-account" title={messages.createAccount} />
        <Route component={ConfirmEmail} onEnter={canAccess} path="confirm-email" />
        <Route component={BusinessSignupFlowContainer} onEnter={canAccess} path="about" />
        <Route component={AccountRegistered} onEnter={canAccess} path="registered" />
        <Route component={LoginSplash} onEnter={canAccess} path="connection" />
      </Route>
      <Route component={PasswordReset} onEnter={canAccess} path="password-reset" />
      <Route component={PasswordReset} onEnter={canAccess} path="password-reset?c=:code" />

      <Route component={Statistics} onEnter={requireAuth} path="statistics" title={messages.statisticsTitle} />

      <Route path="offers(?search=:search)" title={messages.genericTextOffers}>
        <IndexRoute component={OffersPage} onEnter={requireAuth} title={messages.genericTextOffers} />
        <Route component={Offer} onEnter={requireAuth} path="/offers/:id" title={messages.genericTextOffers} />
        <Route component={Offer} onEnter={requireAuth} path="/offers(?vertical=:vertical)" title={messages.genericTextOffers} />
      </Route>

      <Route component={LiveCamWidget} path="live-cam-widget" title={messages.LiveCamWidgetPage.title.text}>
        <IndexRoute
          component={LiveCamWidgetTypeSettings}
          onEnter={requireAuth}
          title={messages.LiveCamWidgetPage.typeSettings.title.text}
        />
        <Route
          component={LiveCamWidgetTemplateSettings}
          onEnter={requireAuth}
          path="template-settings"
          title={messages.LiveCamWidgetPage.templateSettings.title.text}
        />
        <Route
          component={LiveCamWidgetLayoutSettings}
          onEnter={requireAuth}
          path="layout-settings"
          title={messages.LiveCamWidgetPage.layoutSettings.title.text}
        />
        <Route
          component={LiveCamWidgetThumbnailsSettings}
          onEnter={requireAuth}
          path="thumbnails-settings"
          title={messages.LiveCamWidgetPage.thumbnailsSettings.title.text}
        />
        <Route
          component={LiveCamWidgetInfobarSettings}
          onEnter={requireAuth}
          path="infobar-settings"
          title={messages.LiveCamWidgetPage.infobarSettings.title.text}
        />
        <Route
          component={LiveCamWidgetCustomCssSettings}
          onEnter={requireAuth}
          path="custom-css"
          title={messages.LiveCamWidgetPage.customCssSettings.title.text}
        />
      </Route>

      <Route component={SmartLink} onEnter={requireAuth} path="smartlink" title={messages.genericTextSmartLink} />

      <Route
        component={DecemberChallenge}
        onEnter={requireAuth}
        path="Challenge-2020-Registration-Completed"
        title={messages.decemberChallengePage.title}
      />
      <Route
        component={MarchChallenge}
        onEnter={requireAuth}
        path="mad-march-contest-2021"
        title={messages.marchChallengePage.title}
      />

      <Route component={SurveyGenerator} path="survey-machine" title={messages.surveyGeneratorPageTitle}>
        <IndexRoute component={TemplateOptions} onEnter={requireAuth} title={messages.surveyGeneratorColorTemplate} />
        <Route
          component={BasicConfiguration}
          onEnter={requireAuth}
          path="basic-configuration"
          title={messages.surveyGeneratorBasicConfiguration}
        />
        <Route
          component={RewardOptions}
          onEnter={requireAuth}
          path="reward-options"
          title={messages.surveyGeneratorRewardOptions}
        />
      </Route>

      <Route path="referrals" title={messages.profileMenuReferralsTitle}>
        <IndexRoute component={Referrals} onEnter={requireAuth} title={messages.profileMenuReferralsListTitle} />
      </Route>

      <Route
        component={PaymentHistory}
        onEnter={requireAuth}
        path="payment-history"
        title={messages.profilePaymentHistoryTitle}
      />

      <Route component={Profile} path="profile" title={messages.profileTitle}>
        <IndexRedirect to="user-details" />
        <Route component={ProfileUserInfos} onEnter={requireAuth} path="user-details" title={messages.profileMenuUserInfos} />
        <Route component={Identity} onEnter={requireAuth} path="identity" title={messages.profileMenuIdentity} />
        <Route
          component={ProfileBillingInfos}
          onEnter={requireAuth}
          path="billing-infos"
          title={messages.profileBillingInfosTitle}
        />
        <Route
          component={ProfileEmailPreferences}
          onEnter={requireAuth}
          path="email-preferences"
          title={messages.profileEmailPreferencesTitle}
        />
        <Route
          component={ProfileNotifications}
          onEnter={requireAuth}
          path="notifications"
          title={messages.genericTextNotifications}
        />
      </Route>

      <Route component={PromoTools} onEnter={requireAuth} path="promotools" title={messages.genericTextPromoTools} />

      <Route component={Jerkmate2} onEnter={requireAuth} path="jerkmate" title={messages.genericTextJerkmate} />

      <Route component={VastPreRoll} onEnter={requireAuth} path={VAST_PREROLL_PATH} title={messages.vastPreRoll} />

      <Route component={Achievements} onEnter={requireAuth} path="achievements" title={messages.achievements} />

      <Redirect from="billing/invoice/:id" to="payment-history" />
      <Redirect from="livecam-generator" to="mfc-widget" />
      <Redirect from="offers/view/:id" to="offers/:id" />
      <Redirect from="profile/payment-history" to="payment-history" />
      <Redirect from="profile/referrals" to="referrals" />
      <Redirect from="registration" to="/access/create-account" />
      <Redirect from="signup" to="/access/create-account" />
      <Redirect from="signup-choice" to="/access/create-account" />
      <Redirect from="tools" to="promotools" />
      <Redirect from="*" to="login" />
      <Redirect from="survey-generator" to="survey-machine" />
    </Route>
  );
};

export default Routes;
