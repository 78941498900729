import { sortCountries, getCurrentQuarter } from '../utils/helpers';

import consts from '../utils/consts';

const { ACTIONS } = consts;

const initialState = {
  currentQuarter: getCurrentQuarter(),

  findAllSearch: consts.OFFERS_FIND_ALL,
  lists: {
    bestTimesToCall: [],
    bestTimesToCallIsFetching: false,
    validationStatus: [],
    validationStatusIsFetching: false,
    affiliateTypes: [],
    affiliateTypesIsFetching: false,
    minimumPayouts: [],
    minimumPayoutsIsFetching: false,
    offersCategories: [],
    offersCategoriesIsFetching: false,
    paymentMethods: [],
    paymentMethodsIsFetching: false,
    paymentMethodsV2: [],
    paymentMethodsV2IsFetching: false,
    salutations: [],
    salutationsIsFetching: false,
    offers: {},
    offersIsFetching: false,
    offersUrl: [],
    offersUrlIsFetching: false,
    goals: [],
    goalsIsFetching: false,
    browsers: [],
    browsersIsFetching: false,
    timezones: [],
    timezonesIsFetching: false,
    countries: [],
    countriesIsFetching: false,
    searchFields: {
      isFetching: false,
      MediaPlacements: [],
      Verticals: [],
      ConversionTypes: [],
      Channels: [],
    },
    allOffers: [],
    allOffersIsFetching: false,
  },
  ui: {
    mainMenuIsOpen: false,
    subscriptionFormPercent: 0,
    isDisabled: true,
    paymentHistoryViewMode: 'table',
    offersFiltersOpen: false,
    offersViewMode: 'table',
    offersCreativesViewMode: 'cards',
    offersPostbacksViewMode: 'table',
    notificationsViewMode: 'table',
    referralsViewMode: 'table',
    collapsedPostback: true,
    collapsedStatisticsFilters: true,
    collapsedStatisticsGraph: false,
    actionMessage: {
      status: null,
      textObj: {},
      time: 5000,
      callback: null,
    },
  },
  savedForm: {},
  language: 'en',
  showApplication: false,
};

export const application = (state = initialState, action = {}) => {
  const { lists, savedForm, ui } = state;
  const searchFields = lists.searchFields;
  let form;

  switch (action.type) {
    case ACTIONS.APP_UI_CHANGE_MAIN_MENU_STATE:
      return {
        ...state,
        ui: {
          ...ui,
          mainMenuIsOpen: action.mainMenuIsOpen,
        },
      };

    case ACTIONS.REQUEST_OFFERS_CATEGORIES:
      return {
        ...state,
        lists: {
          ...lists,
          offersCategoriesIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_OFFERS_CATEGORIES:
      return {
        ...state,
        lists: {
          ...lists,
          offersCategories: action.data,
          offersCategoriesIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_OFFERS_CATEGORIES:
      return {
        ...state,
        lists: {
          ...lists,
          offersCategoriesIsFetching: false,
        },
      };

    case ACTIONS.REQUEST_SALUTATIONS:
      return {
        ...state,
        lists: {
          ...lists,
          salutationsIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_SALUTATIONS:
      return {
        ...state,
        lists: {
          ...lists,
          salutations: action.data,
          salutationsIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_SALUTATIONS:
      return {
        ...state,
        lists: {
          ...lists,
          salutationsIsFetching: false,
        },
      };

    case ACTIONS.REQUEST_BEST_TIMES_TO_CALL:
      return {
        ...state,
        lists: {
          ...lists,
          bestTimesToCallIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_BEST_TIMES_TO_CALL:
      return {
        ...state,
        lists: {
          ...lists,
          bestTimesToCall: action.data,
          bestTimesToCallIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_BEST_TIMES_TO_CALL:
      return {
        ...state,
        lists: {
          ...lists,
          bestTimesToCallIsFetching: false,
        },
      };

    case ACTIONS.REQUEST_VALIDATION_STATUS:
      return {
        ...state,
        lists: {
          ...lists,
          validationStatusIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_VALIDATION_STATUS:
      return {
        ...state,
        lists: {
          ...lists,
          validationStatus: action.data,
          validationStatusIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_VALIDATION_STATUS:
      return {
        ...state,
        lists: {
          ...lists,
          validationStatusIsFetching: false,
        },
      };

    case ACTIONS.REQUEST_AFFILIATE_TYPES:
      return {
        ...state,
        lists: {
          ...lists,
          affiliateTypesIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_AFFILIATE_TYPES:
      return {
        ...state,
        lists: {
          ...lists,
          affiliateTypes: action.data,
          affiliateTypesIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_AFFILIATE_TYPES:
      return {
        ...state,
        lists: {
          ...lists,
          affiliateTypesIsFetching: false,
        },
      };

    case ACTIONS.REQUEST_PAYMENT_METHODS:
      return {
        ...state,
        lists: {
          ...lists,
          paymentMethodsIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_PAYMENT_METHODS:
      return {
        ...state,
        lists: {
          ...lists,
          paymentMethods: action.data,
          paymentMethodsIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_PAYMENT_METHODS:
      return {
        ...state,
        lists: {
          ...lists,
          paymentMethodsIsFetching: false,
        },
      };
    case ACTIONS.REQUEST_PAYMENT_METHODS_V2:
      return {
        ...state,
        lists: {
          ...lists,
          paymentMethodsV2IsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_PAYMENT_METHODS_V2:
      return {
        ...state,
        lists: {
          ...lists,
          paymentMethodsV2: action.data,
          paymentMethodsV2IsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_PAYMENT_METHODS_V2:
      return {
        ...state,
        lists: {
          ...lists,
          paymentMethodsV2IsFetching: false,
        },
      };
    case ACTIONS.REQUEST_MINIMUM_PAYOUTS:
      return {
        ...state,
        lists: {
          ...lists,
          minimumPayoutsIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_MINIMUM_PAYOUTS:
      return {
        ...state,
        lists: {
          ...lists,
          minimumPayouts: action.data,
          minimumPayoutsIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_MINIMUM_PAYOUTS:
      return {
        ...state,
        lists: {
          ...lists,
          minimumPayoutsIsFetching: false,
        },
      };

    case ACTIONS.REQUEST_OFFERS_LIST:
      return {
        ...state,
        lists: {
          ...lists,
          offersIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_OFFERS_LIST:
      return {
        ...state,
        lists: {
          ...lists,
          offers: action.data,
          offersIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_OFFERS_LIST:
      return {
        ...state,
        lists: {
          ...lists,
          offersIsFetching: false,
        },
      };

    case ACTIONS.REQUEST_OFFERS_URL_LIST:
      return {
        ...state,
        lists: {
          ...lists,
          offersUrlIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_OFFERS_URL_LIST:
      return {
        ...state,
        lists: {
          ...lists,
          offersUrl: action.data,
          offersUrlIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_OFFERS_URL_LIST:
      return {
        ...state,
        lists: {
          ...lists,
          offersUrlIsFetching: false,
        },
      };

    case ACTIONS.ADD_COMPLETED_FORM_TO_APP:
      form = action.data;

      return {
        ...state,
        savedForm: {
          ...savedForm,
          ...form,
        },
      };

    case ACTIONS.LOGOUT:
      return {
        ...state,
        ui: initialState.ui,
        showApplication: true,
      };

    case ACTIONS.CHANGE_PAYMENT_HISTORY_VIEW_MODE:
      return {
        ...state,
        ui: {
          ...ui,
          paymentHistoryViewMode: action.viewMode,
        },
      };

    case ACTIONS.CHANGE_OFFERS_VIEW_MODE:
      return {
        ...state,
        ui: {
          ...ui,
          offersViewMode: action.viewMode,
        },
      };

    case ACTIONS.CHANGE_OFFERS_CREATIVES_VIEW_MODE:
      return {
        ...state,
        ui: {
          ...ui,
          offersCreativesViewMode: action.viewMode,
        },
      };

    case ACTIONS.CHANGE_REFERRALS_VIEW_MODE:
      return {
        ...state,
        ui: {
          ...ui,
          referralsViewMode: action.viewMode,
        },
      };

    case ACTIONS.CHANGE_ALL_VIEW_MODES:
      return {
        ...state,
        ui: {
          ...ui,
          offersViewMode: action.viewMode,
          offersCreativesViewMode: action.width <= 1500 ? 'cards' : state.ui.offersCreativesViewMode,
          offersPostbacksViewMode: action.viewMode,
          paymentHistoryViewMode: action.viewMode,
          notificationsViewMode: action.viewMode,
          referralsViewMode: action.viewMode,
        },
      };

    case ACTIONS.SET_ACTIONS_MESSAGE:
      return {
        ...state,
        ui: {
          ...ui,
          actionMessage: {
            status: action.status,
            textObj: action.textObj,
            time: action.time || 5000,
            callback: action.callback,
          },
        },
      };

    case ACTIONS.REQUEST_ALL_GOALS:
      return {
        ...state,
        lists: {
          ...lists,
          goalsIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_ALL_GOALS:
      return {
        ...state,
        lists: {
          ...lists,
          goals: action.data,
          goalsIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_ALL_GOALS:
      return {
        ...state,
        lists: {
          ...lists,
          goalsIsFetching: false,
        },
      };

    case ACTIONS.REQUEST_ALL_BROWSERS:
      return {
        ...state,
        lists: {
          ...lists,
          browsersIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_ALL_BROWSERS:
      return {
        ...state,
        lists: {
          ...lists,
          browsers: action.data,
          browsersIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_ALL_BROWSERS:
      return {
        ...state,
        lists: {
          ...lists,
          browsersIsFetching: false,
        },
      };

    case ACTIONS.REQUEST_ALL_TIMEZONES:
      return {
        ...state,
        lists: {
          ...lists,
          timezonesIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_ALL_TIMEZONES:
      return {
        ...state,
        lists: {
          ...lists,
          timezones: action.data,
          timezonesIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_ALL_TIMEZONES:
      return {
        ...state,
        lists: {
          ...lists,
          timezonesIsFetching: false,
        },
      };

    case ACTIONS.REQUEST_ALL_COUNTRIES:
      return {
        ...state,
        lists: {
          ...lists,
          countriesIsFetching: true,
        },
      };

    case ACTIONS.RECEIVE_ALL_COUNTRIES:
      return {
        ...state,
        lists: {
          ...lists,
          countries: sortCountries(action.data).filter((country) => !country.name.toLowerCase().includes('unknow')),
          countriesIsFetching: false,
        },
      };

    case ACTIONS.FAILED_TO_RECEIVE_ALL_COUNTRIES:
      return {
        ...state,
        lists: {
          ...lists,
          countriesIsFetching: false,
        },
      };

    case ACTIONS.SHOW_APPLICATION:
      return {
        ...state,
        showApplication: true,
      };

    case ACTIONS.REQUEST_SEARCH_FIELDS:
      return {
        ...state,
        lists: {
          ...lists,
          searchFields: {
            ...searchFields,
            isFetching: true,
          },
        },
      };
    case ACTIONS.RECEIVE_SEARCH_FIELDS:
      return {
        ...state,
        lists: {
          ...lists,
          searchFields: {
            ...searchFields,
            isFetching: false,
            ...action.data,
          },
        },
      };
    case ACTIONS.FAILED_TO_RECEIVE_SEARCH_FIELDS:
      return {
        ...state,
        lists: {
          ...lists,
          searchFields: {
            ...searchFields,
            isFetching: false,
          },
        },
      };
    case ACTIONS.REQUEST_ALL_OFFERS:
      return {
        ...state,
        lists: {
          ...lists,
          allOffersIsFetching: true,
        },
      };
    case ACTIONS.RECEIVE_ALL_OFFERS:
      return {
        ...state,
        lists: {
          ...lists,
          allOffers: action.payload.Offers ? action.payload.Offers.Data : [],
          allOffersIsFetching: false,
        },
      };
    case ACTIONS.FAILED_TO_RECEIVE_ALL_OFFER:
      return {
        ...state,
        allOffersIsFetching: false,
      };

    default:
      return state;
  }
};
