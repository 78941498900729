import request from '../utils/Req';
import HandleAPIError from '../utils/handleAPIError';
import { getCookie } from '../utils/helpers';

import config from '../config';
import consts from '../utils/consts';

const { API_URL } = config;
const {
  ACTIONS: { GAMIFICATION },
} = consts;

const requestGamificationEvents = (uniqueEvent) => ({
  type: GAMIFICATION.REQUEST_EVENTS,
  uniqueEvent,
});
const receiveGamificationEvents = (uniqueEvent, payload) => ({
  type: GAMIFICATION.RECEIVE_EVENTS,
  payload,
  uniqueEvent,
});

const failedGamificationEvents = (uniqueEvent) => ({
  type: GAMIFICATION.FAILED_EVENTS,
  uniqueEvent,
});

const requestGamificationBadge = () => ({
  type: GAMIFICATION.REQUEST_BADGES,
});
const receiveGamificationBadge = (payload) => ({
  type: GAMIFICATION.RECEIVE_BADGES,
  payload,
});

const uid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const postGamificationEvents = (gamificationEvent) => (dispatch) =>
  new Promise((fulfill, reject) => {
    const uniqueEvent = { id: uid(), ...gamificationEvent };
    request
      .post(`${API_URL}/gamification/events`)
      .send(gamificationEvent)
      .set({
        'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
      })
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          const data = res.body;
          dispatch(receiveGamificationEvents(uniqueEvent, data));
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.handleGamificationEventResponse();
          fulfill();
        } else {
          dispatch(failedGamificationEvents(uniqueEvent));
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      });
    dispatch(requestGamificationEvents(uniqueEvent));
  });

export const fetchGamificationBadge = () => (dispatch) =>
  new Promise((fulfill, reject) => {
    request
      .get(`${API_URL}/gamification/badges`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          const data = res.body;
          dispatch(receiveGamificationBadge(data));
          fulfill();
        } else {
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      });
    dispatch(requestGamificationBadge());
  });

const requestGamificationAffiliateBadges = () => ({
  type: GAMIFICATION.REQUEST_AFFILIATE_BADGES,
});

const receiveGamificationAffiliateBadges = (payload) => ({
  type: GAMIFICATION.RECEIVE_AFFILIATE_BADGES,
  payload,
});

export const fetchGamificationAffiliateBadges = () => (dispatch) =>
  new Promise((fulfill, reject) => {
    request
      .get(`${API_URL}/gamification/affiliate-badges`)
      .withCredentials()
      .end((err, res) => {
        if (!err) {
          const data = res.body;
          dispatch(receiveGamificationAffiliateBadges(data));
          fulfill();
        } else {
          const APIErrorhandler = new HandleAPIError(res, dispatch);
          APIErrorhandler.redirectIfNotLoggedIn();
          APIErrorhandler.showErrorMessage();
          reject();
        }
      });
    dispatch(requestGamificationAffiliateBadges());
  });
