import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Arrow from '../../pages/profile/user-infos/svg/Arrow.react';
import Warning from '../../pages/profile/user-infos/svg/Warning.react';
import { LockedSectionWrapper } from './LockedSectionContainer.react';
import Skeleton from './Skeleton/Skeleton';

export const ProfileSectionContainer = ({
  isOpen,
  isDrawer = true,
  iconComponent,
  hasMultipleSubsections = false,
  setIsOpen,
  sectionSubtitle,
  sectionTitleAsFormattedMessage,
  invalid,
  lockedData,
  children,
  wrapperClass,
  isLoading,
}) => {
  const IsChildrenVisible = isOpen || !isDrawer || lockedData?.isLocked;
  return isLoading ? (
    <Skeleton height="110px" heightMobile="72px" width="100%" />
  ) : (
    <div
      className={`${wrapperClass ?? ''} profile-info-section ${hasMultipleSubsections ? 'multiple-subsections' : ''}  ${
        lockedData?.isLocked ? 'locked' : ''
      }`}
    >
      <LockedSectionWrapper lockedData={lockedData} />
      {isDrawer ? (
        <>
          <div className="info-header" onClick={() => setIsOpen(!isOpen)}>
            <div className="header-wrapper" />
            <div className="icon">{iconComponent ?? null}</div>
            <div>
              <h1>
                <FormattedMessage {...sectionTitleAsFormattedMessage} />
              </h1>
              <span className="profile-section-subtitle">{sectionSubtitle}</span>
            </div>
            <div className="icons">
              <Warning className={invalid !== null && invalid ? 'invalid' : ''} />
              <Arrow className={IsChildrenVisible ? 'open' : ''} />
            </div>
          </div>
          <span className={`profile-section-subtitle-mobile ${IsChildrenVisible ? 'open' : ''}`}>{sectionSubtitle}</span>
        </>
      ) : null}

      <div className={`children-wrapper ${IsChildrenVisible ? '' : 'hidden'}`}>{children}</div>
    </div>
  );
};

ProfileSectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  iconComponent: PropTypes.node,
  isLocked: PropTypes.bool,
  isOpen: PropTypes.bool,
  sectionSubtitle: PropTypes.string,
  setIsOpen: PropTypes.func.isRequired,
};
