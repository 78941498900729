import { mockBadgeData } from '../badgeDataConfig';
import consts from '../utils/consts';
import {
  FIRST_PAYMENT_TYPE,
  LOGIN_STREAK_TYPE,
  PAYOUT_AMOUNT_TYPE,
  SHOW_TES_AFFILIATE_EVENT_TYPE,
  SHOW_TES_AFFILIATE_SEVILLE_TYPE,
} from '../utils/gamificationHelper';
import { setCookie } from '../utils/helpers';

const {
  ACTIONS: { GAMIFICATION },
} = consts;

const initialState = {
  eventsDataIsFetching: false,
  uniqueEventsIsFetching: [],
  badgeDataIsFetching: false,
  affiliateBadgesIsFetching: false,
  request: null,
  eventsData: {},
  badgeData: {},
  affiliateBadges: [],
  loginStreakBadges: [],
  gamificationBadges: [],
};

const findMatchingBadgeData = (sourceBadge) =>
  mockBadgeData.find((el) => {
    if (el.type !== sourceBadge.type) {
      return false;
    }
    if (el.level && el.level !== sourceBadge.level) {
      return false;
    }
    if (el.badgeData.subtype && el.badgeData.subtype !== sourceBadge.badgeData.subtype) {
      return false;
    }
    return true;
  });

const getAugmentedBadges = (badges) => {
  if (!badges?.length) {
    return badges;
  }
  const augmentedBadges = [];
  badges.forEach((badge) => {
    const matchingBadgeData = findMatchingBadgeData(badge);
    const augmentedBadge = {
      ...badge,
      badgeData: Object.assign(badge.badgeData ?? {}, matchingBadgeData?.badgeData ?? {}),
      title: matchingBadgeData?.title,
    };
    augmentedBadges.push(augmentedBadge);
  });
  return augmentedBadges;
};

const addAffiliateBadges = (affiliateBadgesFromState, affiliateBadge) => {
  if (Array.isArray(affiliateBadgesFromState)) {
    if (!affiliateBadgesFromState.find((ab) => ab?.id === affiliateBadge?.id)) {
      affiliateBadgesFromState.push(affiliateBadge);
    }
  } else {
    affiliateBadgesFromState = new Array(affiliateBadge);
  }
};

const getUniqueEventsIsFetching = (uniqueEventsIsFetching, uniqueEvent, eventIsFetching) => {
  if (!uniqueEvent?.id) {
    return uniqueEventsIsFetching;
  }
  const newUniqueEventsIsFetching = uniqueEventsIsFetching.slice();
  const existingUniqueEvent = newUniqueEventsIsFetching.find((ue) => ue?.id === uniqueEvent.id);
  if (!existingUniqueEvent) {
    newUniqueEventsIsFetching.push({ id: uniqueEvent.id, type: uniqueEvent.type, eventIsFetching });
  } else {
    existingUniqueEvent.eventIsFetching = eventIsFetching;
  }
  return newUniqueEventsIsFetching;
};

export const anyUniqueEventsIsFetching = (uniqueEventsIsFetching) =>
  uniqueEventsIsFetching.some((ue) => ue.eventIsFetching === true);

export const processShowTESAffiliateSevilleEventResponse = (notification) => {
  if (notification?.error) {
    const error = notification.error;
    switch (error.type) {
      case 'BadgeFindError':
        return;
      default:
        break;
    }
  }
  setCookie('codeId', '');
};

export const gamification = (state = initialState, action) => {
  switch (action.type) {
    case GAMIFICATION.REQUEST_EVENTS:
      return {
        ...state,
        eventsDataIsFetching: true,
        uniqueEventsIsFetching: getUniqueEventsIsFetching(state.uniqueEventsIsFetching, action.uniqueEvent, true),
      };
    case GAMIFICATION.FAILED_EVENTS:
      return {
        ...state,
        eventsDataIsFetching: false,
        uniqueEventsIsFetching: getUniqueEventsIsFetching(state.uniqueEventsIsFetching, action.uniqueEvent, false),
      };
    case GAMIFICATION.RECEIVE_EVENTS: {
      let notificationLoginStreak = undefined;
      let notificationFirstPayment = undefined;
      let notificationPayoutAmount = undefined;
      let notificationEventTESAffiliateSeville2025 = undefined;

      const notifications = action?.payload;
      notificationLoginStreak = notifications?.find((notification) => notification.type === LOGIN_STREAK_TYPE);
      notificationFirstPayment = notifications?.find((notification) => notification.type === FIRST_PAYMENT_TYPE);
      notificationPayoutAmount = notifications?.find((notification) => notification.type === PAYOUT_AMOUNT_TYPE);
      notificationEventTESAffiliateSeville2025 = notifications?.find(
        (notification) => notification.type === SHOW_TES_AFFILIATE_SEVILLE_TYPE
      );
      const affiliateBadgesFromState = state.affiliateBadges;
      if (notifications?.length) {
        for (const n of notifications) {
          if (n?.affiliateBadge && n?.status === 'progress') {
            addAffiliateBadges(affiliateBadgesFromState, n.affiliateBadge);
          }
          if (action?.uniqueEvent?.type === SHOW_TES_AFFILIATE_EVENT_TYPE) {
            if (n?.type === 'show_tes_affiliate_seville') {
              processShowTESAffiliateSevilleEventResponse(n);
            }
          }
        }
      }

      return {
        ...state,
        eventsDataIsFetching: false,
        eventsData: {
          notification: notificationLoginStreak,
          notificationFirstPayment,
          notificationPayoutAmount,
          notificationEventTESAffiliateSeville2025,
        },
        affiliateBadges: affiliateBadgesFromState,
        uniqueEventsIsFetching: getUniqueEventsIsFetching(state.uniqueEventsIsFetching, action.uniqueEvent, false),
      };
    }
    case GAMIFICATION.REQUEST_BADGES:
      return {
        ...state,
        badgeDataIsFetching: true,
      };
    case GAMIFICATION.RECEIVE_BADGES: {
      const loginStreakBadges = action.payload?.length
        ? action.payload.filter((b) => b.type === 'login_streak').sort((a, b) => a.level > b.level)
        : [];
      const augmentedBadges = action.payload ? getAugmentedBadges(action.payload) : action.payload;
      return {
        ...state,
        badgeDataIsFetching: false,
        badgeData: augmentedBadges,
        loginStreakBadges,
        gamificationBadges: augmentedBadges,
      };
    }
    case GAMIFICATION.REQUEST_AFFILIATE_BADGES:
      return {
        ...state,
        affiliateBadgesIsFetching: true,
      };
    case GAMIFICATION.RECEIVE_AFFILIATE_BADGES: {
      const affiliateBadgesFromState = state.affiliateBadges;
      if (action?.payload?.length) {
        for (const affBadge of action.payload) {
          addAffiliateBadges(affiliateBadgesFromState, affBadge);
        }
      }
      return {
        ...state,
        affiliateBadgesIsFetching: false,
        affiliateBadges: action.payload ?? [],
      };
    }
    default:
      return state;
  }
};
