import React from 'react';

const HeaderBadgeBg = ({ windowWidthSize }) =>
  windowWidthSize <= 991 ? (
    <svg
      className="achievements-box-bg"
      fill="none"
      height="63"
      viewBox="0 0 400 63"
      width="400"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M399.562 0.270508V62.2399H355.025L349.459 51.4963H48.4993L42.934 62.2399H0V0.270508H42.934L48.4993 11.0177H349.459L355.025 0.270508H399.562Z"
        fill="url(#paint0_linear_13_4803)"
      />
      <defs>
        <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_13_4803" x1="86" x2="401" y1="26.2552" y2="26.2552">
          <stop stopColor="#1D1D1B" stopOpacity="0" />
          <stop offset="0.12" stopColor="#173D15" stopOpacity="0.1" />
          <stop offset="0.25" stopColor="#10640F" stopOpacity="0.2209" />
          <stop offset="0.32" stopColor="#0A8409" stopOpacity="0.4356" />
          <stop offset="0.38" stopColor="#059D05" stopOpacity="0.67" />
          <stop offset="0.43" stopColor="#02AE02" stopOpacity="0.9" />
          <stop offset="0.5" stopColor="#00B900" />
          <stop offset="0.5" stopColor="#00BD00" />
          <stop offset="0.61" stopColor="#01B501" stopOpacity="0.9025" />
          <stop offset="0.67" stopColor="#059F04" stopOpacity="0.6724" />
          <stop offset="0.79" stopColor="#0B7D0A" stopOpacity="0.44" />
          <stop offset="0.877787" stopColor="#144D12" stopOpacity="0.1" />
          <stop offset="1" stopColor="#1D1D1B" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      className="achievements-box-bg"
      fill="none"
      height="63"
      viewBox="0 0 570 63"
      width="570"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M569.562 0.310547V62.2799H525.025L519.459 51.5364H48.4993L42.934 62.2799H0V0.310547H42.934L48.4993 11.0578H519.459L525.025 0.310547H569.562Z"
        fill="url(#paint0_linear_11_3299)"
      />
      <defs>
        <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_11_3299" x1="42" x2="447.5" y1="26.2952" y2="26.2952">
          <stop stopColor="#1D1D1B" stopOpacity="0" />
          <stop offset="0.12" stopColor="#173D15" stopOpacity="0.1" />
          <stop offset="0.25" stopColor="#10640F" stopOpacity="0.2209" />
          <stop offset="0.32" stopColor="#0A8409" stopOpacity="0.4356" />
          <stop offset="0.38" stopColor="#059D05" stopOpacity="0.67" />
          <stop offset="0.43" stopColor="#02AE02" stopOpacity="0.9" />
          <stop offset="0.5" stopColor="#00B900" />
          <stop offset="0.5" stopColor="#00BD00" />
          <stop offset="0.61" stopColor="#01B501" stopOpacity="0.9025" />
          <stop offset="0.67" stopColor="#059F04" stopOpacity="0.6724" />
          <stop offset="0.79" stopColor="#0B7D0A" stopOpacity="0.44" />
          <stop offset="0.877787" stopColor="#144D12" stopOpacity="0.1" />
          <stop offset="1" stopColor="#1D1D1B" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );

export default HeaderBadgeBg;
